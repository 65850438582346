@import "custom";
@import "animations";
@import "forms";

body{
    background: linear-gradient(180deg, #6dacc2 0%, #2eb5cf 29.98%, #006499 60.24%, #113053 100%);
    position: relative;
    
}
*{
    box-sizing: border-box;
    
}
ul{
    list-style: none;
    padding: 0px;
    margin: 0px;
}
button{
    background: transparent;
    border: none;
}
.text-left{
    text-align: left;
}
.text-right{
    text-align: right;
}
.text-center{
    text-align: center;
}
.header{
    font-family: 'CCKillJoy';
    font-size: 48px;
    line-height: 1;
    margin-bottom: 25px;
    text-align: center;
    @media (max-width: 1600px) {
        font-size: 35px;
    }
    @media (max-width: 1200px) {
        font-size: 28px;
    }
    @media (max-width: 768px) {
        font-size: 24px;
    }
}
.text{
    font-size: 24px;
    line-height: 28px;
    @media (max-width: 1600px) {
        font-size: 20px;
        line-height: 24px;
    }
    @media (max-width: 768px) {
        font-size: 18px;
        line-height: 21px;
    }
}
.overlay{
    position: absolute;
    height: 0%;
    width: 100%;
    left: 0;
    top: 0px;
    z-index: 40;
    background-color: rgba(0, 0, 0, 0.9);
    opacity: 0;
    transition: height 0s, opacity 300ms ease-in-out;;
}
@media (max-width: 576px) {
    body.nav-opened .overlay{
        height: 100%;
        opacity: 1;
    }
}
@media (max-width: 992px) {
    body.button_overlay_opened{
        overflow-y: hidden;
        touch-action: none;
        -ms-touch-action: none;
        .menu, .logo{
            z-index: 0;
        }
    }
}
.menu{
    position: fixed;
    z-index: 50;
    top: 50%;
    left: 0px;
    transform: translateY(-50%) translateX(-100%);
    display: flex;
    flex-direction: row;
    
    @media (max-width: 576px) {
        bottom: 0px;
        left: 0px;
        width: 100vw;
        height: 100vh;
        top: unset;
        transform: translateY(100%);
    }
    button{
        
        width: 89px;
        height: 89px;
        background-color: #ffffff;
        top: 50%;
        border-radius: 50%;
        box-shadow: 0px 4px 8px 0px rgba(0, 0, 0, 0.1);
        border: none;
        padding: 0px;
        
        
        @media (max-width: 1200px){
            width: 60px;
            height: 60px;
        }
        @media (max-width: 576px) {
            box-shadow: 0px 0px 8px 4px rgba(0, 0, 0, 0.1);
            width: 60px;
            height: 60px;
        }
        
        .close-icon{
            width: 32.5%;
            height: 32.5%;
        }
    }
    .open-icon_wrapper{
        width: 41.5%;
        min-height: 31.5%;
        height: 31.5%;
        display: block;
        margin: auto;
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translateX(-50%) translateY(-50%);
        // align-items: center;
        // justify-content: space-between;
        // position: relative;
        .open-icon {
            display: block;
            height: 100%;
            width: 100%;
        }
        .open-icon span{
            width: 100%;
            height: 4px;
            display: block;
            border-radius: 2px;
            background: #292456;
            transition: all .3s;
            &:first-child{
                position: absolute;
                top: 0px;
            }
            &:last-child{
                position: absolute;
                bottom: 0px;
            }
            &:nth-child(2){
                position: absolute;
                top: calc(50% - 2px);
            }

        }
       
    }
    .burger-wrapper{
        width: 97px;
        height: 204px;
        position: absolute;
        top: 50%;
        left: 100%;
        // background-image: url('url:../img/burger_bg.png?as=webp');
        filter: drop-shadow(0px 4px 8px rgba(0, 0, 0, 0.1));
        transform: translateY(-50%);
        z-index: 0;
        transition: z-index 0s .3s;
        @media (max-width: 1200px){
            width: 62px;
            height: 139px;
        }
        @media (max-width: 576px) {
            width: 66px;
            height: 140px;
            bottom: calc(100% - 20px);
            top: unset;
            left: calc(0% - 25px);
            transform: translateY(100%) rotate(-90deg);
            transform-origin: top left;
        }
        .burger-wrapper_bg{
            width: 100%;
            height: 100%;
        }
        button{
            transform: translateX(50%) translateY(-50%);
            position: absolute;
            right: 0px;
            @media (max-width: 576px) {
                transform: translateX(50%) translateY(-50%) rotate(-90deg);
            }
        }
    }
    nav{
        transition: all .3s;
        transition-timing-function: cubic-bezier(0.68, -0.6, 0.32, 1.6);
        position: relative;
        right: 200px;
        z-index: 1;
        @media (max-width: 576px) {
            right: unset;
            width: 100%;
            height: 100%;
            display: flex;
            flex-direction: column;
            align-items: center;
            justify-content: center;
        }
        ul:not(.addon-nav){
            display: flex;
            flex-direction: column;
            flex: auto;
            align-items: flex-start;
            @media (max-width: 576px) {
                align-items: center;
                flex: unset;
            }
            li{
                background-color: #fff;
                border-radius: 14px;
                position: relative;
                margin-bottom: 9px;
                margin-top: 9px;
                // overflow: hidden;
                &.active, &:hover{
                    background-color: #FFDB00;
                    .liquid-container{
                        background-color: #FFDB00;
                        span{
                            background-color: #FFDB00;
                        }
                    }
                }
                
                
                position: relative;
               
                a{
                    display: block;
                    text-align: center;
                    padding: 2px 8px;
                    font-size: 18px;
                    text-decoration: none;
                    color: rgba(41, 36, 86, 1);
                    position: relative;
                    transition: all .2s;
                   
                }
                @media (max-width: 340px) {
                    margin-bottom: 7px;
                    margin-top: 7px;
                    a{
                        font-size: 16px;
                    }
                }
                .liquid-container{
                    position: absolute;
                    width: 100%;
                    height: 100%;
                    top: 0px;
                    left: 0px;
                    background-color: white;
                    filter: url('#goo');
                    z-index: -1;
                    border-radius: 14px;
                    box-shadow: 0px 4px 8px 0px rgba(0,0,0,.1);
                    span{
                        z-index: -2;
                        background-color: white;
                        border-radius: 50%;
                        position: absolute;
                    }
                    span:nth-child(1){
                        left: 10%;
                        top: 0%;
                        width: 20px;
                        height: 20px;
                    }
                    span:nth-child(2){
                        right: 10%;
                        top: 0%;
                        width: 10px;
                        height: 10px;
                    }
                    span:nth-child(3){
                        left: 10%;
                        bottom: 0%;
                        width: 10px;
                        height: 10px;
                    }
                    span:nth-child(4){
                        right: 10%;
                        bottom: 0%;
                        width: 20px;
                        height: 20px;
                    }
                    
                    // @keyframes lq {
                    //     0%, 100%{
                    //         right: 0px;
                    //         top: 0px;
                    //     }
                    //     20%{
                            
                    //     }
                    // }
                }
                &:hover .liquid-container>span:nth-child(-n+2){
                    animation: top-spans 4s cubic-bezier(0.12, 0, 0.39, 0) infinite;
                }
                &:hover .liquid-container>span:nth-child(3){
                    animation: bottom-spans-left 3s cubic-bezier(0.12, 0, 0.39, 0) infinite;
                }
                &:hover .liquid-container>span:nth-child(4){
                    animation: bottom-spans-right 3s cubic-bezier(0.12, 0, 0.39, 0) infinite;
                }
                &:hover .liquid-container>span:nth-child(2){
                    animation-delay: 300ms;
                }
                &:hover .liquid-container>span:nth-child(4){
                    animation-delay: 500ms;
                }
            }
            @for $i from 1 through 9 {
                li:nth-child(#{$i}) {
                  left: calc(40% * #{$i} - #{$i} * #{$i} * 4% + 10%);
                }
            }
            @media (max-width: 576px) {
                li{
                    left: unset!important;
                    position: relative;
                }
            }
        }
        .addon-nav{
            display: none;
            margin-top: 35px;
            li:first-child{
                margin-bottom: 10px;
            }
            a img{
                max-width: 135px;
                @media (max-width: 340px) {
                    max-width: 100px;
                }
            }
            @media (max-width: 576px) {
                display: block;
            }
            @media (max-width: 340px) {
                margin-top: 10px;
            }
        }
        button{
            position: absolute;
            top: 50%;
            right: 0px;
            transform: translateY(-50%);
            @media (max-width: 576px) {
                right: unset;
                left: 40px;
                top: unset;
                bottom: 6px;
            }
        }
    }
    &.nav-opened{
        nav{
            right: -141px;
            @media (max-width: 1200px) {
                right: -92px;
            }
            @media (max-width: 576px) {
                right: unset;
                transform: translateY(-100%);
            }
        }
        .burger-wrapper{
            z-index: 5;
        }
        .open-icon_wrapper{
            .open-icon span{
                
                &:first-child{
                    top: calc(50% - 2px);
                    transform-origin: center;
                    transform: rotate(45deg);
                }
                &:last-child{
                    bottom: calc(50% - 2px);
                    transform-origin: center;
                    transform: rotate(45deg);
                }
                &:nth-child(2){
                    top: calc(50% - 2px);
                    transform-origin: center;
                    transform: rotate(-45deg);
                }
    
            }
            
        }
    }
    
}


.logo{
    position: fixed;
    top: 20px;
    left: 50%;
    z-index: 10;
    transform: translateX(-50%);
    width: 24%;
    @media (max-width: 1600px) {
        width: 20%;
    }
    @media (max-width: 576px) {
        position: absolute;
        width: auto;
        top: 5px;
    }
    img{
        max-width: 100%;
        max-height: 100%;
        width: auto;
    }
}

.section{
    height: 100vh;
    padding-top: 206px;
    overflow: hidden;
    position: relative;
    // max-width: 1920px;
    // margin: 0 auto;
    @media (orientation: portrait), (max-width: 1400px) {
        
        height: auto;
        &:not(.section-1){
            padding: 200px 0px;
            // height: auto;
        }
    }
    @media (max-width: 992px) {
        &:not(.section-1){
            padding: 100px 0px;
        }
    }
    // @media (max-width: 1600px) {
    //     height: auto;
    //     min-height: 100vh;
    // }
    @media (max-width: 576px) {
        padding-top: 0px;
    }
}
.section_content{
    height: 100%;
    position: relative;
    z-index: 1;
    padding: 20px 0px 0px;
    @media (max-width: 768px) {
        padding: 20px 25px 0px;
    }
}
.img-fluid{
    max-width: 100%;
    max-height: 100%;
    width: auto;
}
.section_bg{
    position: absolute;
    left: 0px;
    right: 0px;
    top: 0px;
    bottom: 0px;
    z-index: -1;
}
@keyframes foating_cat{
    0%{
        transform: translateX(-5%) translateY(-5%);
    }
    
}
.section-1{
    position: relative;
    z-index: 1;
    .section_content{
        @media (min-height: 675px) and (max-height: 812px) {
            padding-bottom: 50px;
        }
    }
    .row{
        height: 100%;
        position: relative;
        .col-sm-6{
            max-height: 100%;
            
        }
        .img-fluid{
            max-height: 100%;
            max-width: 100%;
            &.main-text{
                max-height: 32%;
            }
            &.main-aquarium{
                max-height: calc(100% - 32%);
                position: relative;
                left: 27%;
            }
            &.main-right{
                position: relative;
                top: -5%;
                right: 5%;
            }
            
        }
    }
    .star{
        position: absolute;
        width: 71px;
        height: 71px;
        background-repeat: no-repeat;
        background-size: contain;
        background-position: center;
        @media (max-width: 1200px) {
            width: 40px;
            height: 40px;
        }
        // &.bl{
        //     background-image: url("data:image/svg+xml,%3Csvg width='58' height='55' viewBox='0 0 58 55' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M24.5821 3.30758C26.4162 -0.408648 31.7154 -0.408645 33.5495 3.30758L38.4364 13.2096C39.1648 14.6853 40.5726 15.7082 42.2011 15.9448L53.1287 17.5327C57.2298 18.1286 58.8673 23.1685 55.8997 26.0611L47.9925 33.7688C46.8141 34.9175 46.2763 36.5725 46.5545 38.1944L48.4212 49.0778C49.1217 53.1624 44.8345 56.2772 41.1664 54.3487L31.3925 49.2103C29.9359 48.4445 28.1957 48.4445 26.7391 49.2103L16.9652 54.3487C13.2971 56.2772 9.00994 53.1624 9.7105 49.0778L11.5771 38.1944C11.8553 36.5725 11.3176 34.9175 10.1392 33.7688L2.23192 26.0611C-0.735664 23.1685 0.901889 18.1286 5.00299 17.5327L15.9305 15.9448C17.5591 15.7082 18.9669 14.6853 19.6952 13.2096L24.5821 3.30758Z' fill='%231AA2B5'/%3E%3C/svg%3E");
        // }
        // &.ye{
        //     background-image: url("data:image/svg+xml,%3Csvg width='58' height='55' viewBox='0 0 58 55' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M24.5821 3.30758C26.4162 -0.408648 31.7154 -0.408645 33.5495 3.30758L38.4364 13.2096C39.1648 14.6853 40.5726 15.7082 42.2011 15.9448L53.1287 17.5327C57.2298 18.1286 58.8673 23.1685 55.8997 26.0611L47.9925 33.7688C46.8141 34.9175 46.2763 36.5725 46.5545 38.1944L48.4212 49.0778C49.1217 53.1624 44.8345 56.2772 41.1664 54.3487L31.3925 49.2103C29.9359 48.4445 28.1957 48.4445 26.7391 49.2103L16.9652 54.3487C13.2971 56.2772 9.00994 53.1624 9.7105 49.0778L11.5771 38.1944C11.8553 36.5725 11.3176 34.9175 10.1392 33.7688L2.23192 26.0611C-0.735664 23.1685 0.901889 18.1286 5.00299 17.5327L15.9305 15.9448C17.5591 15.7082 18.9669 14.6853 19.6952 13.2096L24.5821 3.30758Z' fill='%23FFDA00'/%3E%3C/svg%3E");
        // }
        &:nth-child(1){
            top: 15%;
            left: -1%;
            background-image: url('url:../img/blue-star-1.png?as=webp');
            
        }
        &:nth-child(2){
            top: 83%;
            left: 3%;
            background-image: url('url:../img/yellow-star-1.png?as=webp');
        }
        &:nth-child(3){
            top: 61%;
            left: 7%;
            background-image: url('url:../img/blue-star-2.png?as=webp');
        }
        &:nth-child(4){
            top: 28%;
            left: 13%;
            background-image: url('url:../img/yellow-star-2.png?as=webp');
        }
        &:nth-child(5){
            top: 70%;
            left: 19%;
            background-image: url('url:../img/yellow-star-3.png?as=webp');
        }
        &:nth-child(6){
            top: 12%;
            left: 78%;
            background-image: url('url:../img/yellow-star-4.png?as=webp');
        }
        &:nth-child(7){
            top: 82%;
            left: 82%;
            background-image: url('url:../img/blue-star-3.png?as=webp');
        }
        &:nth-child(8){
            top: 44%;
            left: 89%;
            background-image: url('url:../img/yellow-star-5.png?as=webp');
        }
        &:nth-child(9){
            top: 5%;
            left: 97%;
            background-image: url('url:../img/blue-star-4.png?as=webp');
        }
        &:nth-child(10){
            top: 76%;
            left: 98%;
            background-image: url('url:../img/yellow-star-6.png?as=webp');
        }
    }
    @media (max-width: 576px) {
        height: 100vh;
        height: calc(var(--vh, 1vh) * 100);
        .section_content{
            padding-top: 0px;
        }
        .col-sm-6.text-right{
            height: 100%;
            display: flex;
            flex-direction: column;
            justify-content: space-between;
            align-items: center;
            .main-aquarium{
                max-height: 29%;
                transform: translateX(10%);
                left: unset;
            }
            .main-text{
                max-height: 17%;
                transform: translateX(-25%);
            }
        }
        .col-sm-6.text-left{
            position: absolute;
            height: 100%;
            top: 0px;
            display: flex;
            align-items: center;
            justify-content: center;
            .img-fluid.main-right{
                position: absolute;
                max-height: 55%;
                z-index: -1;
                top: unset;
                bottom: 26%;
                right: unset;
            }
        }
    }
}
.section-2{
    padding-top: 0px;
    .section_bg_img{
        position: absolute;
        &::before{
            background-size: contain;
            background-repeat: no-repeat;
        }
        &:nth-child(1){
            width: 13%;
            transform: rotate(340deg);
            top: 66%;
            left: 7%;
            &::before{
                content: '';
                display: block;
                background-image: url('url:../img/ufo.png?as=webp');
                width: 100%;
                padding-bottom: 97%;
            }
            @media (max-width: 992px) {
                width: 123px;
                top: unset;
                bottom: 0px;
                left: -44px;
            }
           
        }
        &:nth-child(2){
            width: 12%;
            transform: rotate(21.5deg);
            top: 9%;
            left: 16%;
            &::before{
                content: '';
                display: block;
                background-image: url('url:../img/cat-pink.png?as=webp');
                width: 100%;
                padding-bottom: 125%;
            }
            @media (max-width: 992px) {
                left: 1.95px;
                top: 18px;
                width: 100px;
            }
            @media (max-width: 576px) {
                width: 80px;
                
            }
            
        }
        &:nth-child(3){
            width: 10%;
            transform: rotate(19.25deg);
            top: 75%;
            left: 45%;
            &::before{
                content: '';
                display: block;
                background-image: url('url:../img/butterfly.png?as=webp');
                width: 100%;
                padding-bottom: 111%;
            }
            @media (max-width: 992px) {
                display: none;
            }
        }
        &:nth-child(4){
            width: 17%;
            transform: rotate(300deg);
            top: 7%;
            left: 68%;
            &::before{
                content: '';
                display: block;
                background-image: url('url:../img/rocket.png?as=webp');
                width: 100%;
                padding-bottom: 102%;
            }
            @media (max-width: 992px) {
                left: unset;
                right: 8.95px;
                top: 0px;
                width: 148px;
            }
        }
        &:nth-child(5){
            width: 16%;
            transform: rotate(20.35deg);
            top: 62%;
            left: 78%;
            &::before{
                content: '';
                display: block;
                background-image: url('url:../img/cat-black.png?as=webp');
                width: 100%;
                padding-bottom: 93%;
            }
            @media (max-width: 992px) {
                width: 137px;
                top: unset;
                bottom: 0px;
                left: unset;
                right: -44px;
            }
            @media (max-width: 576px) {
                width: 100px;
                right: -30px;
            }
        }
    }
}
.section-3{
    @media (max-width: 992px) {
        &.section{
            padding-bottom: 150px;
        }
        
    }
    
    .section_content{
        height: unset;
        
    }
    .section_bg_img{
        position: absolute;
        &::before{
            background-size: contain;
            background-repeat: no-repeat;
        }
        &:nth-child(1){
            width: 4%;
            top: 22%;
            left: 3%;
            &::before{
                content: '';
                display: block;
                background-image: url('url:../img/blot-blue.png?as=webp');
                width: 100%;
                padding-bottom: 92%;
            }
            @media (max-width: 992px) {
                left: 29px;
                width: 43px;
                top: 113px;
            }
            @media (max-width: 576px) {
                top: 50px;
            }
        }
        &:nth-child(2){
            width: 17%;
            transform: rotate(-7.87deg);
            top: 50%;
            left: 7%;
            &::before{
                content: '';
                display: block;
                background-image: url('url:../img/tube-blue.png?as=webp');
                width: 100%;
                padding-bottom: 147%;
            }
            @media (max-width: 992px) {
                left: -6px;
                width: 197px;
                top: unset;
                bottom: -40px;
            }
        }
        &:nth-child(3){
            width: 19%;
            top: 2%;
            left: 13%;
            &::before{
                content: '';
                display: block;
                background-image: url('url:../img/tube-pink.png?as=webp');
                width: 100%;
                padding-bottom: 128%;
            }
            @media (max-width: 992px) {
                display: none;
            }
            
        }
        &:nth-child(4){
            width: 5%;
            top: 65%;
            left: 71%;
            &::before{
                content: '';
                display: block;
                background-image: url('url:../img/blot-green.png?as=webp');
                width: 100%;
                padding-bottom: 101%;
            }
            @media (max-width: 992px) {
                display: none;
            }
        }
        &:nth-child(5){
            width: 20%;
            top: 5%;
            left: 63%;
            &::before{
                content: '';
                display: block;
                background-image: url('url:../img/tube-black.png?as=webp');
                width: 100%;
                padding-bottom: 105%;
            }
            @media (max-width: 992px) {
                width: 150px;
                top: 30px;
                left: unset;
                right: -26px;
            }
        }
        &:nth-child(6){
            width: 13%;
            top: 38%;
            left: 80%;
            &::before{
                content: '';
                display: block;
                background-image: url('url:../img/tube-green.png?as=webp');
                width: 100%;
                padding-bottom: 226%;
            }
            @media (max-width: 992px) {
                display: none;
            }
        }
    }
    .text{
        margin-bottom: 50px;
    }
    .video_wrapper{
        @media (max-width: 1600px) {
            width: 90%;
            margin: 0 auto;
        }
    }
    .video_inner{
        width: 100%;
        position: relative;
        &::after{
            content: '';
            display: block;
            width: 100%;
            padding-bottom: 56%;
        }
        .player{
            position: absolute;
            top: 0px;
            left: 0px;
            width: 100%;
            height: 100%;
        }
    }
}
.section-4{
    .header{
        margin-bottom: 50px;
    }
    .section_content{
        height: unset;
    }
    .video:hover{
        transform: scale(2);
    }
}
.section-5{
    
    .header{
        margin-bottom: 50px;
    }
    .section_content{
        height: unset;
        
    }
    .cat, .rocket, .ufo{
        position: absolute;
        z-index: -1;
    }
    .rocket{
        left: 0px;
        top: 0px;
        transform: translateX(-30%) translateY(-55%) rotate(50.84deg);
    }
    .ufo{
        right: 0px;
        top: 0px;
        transform: translateX(50%) translateY(-70%) rotate(21.22deg);
    }
    .cat{
        right: 0px;
        top: 90%;
        transform: translateX(50%) translateY(-20%) rotate(22.67deg);
        z-index: 1;
    }
    @media (max-width: 1600px) {
        .cat{
            max-width: 100px;
        }
        .ufo{
            max-width: 150px;
        }
        .rocket{
            max-width: 150px;
        }
    }
    @media (max-width: 1200px) {
        .video-outer{
            margin-bottom: 40px;
        }
        .cat{
            display: none;
        }
        .ufo{
            right: 30px;
            top: unset;
            bottom: -140px;
            
        }
       
    }
    @media (max-width: 576px) {
        .rocket {
            left: 80%;
            top: -120px;
        }
        .ufo {
            right: unset;
            left: 0px;
            transform: translateX(0%) translateY(-70%) rotate(21.22deg);
            bottom: -230px;
        }
    }
    .video-outer{
        transition: all .3s;
    }
    @media (min-width: 1200px) {
        .col-xl-2{
            .video{
                padding: 10px;
                border-radius: 30px;
            }
        }
    }
    @media (min-width: 1200px) {
        .col-xl-2.video-outer{
            min-width: 281px;
        }
        .col-xl-4.video-outer{
            min-width: 594px;
            
        }
        
    }
    @media (max-width: 1200px) {
        .video_wrapper{
            width: 90%;
            margin: 0 auto;
        }
    }
    
    .video_inner{
        width: 100%;
        position: relative;
        &::after{
            content: '';
            display: block;
            width: 100%;
            padding-bottom: 56%;
        }
        .player{
            position: absolute;
            top: 0px;
            left: 0px;
            width: 100%;
            height: 100%;
        }
    }
}
.section-6{
    @media (max-width: 576px) {
        &.section {
            padding-bottom: 0px!important;
        }
    }
    z-index: 2;
    .section_bg_img{
        position: absolute;
        &::before{
            background-size: contain;
            background-repeat: no-repeat;
        }
        &:nth-child(1){
            width: 8%;
            top: 20%;
            left: 81%;
            &::before{
                content: '';
                display: block;
                background-image: url('url:../img/asteroid-1.png?as=webp');
                width: 100%;
                padding-bottom: 59%;
            }
            @media (max-width: 1600px) {
                width: 100px;
                top: 50px;
                left: unset;
                right: 20px;
            }
        }

    }
    @media (min-width: 992px) and (max-width: 1400px) {
        .products{
            .col-xl-2{
                min-width: 240px;
            }
        }
    }
    
    .product{
        display: flex;
        flex-direction: column;
        align-items: center;
        position: relative;
        .owl-item{
            padding-top: 20px;
            cursor: -webkit-zoom-in;
            cursor: zoom-in;
            img {
                transition: all 0.3s;
                cursor: -webkit-zoom-in;
                cursor: zoom-in;
            }
        } 
        .owl-item:hover img {
            transform: translateY(-20px);
        }
        @media (max-width: 992px) {
            max-width: 300px;
            margin: auto;
        }
        &_img{
            position: relative;
            display: flex;
            justify-content: center;
            align-items: center;
            width: 100%;
            transition: all 0.3s;
            img{
                margin: auto;
                max-width: 100%;
            }
            .light{
                position: absolute;
                width: 100%;
                top: 50%;
                transform: translateY(-50%);
                z-index: -1;
                &:before{
                    content: '';
                    width: 100%;
                    display: block;
                    padding-bottom: 100%;
                    background: #9EDBEF;
                    filter: blur(45px);
                    border-radius: 50%;
                }
            }
        }
        &_title{
            text-transform: uppercase;
            font-size: 24px;
            line-height: 28px;
            text-align: center;
            margin-top: 50px;
            margin-bottom: 13px;
            @media (max-width: 576px) {
                font-size: 18px;
                line-height: 21px;
            }
        }
        &_info{
            position: relative;
            width: 100%;
            margin-bottom: 21px;
            line-height: 21px;
            &_text{
                font-size: 18px;
                line-height: 21px;
                border-bottom: 2px dotted #ffffff;
                cursor: pointer;
                margin: auto;
                &::after {
                    content: '';
                    position: absolute;
                    background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='16' height='16' fill='white' class='bi bi-arrow-left' viewBox='0 0 16 16'%3E%3Cpath fill-rule='evenodd' d='M15 8a.5.5 0 0 0-.5-.5H2.707l3.147-3.146a.5.5 0 1 0-.708-.708l-4 4a.5.5 0 0 0 0 .708l4 4a.5.5 0 0 0 .708-.708L2.707 8.5H14.5A.5.5 0 0 0 15 8z'/%3E%3C/svg%3E");
                    background-repeat: no-repeat;
                    background-size: contain;
                    background-position: center center;
                    width: 50px;
                    height: 25px;
                    animation: float-arrow 2.5s infinite cubic-bezier(0.37, 0, 0.63, 1);
                }
                @keyframes float-arrow {
                    0%, 100% {
                      transform: translateX(0%);
                    }
                    25% {
                        transform: translateX(10%);
                    }
                    80% {
                        transform: translateX(0%);
                    }
                    
                    
                }
            }
            &_btn {
                position: relative;
                display: none;
                // &::after {
                //     content: '';
                //     background: url('url:../img/arrow.png') no-repeat center center;
                //     background-size: contain;
                //     position: absolute;
                //     width: 30px;
                //     height: 70px;
                //     transform: translateY(25%);
                //     animation: float-arrow 2.5s infinite cubic-bezier(0.37, 0, 0.63, 1);
                // }
                // @keyframes float-arrow {
                //     0%, 100% {
                //       transform: translateY(25%);
                //     }
                //     25% {
                //         transform: translateY(10%);
                //     }
                //     80% {
                //         transform: translateY(25%);
                //     }
                    
                    
                // }
            }
            ul{
                list-style: inside;
                list-style-type: disc;
                margin-bottom: 0px;
                li{
                    white-space: nowrap;
                }
            }
            
                &_text:hover~.product_info_popup, &_text:focus~.product_info_popup, .product_info_popup:hover{
                    display: block;
                }
            
            &_popup{
                position: absolute;
                display: none;
                bottom: 40px;
                right: 0px;
                background-color: #ffffff;
                color: rgba(41, 36, 86, 1);
                border-radius: 15px;
                padding: 15px 30px 15px 30px;
                width: max-content;
                // min-width: 100%;
                font-size: 18px;
                line-height: 25px;
                left: 50%;
                transform: translateX(-50%);
                z-index: 2;
                &::after {
                    content: '';
                    position: absolute;
                    width: 0; 
                    height: 0; 
                    border-left: 20px solid transparent;
                    border-right: 20px solid transparent;
                    border-top: 20px solid #ffffff;
                    left: 50%;
                    transform: translateX(-50%);
                    bottom: -20px;
                    z-index: 11;
                }
            }
            .close_btn{
                transform: rotate(45deg);
                position: absolute;
                bottom: 8px;
                right: 0px;
                color: rgba(41, 36, 86, 1);
                display: none;
            }
            button{
                color: #ffffff;
                font-size: 28px;
            }
        }
        .buy_button_wrapper{
            position: relative;
            .btn.buy_button {
                position: relative;
                z-index: 2;
            }
            .links{
                position: absolute;
                top: 50%;
                left: calc(100% + 10px);
                transform: translateY(-50%);
                display: flex;
                flex-direction: column;
                align-items: flex-start;
                z-index: -1;
                @media (max-width: 1200px) {
                    flex-direction: column;
                    align-items: center;
                    left: unset;
                    top: unset;
                    top: -80%;
                    width: 100%;
                }
                a{
                    background-color: rgba(147, 242, 255, 1);
                    padding: 5px 15px;
                    margin-bottom: 10px;
                    display: inline-block;
                    border-radius: 25px;
                    transition: opacity .3s cubic-bezier(0.85, 0, 0.15, 1); 
                    visibility: hidden;
                    opacity: 0;
                    position: relative;
                    height: 36px;
                    display: flex;
                    align-items: center;
                    justify-content: center;
                    &>img{
                        display: block;
                    }
                    &:first-child{
                        transform: translateX(-30px);
                        @media (max-width: 1200px) {
                            transform: unset;
                        }
                    }
                    &:last-child{
                        margin-bottom: 0px;
                        transform: translateX(-30px);
                        @media (max-width: 1200px) {
                            transform: unset;
                        }
                    }
                }
                @for $i from 1 through 4 {
                    a:nth-child(#{$i}) {
                      transition-delay: $i * 0.1s;
                    }
                }
                
            }
            .buy_button_overlay {
                z-index: 3;
                display: none;
                background-color: rgba(0, 0, 0, 0.8);
                width: 100vw;
                height: 100vh;
                top: 0px;
                right: 0px;
                position: fixed;
            }
            &.opened{
                .btn.buy_button, .links{
                    z-index: 4;
                }
                .links a{
                    visibility: visible;
                    opacity: 1;
                    transition: all .3s cubic-bezier(0.85, 0, 0.15, 1);
                    @for $i from 1 through 4 {
                        &:nth-child(#{$i}) {
                          transition-delay: $i * 0.1s;
                        }
                    }
                }
                @media (max-width: 1200px) {
                    .buy_button_overlay{
                        display: block;
                    }
                }
            }
        }
        
    }
    .products .col-md-6:nth-child(2) .product_info_btn::after {
        animation-delay: .3s;
    }
    .products .col-md-6:nth-child(3) .product_info_btn::after {
        animation-delay: .6s;
    }
}
.section-7{
    @media (max-width: 576px) {
        &.section {
            padding-bottom: 0px;
        }
    }
    .section_bg_img{
        position: absolute;
        &::before{
            background-size: contain;
            background-repeat: no-repeat;
        }
        &:nth-child(1){
            width: 10%;
            top: 23%;
            left: 9%;
            &::before{
                content: '';
                display: block;
                background-image: url('url:../img/asteroid-2.png?as=webp');
                width: 100%;
                padding-bottom: 61%;
            }
            @media (max-width: 1600px) {
                width: 100px;
                top: 20px;
                left: 20px;
            }
        }
    }
    .text{
        margin-bottom: 50px;
    }
    .blocks{
        display: flex;
        flex-direction: row;
        max-width: 1180px;
        margin: auto;
        justify-content: center;
        
        @media (max-width: 1400px) {
            max-width: 768px;
            flex-wrap: wrap;
        }
        @media (max-width: 768px) {
            flex-direction: column;
            align-items: center;
        }
    }
    .block{
        flex: 1 1 100%;
        padding: 15px;
        &:first-child{
            margin-left: 0px;
        }
        @media (max-width: 1400px) {
            min-width: 280px;
            max-width: 280px;
        }
        @media (max-width: 768px) {
            flex-direction: column;
            align-items: center;
            padding-bottom: 30px;
        }
        
    }
    .block .block_img{
        height: 100px;
        transition: all .3s;
    }
    @media (max-width: 576px) {
        
        .block_title{
            font-size: 18px;
            line-height: 21px;
        }
        .block_text{
            font-size: 14px;
            line-height: 16px;
        }
    }
    .block{
        &:hover{
            .block_img{
                transform: translateY(-20px);
            }
        }
    }
}
.section-8{
    .section_bg_img{
        position: absolute;
        &::before{
            background-size: contain;
            background-repeat: no-repeat;
        }
        &:nth-child(1){
            width: 12%;
            top: 76%;
            left: -1%;
            &::before{
                content: '';
                display: block;
                background-image: url('url:../img/planet-blue.png?as=webp');
                width: 100%;
                padding-bottom: 62%;
            }
            @media (max-width: 1600px) {
                width: 150px;
                top: unset;
                bottom: 0px;
                left: -10px;
                
            }
        }
        &:nth-child(2){
            width: 8%;
            top: 28%;
            left: 82%;
            &::before{
                content: '';
                display: block;
                background-image: url('url:../img/asteroid-3.png?as=webp');
                width: 100%;
                padding-bottom: 56%;
            }
            @media (max-width: 1600px) {
                width: 100px;
                top: 20px;
                left: unset;
                right: 50px;
            }
        }
    }
    .header{
        margin-bottom: 80px;
        @media (max-width: 768px) {
            margin-bottom: 25px;
        }
    }
    .carousel-container{
        min-width: 1124px;
        max-width: 1124px;
        @media (max-width: 1200px) {
            min-width: 843px;
            max-width: 843px;
        }
        @media (max-width: 1040px) {
            min-width: 562px;
            max-width: 562px;
        }
        @media (max-width: 700px) {
            min-width: 281px;
            max-width: 281px;
        }
        @media (max-width: 350px) {
            min-width: unset;
            max-width: 100%;
        }
    }
    .owl-carousel.aqua-theme{
        .owl-next, .owl-prev{
            top: 127px!important;
        }
        .owl-next{
            right: -60px!important;
        }
        .owl-prev{
            left: -60px!important;
        }
        @media (max-width: 475px) {
            .owl-next{
                right: -20px!important;
            }
            .owl-prev{
                left: -20px!important;
            }
        }
    }
    .block{
        &_img{
            width: 100%;
            padding: 20px 20px 0px;
            &_inner{
                position: relative;
                height: unset;
                display: inline-block;
                border-radius: 50%;
                transform: scale(1);
                transition: all .3s;
                box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.1);
                overflow: hidden;
                &::after{
                    content: '';
                    width: 54px;
                    height: 54px;
                    display: block;
                    visibility: hidden;
                    opacity: 0;
                    z-index: 2;
                    position: absolute;
                    top: 50%;
                    left: 50%;
                    transform: translateX(-50%) translateY(-50%);
                    background-image: url("data:image/svg+xml,%3Csvg width='54' height='54' viewBox='0 0 54 54' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M35.2091 22.4673H27.4589V13.6663C27.4589 13.3773 27.2225 13.1408 26.9335 13.1408H22.9927C22.7037 13.1408 22.4673 13.3773 22.4673 13.6663V22.4673H14.7171C14.4281 22.4673 14.1917 22.7037 14.1917 22.9927V26.9335C14.1917 27.2225 14.4281 27.4589 14.7171 27.4589H22.4673V36.26C22.4673 36.549 22.7037 36.7854 22.9927 36.7854H26.9335C27.2225 36.7854 27.4589 36.549 27.4589 36.26V27.4589H35.2091C35.4981 27.4589 35.7345 27.2225 35.7345 26.9335V22.9927C35.7345 22.7037 35.4981 22.4673 35.2091 22.4673ZM53.8621 50.3154L44.2729 40.7262C52.2923 30.9465 51.7341 16.4576 42.5652 7.29534C32.8447 -2.43178 17.0553 -2.43178 7.29534 7.29534C-2.43178 17.0553 -2.43178 32.8447 7.29534 42.5652C16.4576 51.7341 30.9465 52.2923 40.7262 44.2729L50.3154 53.8621C50.5256 54.046 50.8605 54.046 51.0379 53.8621L53.8621 51.0379C54.046 50.8605 54.046 50.5256 53.8621 50.3154ZM39.0842 39.0842C31.2815 46.8803 18.6448 46.8803 10.842 39.0842C3.04589 31.2815 3.04589 18.6448 10.842 10.842C18.6448 3.04589 31.2815 3.04589 39.0842 10.842C46.8803 18.6448 46.8803 31.2815 39.0842 39.0842Z' fill='%23292456'/%3E%3C/svg%3E");
                    transition: all .3s;
                }
                &:hover{
                    cursor: pointer;
                    transform: scale(1.05);
                    box-shadow: 0px 0px 20px rgba(0, 209, 255, 0.7);
                    &::after{
                        visibility: visible;
                        opacity: 1;
                    }
                }
                
            }
        }
        &_title{
            margin-bottom: 30px;
        }
    }
}
.section-9{
    @media (max-width: 576px) {
        &.section {
            padding-top: 0px!important;
        }
    }
    .section_bg_img{
        position: absolute;
        &::before{
            background-size: contain;
            background-repeat: no-repeat;
        }
        &:nth-child(1){
            width: 5%;
            top: 38%;
            left: 9%;
            &::before{
                content: '';
                display: block;
                background-image: url('url:../img/star-3.png?as=webp');
                width: 100%;
                padding-bottom: 103%;
            }
            @media (max-width: 1600px) {
                width: 50px;
                top: 30px;
                left: 20;
            }
            @media (max-width: 576px) {
                display: none;
            }
        }
        &:nth-child(2){
            width: 16%;
            top: 66%;
            left: 85%;
            &::before{
                content: '';
                display: block;
                background-image: url('url:../img/planet-small.png?as=webp');
                width: 100%;
                padding-bottom: 47%;
            }
            @media (max-width: 1600px) {
                width: 150px;
                top: unset;
                left: unset;
                right: -1px;
                bottom: 0px;
            }
        }
    }
    .carousel-container {
        max-width: 900px;
        min-width: 900px;
        @media (max-width: 1040px) {
            max-width: 600px;
            min-width: 600px;
        }
        @media (max-width: 768px) {
            max-width: 300px;
            min-width: 300px;
        }
        @media (max-width: 350px) {
            max-width: 100%;
            min-width: unset;
        }
    }
    .block{
        &_img{
            width: 100%;
            position: relative;
            &::after{
                content: '';
                display: block;
                width: 80%;
                padding-bottom: 80%;
                border-radius: 50%;
                position: absolute;
                bottom: 0px;
                left: 50%;
                transform: translateX(-50%);
                filter: blur(10px);
                z-index: -1;
            }
            &.captain{
                &::after{
                    background-color: #5B99FF;
                }
            }
            &.pilot{
                &::after{
                    background-color: #F9B000;
                }
            }
            &.cook{
                &::after{
                    background-color: #FF3F3F;
                }
            }
            &.doctor{
                &::after{
                    background-color: #02E17B;
                }
            }
            &.engineer{
                &::after{
                    background-color: #FFEE00;
                }
            }
            &.scientist{
                &::after{
                    background-color: #B93DFA;
                }
            }
            img{
                transform: translateY(0);
                animation: floating 3s ease-in-out infinite;
            }
        }
    }
    .row:nth-child(3){
        margin-top: 50px;
    }
    .owl-carousel.aqua-theme .owl-nav .owl-next {
        right: -60px;
    }
    .owl-carousel.aqua-theme .owl-nav .owl-prev {
        left: -60px;
    }
    @media (max-width: 475px) {
        .owl-carousel.aqua-theme .owl-nav .owl-next {
            right: -20px;
        }
        .owl-carousel.aqua-theme .owl-nav .owl-prev {
            left: -20px;
        }
    }
    @media (max-width: 350px) {
        .owl-carousel.aqua-theme .owl-nav .owl-next {
            right: -10px;
        }
        .owl-carousel.aqua-theme .owl-nav .owl-prev {
            left: -10px;
        }
    }
}
.section-10{
    // .asteroids{
    //     background: url('url:../img/galaxy.png?as=webp');
    //     background-position: right top;
    //     background-repeat: no-repeat;
    //     background-size: 10%;
    // }
    .section_bg_img{
        position: absolute;
        &::before{
            background-size: contain;
            background-repeat: no-repeat;
        }
        &:nth-child(1){
            width: 4%;
            top: 74%;
            left: 8%;
            &::before{
                content: '';
                display: block;
                background-image: url('url:../img/star-1.png?as=webp');
                width: 100%;
                padding-bottom: 101%;
            }
        }
        &:nth-child(2){
            width: 17%;
            top: 10%;
            left: 10%;
            &::before{
                content: '';
                display: block;
                background-image: url('url:../img/rocket-2.png?as=webp');
                width: 100%;
                padding-bottom: 87%;
            }
            @media (max-width: 992px) {
                width: 80px;
                top: 30px;
            }
        }
        &:nth-child(3){
            width: 15%;
            bottom: 0%;
            left: 41%;
            &::before{
                content: '';
                display: block;
                background-image: url('url:../img/octopus.png?as=webp');
                width: 100%;
                padding-bottom: 81%;
            }
            @media (max-width: 576px) {
                width: 100px;
            }
        }
        &:nth-child(4){
            width: 4%;
            top: 14%;
            left: 74%;
            &::before{
                content: '';
                display: block;
                background-image: url('url:../img/star-2.png?as=webp');
                width: 100%;
                padding-bottom: 99%;
            }
            @media (max-width: 576px) {
                top: 80px;
            }
        }
        &:nth-child(5){
            width: 24%;
            top: 36%;
            left: 79%;
            &::before{
                content: '';
                display: block;
                background-image: url('url:../img/planet-big.png?as=webp');
                width: 100%;
                padding-bottom: 50%;
            }
        }
        
    }
    .logo-link{
        @media (max-width: 768px) {
            display: block;
            margin-bottom: 30px;
            
        }
    }
    @media (max-width: 768px) {
        .logo-block:last-child a{
            margin-top: -20px;
        }
    }
    .contacts{
        text-align: center;
        margin-top: 50px;
        margin-bottom: 70px;
        .tel{
            font-size: 36px;
            line-height: 43px;
            color: #ffffff;
            text-decoration: none;
        }
        .tel-text{
            font-size: 24px;
            line-height: 28px;
            margin-bottom: 30px;
        }
        @media (max-width: 768px) {
            .tel{
                font-size: 24;
            }
            .tel-text{
                font-size: 18px;
            }
        }
    }
}
.btn{
    // background-color: white;
    color: rgba(41, 36, 86, 1);
    font-size: 24px;
    // line-height: 29px;
    text-transform: uppercase;
    // padding: 8px 20px;
    // box-shadow: 0px 4px 10px rgba(0, 0, 0, 0.25);
    position: relative;
    border-radius: 25px;
    &:hover{
        text-decoration: none;
        color: rgba(41, 36, 86, 1);
    }
    &.btn-blue{
        background: #292456;
        box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.1);
        border-radius: 25px;
        color: #ffffff;
        padding: 2px 15px;
    }
}
svg.liquid-button{
    filter: drop-shadow(0px 4px 10px rgba(0,0,0,.25));
}
.block{
    &_img{
        width: 100px;
        margin: 0px auto 20px;
        svg, img{
            max-width: 100%;
            height: auto;
            max-height: 100%;
        }
    }
    &_title{
        font-size: 24px;
        line-height: 29px;
        text-align: center;
        letter-spacing: 0.03em;
        text-transform: uppercase;
        margin-bottom: 10px;
    }
    &_text{
        font-size: 18px;
        line-height: 22px;
        text-align: center;
        letter-spacing: 0.03em;
    }
}
.video_wrapper{

    .video_title{
        margin-top: 25px;
        font-size: 24px;
        line-height: 28px;
    }
    .video{
        max-height: 100%;
        padding: 18px;
        background-color: #ffffff;
        filter: drop-shadow(0px 4px 8px rgba(0, 0, 0, 0.1));
        border-radius: 40px;
        overflow: hidden;
        @media (max-width: 576px) {
            padding: 10px;
            border-radius: 30px;
        }
        &_inner{
            border: 6px solid #292456;
            filter: drop-shadow(0px 4px 8px rgba(0, 0, 0, 0.1));
            border-radius: 20px;
            overflow: hidden;
            position: relative;
            display: flex;
            justify-content: center;
            align-items: center;
        }
        .play-btn {
            /*  border: 1px solid red;*/
              display: inline-block;
              transition: all 0.5s ease;
              position: absolute;
              z-index: 1;
              width: 20%;
              z-index: 1;
              background-color: rgba(255, 255, 255, 0.5);
              border-radius: 50%;
              max-width
              ::after{
                  content: '';
                  padding-bottom: 100%;
              }
              svg{
                  width: 100%;
                  height: 100%;
              }
              .circle {
                stroke: #292456;
                stroke-dasharray: 650;
                stroke-dashoffset: 650;
                -webkit-transition: all 0.5s ease-in-out;
                opacity: 0.3;
              }
              .triangle {
                transition: all 0.7s ease-in-out;
                stroke-dasharray: 240;
                stroke-dashoffset: 480;
                stroke: #292456;
                transform: translateY(0) 
               }
              
              
              &:hover {
                cursor: pointer;
                .triangle {
                  stroke-dashoffset: 0;
                  opacity: 1;
                  stroke: #292456;
                  animation: nudge 0.7s ease-in-out;
                  
                  @keyframes nudge{
                    0% {
                      transform: translateX(0)  
                    }
                    30% {
                      transform: translateX(-5px)
                    }
                    50% {
                      transform: translateX(5px)
                    }
                    70% {
                      transform: translateX(-2px)
                    }
                    100% {
                      transform: translateX(0)
                    }
                  }
                }
                
                .circle {
                  stroke-dashoffset: 0;
                  opacity: 1;
                  
                }
                
              }
        }
    }
}
.modal.aqua{
    .modal-content{
        background: #D8F1FF;
        border: 11px solid #292456;
        box-shadow: 0px 4px 15px rgba(0, 0, 0, 0.25);
        border-radius: 23px;
        padding: 50px;
        &_img{
            filter: drop-shadow(0px 4px 15px rgba(0, 0, 0, 0.25));
            img{
                max-width: 100%;
            }
        }
        .close{
            position: absolute;
            top: 20px;
            right: 20px;
            font-size: 35px;
            line-height: 20px;
            font-weight: 600;
            overflow: hidden;
            padding: 0px;
            span{
                font-size: 35px;
                line-height: 20px;
                display: inline-block;
            }
        }
    }
}
#contact-form{
    .modal-content{
        background-color: #ffffff;
    }
    input, textarea{
        max-width: 100%;
        width: 100%;
        border: none;
        border-bottom: 2px solid rgba(41, 36, 86, 1);
        font-size: 18px;
        &:focus{
            border: none;
            border-bottom: 2px solid rgba(41, 36, 86, 1);
            outline: none;
        }
    }
    input{
        margin-bottom: 20px;
        &:last-child{
            margin-bottom: 0px;
        }
    }
    textarea{
        height: 100%;
    }
    .form-footer{
        margin-top: 20px;
    }
    .checkbox{
        font-size: 12px;
        margin-bottom: 0px;
        input[type=checkbox]{
            display: none;
            &:checked+.checkbox-icon::after{
                display: block;
            }
        }
        @media (max-width: 768px) {
            margin-bottom: 16px;
        }
        .checkbox-icon{
            min-width: 26px;
            min-height: 26px;
            border: 3px solid #292456;
            box-sizing: border-box;
            border-radius: 50%;
            position: relative;
            margin-right: 10px;
            &::after{
                content: '';
                width: 14px;
                height: 14px;
                border-radius: 50%;
                position: absolute;
                top: 50%;
                left: 50%;
                transform: translateX(-50%) translateY(-50%);
                background-color: #292456;
                display: none;

            }
        }
        color:rgba(41, 36, 86, 1);
    }
}
.owl-carousel.aqua-theme{
    .owl-nav{
        .owl-next, .owl-prev{
            width: 26px;
            height: 30px;
            background-image: url("data:image/svg+xml,%3Csvg width='26' height='30' viewBox='0 0 26 30' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M24 11.5359C26.6667 13.0755 26.6667 16.9245 24 18.4641L6.00001 28.8564C3.33334 30.396 4.38926e-06 28.4715 3.98547e-06 25.3923L1.2599e-06 4.6077C8.56109e-07 1.5285 3.33333 -0.396005 6 1.1436L24 11.5359Z' fill='white'/%3E%3C/svg%3E");
            background-repeat: no-repeat;
            background-size: contain;
            background-position: center;
            filter: drop-shadow(0px 4px 10px rgba(0, 0, 0, 0.1));
            position: absolute;
            top: calc(50% - 10px);
            &::after{
                content: '';
                display: block;
                width: 18px;
                height: 20px;
                background-image: url("data:image/svg+xml,%3Csvg width='18' height='20' viewBox='0 0 18 20' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M17 8.26795C18.3333 9.03775 18.3333 10.9622 17 11.7321L3.5 19.5263C2.16667 20.2961 0.500001 19.3338 0.500001 17.7942L0.499999 2.20577C0.499999 0.666172 2.16666 -0.296078 3.5 0.473722L17 8.26795Z' fill='%23FFDB00'/%3E%3C/svg%3E");
                background-repeat: no-repeat;
                background-size: contain;
                background-position: center;
                position: absolute;
                top: 50%;
                left: 44%;
                transform: translateX(-50%) translateY(-50%);
                filter: drop-shadow(0px 4px 10px rgba(0, 0, 0, 0.1));
                visibility: hidden;
                opacity: 0;
                transition: all .3s;
            }
            &:hover{
                &::after{
                    visibility: visible;
                    opacity: 1;
                }
            }
        }
        .owl-next{
            right: -26px;
        }
        .owl-prev{
            transform: rotate(180deg);
            left: -26px;
            &::after{
                left: 48%;
            }
        }
    }
}

.before-test{
//    display: none;
}
.test-container{
    position: relative;
    min-height: 400px;
}
.test{
    display: none;
    position: absolute;
    width: 100%;
    
    top: 0px;
    left: 0px;
    .navigation{
        @media (max-width: 768px) {
            max-width: 300px;
        }
        .info{
            margin: 0px 140px;
            font-size: 24px;
            @media (max-width: 768px) {
                margin: 0 40px;
            }
        }
        .right, .left{
            width: 26px;
            height: 30px;
            padding: 0px;
            background-image: url("data:image/svg+xml,%3Csvg width='26' height='30' viewBox='0 0 26 30' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M24 11.5359C26.6667 13.0755 26.6667 16.9245 24 18.4641L6.00001 28.8564C3.33334 30.396 4.38926e-06 28.4715 3.98547e-06 25.3923L1.2599e-06 4.6077C8.56109e-07 1.5285 3.33333 -0.396005 6 1.1436L24 11.5359Z' fill='white'/%3E%3C/svg%3E");
            background-repeat: no-repeat;
            background-size: contain;
            background-position: center;
            filter: drop-shadow(0px 4px 10px rgba(0, 0, 0, 0.1));

            &::after{
                content: '';
                display: block;
                width: 18px;
                height: 20px;
                background-image: url("data:image/svg+xml,%3Csvg width='18' height='20' viewBox='0 0 18 20' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M17 8.26795C18.3333 9.03775 18.3333 10.9622 17 11.7321L3.5 19.5263C2.16667 20.2961 0.500001 19.3338 0.500001 17.7942L0.499999 2.20577C0.499999 0.666172 2.16666 -0.296078 3.5 0.473722L17 8.26795Z' fill='%23FFDB00'/%3E%3C/svg%3E");
                background-repeat: no-repeat;
                background-size: contain;
                background-position: center;
                position: absolute;
                top: 50%;
                left: 44%;
                transform: translateX(-50%) translateY(-50%);
                filter: drop-shadow(0px 4px 10px rgba(0, 0, 0, 0.1));
                visibility: hidden;
                opacity: 0;
                transition: all .3s;
            }
            &:hover{
                cursor: pointer;
                &::after{
                    visibility: visible;
                    opacity: 1;
                }
            }
            &:disabled{
                cursor: not-allowed;
                opacity: .5;
            }

        }
        .left{
            transform: rotate(180deg);
            transform-origin: center;
            &::after{
                left: 45%;
            }
        }
    }
}
.test-result{
    display: none;
    // opacity: 0;
    // position: absolute;
    width: 100%;
    height: 100%;
    top: 0px;
    left: 0px;
    &__container{
        max-width: 1040px;
        padding: 0 50px;
        margin-left: auto;
        margin-right: auto;
        @media (max-width: 768px) {
            padding: 0;
        }
    }
    .res-name{
        font-size: 24px;
        text-transform: uppercase;
        max-width: 170px;
        text-align: center;
        @media (max-width: 768) {
            margin-bottom: 30px;
        }
    }
    .res-slogan, .res-text{
        font-size: 18px;
        line-height: normal;
    }
    .res-slogan{
        text-transform: uppercase;
        margin-bottom: 30px;
    }
    .owl-carousel.aqua-theme{
        .owl-stage{
            @media (min-width: 769px) {
                display: flex;
                align-items: center;
                justify-content: center;
            }
        }
        .owl-next{
            right: -30px!important;
        }
        .owl-prev{
            left: -30px!important;
        }
        @media (max-width: 768px) {
            .owl-next, .owl-prev{
                top: 167px;
                
            }
            .owl-next{
                right: -10px!important;
            }
            .owl-prev{
                left: -10px!important;
            }
           
        }
    }
    .owl-carousel.aqua-theme{
        visibility: hidden;
        opacity: 0;
        transition: all 2s;
    }
    &.show{
        display: block;
        // position: static;
        .owl-carousel.aqua-theme{
            visibility: visible;
            opacity: 1;
        }
    }
}
.progress-bar-wrapper{
    width: 100%;
    height: 35px;
    background-color: #fff;
    border-radius: 22px;
    position: relative;
    overflow: hidden;
    margin-bottom: 40px;
    @media (max-width: 1200px) {
        margin-top: 40px;
    }
    @media (max-width: 768px) {
        height: 26px;
        max-width: 300px;
    }
    .progress-bar{
        width: 0%;
        height: 100%;
        border-radius: 22px;
        position: absolute;
        left: 0px;
        top: 0px;
        z-index: 1;
        background-color: #50C2F0;
        transition: all .3s;
    }
}
.steps{
    position: relative;
    .step{
        position: absolute;
        visibility: hidden;
        opacity: 0;
        z-index: -1;
        // top: 0px;
        // left: 0px;
        transform: translateX(100%);
        transition: all .5s cubic-bezier(0.87, 0, 0.13, 1);
        &.active{
            position: relative;
            visibility: visible;
            opacity: 1;
            z-index: 0;
            transform: translateX(0%);
        }
        &.prev{
            transform: translateX(-100%);
        }
    }
    .step-number{
        font-size: 24px;
        margin-bottom: 25px;
        line-height: 1;
        @media (max-width: 768px) {
            font-size: 14px;
        }

    }
    .question {
        font-size: 36px;
        margin-bottom: 40px;
        line-height: 1;
        text-align: center;
        min-height: 75px;
        @media (max-width: 768px) {
            font-size: 24px;
            min-height: 50px;
        }
    }
}
.answers-wrapper{
    margin-bottom: 40px;
    .answers{
        position: relative;
        &:before{
            content: 'согласен';
            position: absolute;
            left: -50px;
            top: 50%;
            transform: translateX(-100%) translateY(-50%);
            font-size: 24px;
            color: #42FF00;
            @media (max-width: 1200px) {
                left: 0px;
                top: calc(100% + 20px);
                transform: unset;
            }
            @media (max-width: 768px) {
                font-size: 14px;
            }
        }
        
        &:after{
            content: 'не согласен';
            position: absolute;
            right: -50px;
            top: 50%;
            transform: translateX(100%) translateY(-50%);
            font-size: 24px;
            color: #FF0000;
            @media (max-width: 1200px) {
                right: 0px;
                top: calc(100% + 20px);
                transform: unset;
            }
            @media (max-width: 768px) {
                font-size: 14px;
            }
        }
    }
    .answer{
        border-radius: 50%;
        overflow: hidden;
        margin-right: 40px;
        position: relative;
        @media (max-width: 768px) {
            margin-right: 10px; 
        }
        &::after{
            content: '';
            width: 70%;
            height: 70%;
            position: absolute;
            top: 50%;
            left: 50%;
            display: block;
            transform: translateX(-50%) translateY(-50%);
            background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='16' height='16' fill='currentColor' class='bi bi-check' viewBox='0 0 16 16'%3E%3Cpath fill='white' d='M10.97 4.97a.75.75 0 0 1 1.07 1.05l-3.99 4.99a.75.75 0 0 1-1.08.02L4.324 8.384a.75.75 0 1 1 1.06-1.06l2.094 2.093 3.473-4.425a.267.267 0 0 1 .02-.022z'/%3E%3C/svg%3E");
            background-size: cover;
            background-position: center;
            background-repeat: no-repeat;
            visibility: hidden;
            opacity: 0;
        }
        &.checked{
            &::after{
                visibility: visible;
                opacity: 1;
            }
        }
        &:last-child{
            margin-right: 0px;
        }
        :hover{
            cursor: pointer;
        }
        svg{
            width: 100%;
            height: 100%;
        }
        &.max{
            width: 100px;
            height: 100px;
            min-width: 100px;
            min-height: 100px;
            @media (max-width: 768px) {
                width: 55px;
                height: 55px;
                min-width: 55px;
                min-height: 55px; 
            }
            @media (max-width: 375px) {
                width: 40px;
                height: 40px;
                min-width: 40px;
                min-height: 40px; 
            }
            &.positive:hover, &.positive.checked{
                background: linear-gradient(to right, #42FF00 0.74%, #FFD700 333.55%);
            }
            &.negative:hover, &.negative.checked{
                // background: linear-gradient(89.99deg,  #FFD700 0%, #FF0000 100%);
                background: linear-gradient(to left, #FF0000 0.01%, #FFD700 311.6%);
            }
            
        }
        &.mid{
            width: 70px;
            height: 70px;
            min-width: 70px;
            min-height: 70px;
            @media (max-width: 768px) {
                width: 40px;
                height: 40px;
                min-width: 40px;
                min-height: 40px; 
            }
            @media (max-width: 375px) {
                width: 35px;
                height: 35px;
                min-width: 35px;
                min-height: 35px; 
            }
            &.positive:hover, &.positive.checked{
                background: linear-gradient(to right, #42FF00 -167.91%, #FFD700 432.09%);
            }
            &.negative:hover, &.negative.checked{
                background: linear-gradient(to left, #FF0000 -194.74%, #FFD700 244.56%);
            }
        }
        &.min{
            width: 60px;
            height: 60px;
            min-width: 60px;
            min-height: 60px;
            @media (max-width: 768px) {
                width: 35px;
                height: 35px;
                min-width: 35px;
                min-height: 35px; 
            }
            @media (max-width: 375px) {
                width: 28px;
                height: 28px;
                min-width: 28px;
                min-height: 28px; 
            }
            &.positive:hover, &.positive.checked{
                background: linear-gradient(to right, #42FF00 -341.38%, #FFD700 224.14%);
            }
            &.negative:hover, &.negative.checked{
                background: linear-gradient(to left, #FF0000 -407.53%, #FFD700 99.98%);
            }
        }
        &.neutral{
            width: 50px;
            height: 50px;
            min-width: 50px;
            min-height: 50px;
            @media (max-width: 768px) {
                width: 30px;
                height: 30px;
                min-width: 30px;
                min-height: 30px; 
            }
            @media (max-width: 375px) {
                width: 25px;
                height: 25px;
                min-width: 25px;
                min-height: 25px; 
            }
            &:hover, &.checked{
                background: linear-gradient(to right, #42FF00 -680.77%, #FFD700 100%);
            }
        }

    }
}
.cta-btn {
    position: fixed;
    bottom: 0px;
    right: 0px;
    color: white;
    z-index: 50;
    background-color: #ff0072;
    border-top-left-radius: 10px;
    padding: 8px 18px;
    transition: all 0.3s;
    font-size: 18px;
    filter: drop-shadow(0px -4px 8px rgba(0, 0, 0, 0.1));
    text-transform: uppercase;
    &:hover {
        color: #292456;
        text-decoration: none;
        background-color: #FFDB00;
    }
}
@media (min-width: 450px) {
    .cta-btn {
        display: none;
    }
}
// @media (max-width: 500px) {
//     .section-3{
//         order: 4;
//     }
//     .section-4 {
//         order: 5
//     }
//     .section-5{
//         order: 6;
//     }
//     .section-6{
//         order: 3
//     }
// }
