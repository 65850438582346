.response {
  visibility: hidden;
  opacity: 0;
  position: absolute;
  max-width: 80%;
  top: 50%;
  background: #292456;
  margin: auto;
  transform: translateX(0%) translateY(-50%);
  left: 50%;
  color: white;
  padding: 30px;
  border-radius: 10px;
  transition: all .5s;
  &.show{
    visibility: visible;
    opacity: 1;
    transform: translateX(-50%) translateY(-50%);
  }
}
.checkbox-icon.error {
    box-shadow: 0px 0px 5px 3px red;
}