.bubbles {
    // position: fixed;
    // width: 100vw;
    // height: 100vh;
    // overflow: hidden;
    // top: 0px;
    position: relative;
    overflow: hidden;
    display: flex;
    flex-direction: column;

}

.bubbles.trashed{
    animation: trashed 3s;
    animation-timing-function: cubic-bezier(0.87, 0, 0.13, 1);
    // animation-timing-function: cubic-bezier(0.76, 0, 0.24, 1);
    // animation-timing-function: cubic-bezier(0.36, 0, 0.66, -0.56);
    // animation-timing-function: cubic-bezier(0.85, 0, 0.15, 1);;
}
@keyframes trashed {
    0%{
        top: -100px
    }
    25% {
        top: -500px
    }
    100% {
        top: -100px
    }
    
}
  .bubbles>li {
    position: absolute;
    list-style: none;
    display: block;
    text-align: center;
    width: 100px;
    padding: 10px;
    height: 100px;
    border-radius: 50%;
    // border: 1px solid rgba(255, 255, 255, 0.2);
    border: 1px solid rgba(10, 25, 153, 0.2);
    background-color: rgba(255, 255, 255, 0.15);
    // box-shadow: inset -3px 1px 10px rgba(255, 255, 255, 0.5), inset 0 0 35px rgba(50, 100, 100, 0.2);
    box-shadow: inset -3px 1px 10px rgba(10, 25, 153, 0.5), inset 0 0 35px rgba(50, 100, 100, 0.2);
    -webkit-animation: float 25s infinite;
    animation: float 25s infinite;
    transition-timing-function: linear;
    bottom: -200px;
    opacity: 0;
  }
  .bubbles>li:after {
    display: block;
    content: "";
    height: 8px;
    width: 11px;
    transform: rotate(40deg);
    background: rgba(255, 255, 255, 0.3);
    box-shadow: 0 0 5px rgba(255, 255, 255, 0.3);
    border-radius: 50%;
    position: absolute;
    top: 20%;
    right: 20%;
  }
  .bubbles>li:before {
    display: block;
    content: "";
    height: 15px;
    width: 20px;
    background: rgba(255, 255, 255, 0.1);
    box-shadow: 0 0 5px rgba(255, 255, 255, 0.2);
    border-radius: 50%;
    position: absolute;
    bottom: 20%;
    left: 20%;
    transform: rotate(40deg);
  }
  .bubbles>li:nth-child(1) {
    left: 10%;
  }
  .bubbles>li:nth-child(2) {
    left: 20%;
    transform: scale(0.5, 0.5);
    -webkit-animation-delay: 2s;
            animation-delay: 2s;
    -webkit-animation-duration: 17s;
            animation-duration: 17s;
  }
  .bubbles>li:nth-child(3) {
    left: 25%;
    -webkit-animation-delay: 4s;
            animation-delay: 4s;
  }
  .bubbles>li:nth-child(4) {
    left: 40%;
    transform: scale(0.3, 0.3);
    -webkit-animation-duration: 22s;
            animation-duration: 22s;
    background-color: rgba(255, 255, 255, 0.25);
  }
  .bubbles>li:nth-child(5) {
    left: 70%;
    transform: scale(0.4, 0.4);
  }
  .bubbles>li:nth-child(6) {
    left: 80%;
    transform: scale(0.6, 0.6);
    -webkit-animation-delay: 3s;
            animation-delay: 3s;
    background-color: rgba(255, 255, 255, 0.1);
  }
  .bubbles>li:nth-child(7) {
    left: 32%;
    transform: scale(0.8, 0.8);
    -webkit-animation-delay: 7s;
            animation-delay: 7s;
  }
  .bubbles>li:nth-child(8) {
    left: 55%;
    transform: scale(0.2, 0.2);
    -webkit-animation-delay: 15s;
            animation-delay: 15s;
    -webkit-animation-duration: 40s;
            animation-duration: 40s;
  }
  .bubbles>li:nth-child(9) {
    left: 25%;
    transform: scale(0.1, 0.1);
    -webkit-animation-delay: 2s;
            animation-delay: 2s;
    -webkit-animation-duration: 40s;
            animation-duration: 40s;
  }
  .bubbles>li:nth-child(10) {
    left: 85%;
    transform: scale(1, 1);
    -webkit-animation-delay: 11s;
            animation-delay: 11s;
  }
  .bubbles>li:nth-child(11) {
    left: 85%;
    transform: scale(0.5, 0.5);
    -webkit-animation-delay: 1s;
            animation-delay: 1s;
  }
  .bubbles>li:nth-child(12) {
    left: 45%;
    transform: scale(0.4, 0.4);
    -webkit-animation-delay: 6s;
            animation-delay: 6s;
    -webkit-animation-duration: 40s;
            animation-duration: 40s;
  }
  .bubbles>li:nth-child(13) {
    left: 15%;
    transform: scale(0.6, 0.6);
    -webkit-animation-delay: 7s;
            animation-delay: 7s;
  }
  .bubbles>li:nth-child(14) {
    left: 15%;
    transform: scale(0.9, 0.9);
    -webkit-animation-delay: 1s;
            animation-delay: 1s;
    -webkit-animation-duration: 15s;
            animation-duration: 15s;
  }
  .bubbles>li:nth-child(15) {
    left: 86%;
    transform: scale(0.3, 0.3);
    -webkit-animation-delay: 20s;
            animation-delay: 20s;
    -webkit-animation-duration: 22s;
            animation-duration: 22s;
  }
  .bubbles>li:nth-child(16) {
    left: 34%;
    transform: scale(1, 1);
    -webkit-animation-delay: 7s;
            animation-delay: 7s;
    -webkit-animation-duration: 6s;
            animation-duration: 6s;
  }
  .bubbles>li:nth-child(17) {
    left: 2%;
    transform: scale(0.7, 0.7);
    -webkit-animation-delay: 32s;
            animation-delay: 32s;
    -webkit-animation-duration: 11s;
            animation-duration: 11s;
  }
  .bubbles>li:nth-child(18) {
    left: 73%;
    transform: scale(0.4, 0.4);
    -webkit-animation-delay: 14s;
            animation-delay: 14s;
    -webkit-animation-duration: 14s;
            animation-duration: 14s;
  }
  .bubbles>li:nth-child(19) {
    left: 59%;
    transform: scale(0.8, 0.8);
    -webkit-animation-delay: 2s;
            animation-delay: 2s;
    -webkit-animation-duration: 5s;
            animation-duration: 5s;
  }
  .bubbles>li:nth-child(20) {
    left: 11%;
    transform: scale(0.3, 0.3);
    -webkit-animation-delay: 86s;
            animation-delay: 86s;
    -webkit-animation-duration: 28s;
            animation-duration: 28s;
  }
  .bubbles>li:nth-child(21) {
    left: 44%;
    transform: scale(0.9, 0.9);
    -webkit-animation-delay: 48s;
            animation-delay: 48s;
    -webkit-animation-duration: 10s;
            animation-duration: 10s;
  }
  .bubbles>li:nth-child(22) {
    left: 93%;
    transform: scale(0.7, 0.7);
    -webkit-animation-delay: 32s;
            animation-delay: 32s;
    -webkit-animation-duration: 21s;
            animation-duration: 21s;
  }
  .bubbles>li:nth-child(23) {
    left: 31%;
    transform: scale(0.5, 0.5);
    -webkit-animation-delay: 60s;
            animation-delay: 60s;
    -webkit-animation-duration: 7s;
            animation-duration: 7s;
  }
  .bubbles>li:nth-child(24) {
    left: 69%;
    transform: scale(0.3, 0.3);
    -webkit-animation-delay: 48s;
            animation-delay: 8s;
    -webkit-animation-duration: 19s;
            animation-duration: 19s;
  }

@-webkit-keyframes float {
    0% {
      bottom: -200px;
      opacity: 0;
      margin-left: 0;
    }
    5% {
      margin-left: 0;
      opacity: 1;
    }
    10% {
      margin-left: 10px;
    }
    15% {
      margin-left: 0;
    }
    20% {
      margin-left: 15px;
    }
    25% {
      margin-left: 0;
    }
    30% {
      margin-left: 10px;
    }
    35% {
      margin-left: 0;
    }
    40% {
      margin-left: 10px;
    }
    45% {
      margin-left: 0;
    }
    50% {
      margin-left: 20px;
    }
    55% {
      margin-left: 0;
    }
    60% {
      margin-left: 10px;
    }
    65% {
      margin-left: 0;
    }
    70% {
      margin-left: 10px;
    }
    75% {
      margin-left: 0;
    }
    80% {
      margin-left: 15px;
    }
    85% {
      margin-left: 0;
    }
    90% {
      margin-left: 10px;
    }
    95% {
      margin-left: 0;
    }
    100% {
      bottom: 120%;
      margin-left: 0;
    }
}
@keyframes float {
    0% {
      bottom: -120px;
      margin-left: 0;
      opacity: 0;
    }
    5% {
      margin-left: 0;
      opacity: 0;
      
    }
    10% {
      margin-left: 10px;
      opacity: 0;
    }
    15% {
      margin-left: 0;
      
    }
    20% {
      margin-left: 15px;

    }
    25% {
      margin-left: 0;
      
    }
    30% {
      margin-left: 10px;
    }
    35% {
      margin-left: 0;
      opacity: 1;
    }
    40% {
      margin-left: 10px;
    }
    45% {
      margin-left: 0;
    }
    50% {
      margin-left: 20px;
    }
    55% {
      margin-left: 0;
    }
    60% {
      margin-left: 10px;
    }
    65% {
      margin-left: 0;
    }
    70% {
      margin-left: 10px;
    }
    75% {
      margin-left: 0;
    }
    80% {
      margin-left: 15px;
    }
    85% {
      margin-left: 0;
    }
    90% {
      margin-left: 10px;
    }
    95% {
      margin-left: 0;
    }
    100% {
      bottom: 110%;
      margin-left: 0;
    }
}




//rays
ul.rays {
    position: absolute;
    left: -10em;
    right: -10em;
    top: -100%;
    bottom: 290px;
    margin: auto;
    padding: 0;
    width: 0;
    height: 0;
    list-style: none;
    opacity: 0.2;
    filter: blur(10px);
  }
  
  ul.rays.left {
    -webkit-animation: 60s rotate-l infinite linear;
            animation: 60s rotate-l infinite linear;
  }
  
  ul.rays.right {
    -webkit-animation: 40s rotate-r infinite linear;
            animation: 40s rotate-r infinite linear;
  }
  
  .rays > li,
  .rays > li:before,
  .rays > li:after {
    position: absolute;
    border: 0 solid transparent;
    border-width: 1.2em 20em;
    border-color: transparent rgba(255, 255, 255, 0.1);
    width: 0;
    height: 0;
    font-size: 60px;
  }
  
  .rays > li {
    left: -20em;
    top: 40%;
    margin-top: -1.2em;
    transform: rotate(0.1deg);
  }
  
  .rays > li:before,
  .rays > li:after {
    left: -20em;
    top: -1.2em;
    display: block;
    content: "";
  }
  
  .rays > li:before {
    transform: rotate(60deg);
  }
  
  .rays > li:after {
    transform: rotate(-60deg);
  }
  
  .rays > li:nth-child(2) {
    transform: rotate(13deg);
  }
  
  .rays > li:nth-child(2),
  .rays > li:nth-child(2):before,
  .rays > li:nth-child(2):after {
    border-color: transparent rgba(255, 255, 255, 0.2);
  }
  
  .rays > li:nth-child(3) {
    transform: rotate(37deg);
  }
  
  .rays > li:nth-child(3),
  .rays > li:nth-child(3):before,
  LI:nth-child(3):after {
    border-color: transparent rgba(230, 247, 255, 0.2);
  }
  
  .rays > li:nth-child(4) {
    transform: rotate(55deg);
  }
  
  .rays > li:nth-child(4),
  .rays > li:nth-child(4):before,
  .rays > li:nth-child(4):after {
    border-color: transparent rgba(255, 255, 255, 0.1);
  }
  
  @-webkit-keyframes rotate-l {
    100% {
      transform: rotate(361deg);
    }
  }
  
  @keyframes rotate-l {
    100% {
      transform: rotate(363deg);
    }
  }
  @-webkit-keyframes rotate-r {
    100% {
      transform: rotate(-365deg);
    }
  }
  @keyframes rotate-r {
    100% {
      transform: rotate(-367deg);
    }
  }
  // .rays:before {
  //   background: conic-gradient(rgba(255, 255, 255, 0.42), rgba(255, 255, 255, 0.45), rgba(255, 255, 255, 0.69), rgba(87, 199, 255, 0.01), rgba(230, 247, 255, 0.22), rgba(240, 250, 255, 0.11), rgba(255, 255, 255, 0.53), rgba(255, 255, 255, 0.97), rgba(255, 255, 255, 0.08), rgba(255, 255, 255, 0.73), rgba(255, 255, 255, 0.77), rgba(133, 214, 255, 0.35), rgba(148, 219, 255, 0.99), rgba(255, 255, 255, 0.7), rgba(250, 253, 255, 0.66), rgba(240, 250, 255, 0.07), rgba(255, 255, 255, 0.49), rgba(26, 179, 255, 0.39), rgba(230, 247, 255, 0.57), rgba(255, 255, 255, 0.27), rgba(82, 197, 255, 0.73), white, rgba(51, 187, 255, 0.53), rgba(255, 255, 255, 0.42), rgba(255, 255, 255, 0.45), rgba(255, 255, 255, 0.69), rgba(87, 199, 255, 0.01), rgba(230, 247, 255, 0.22), rgba(240, 250, 255, 0.11), rgba(255, 255, 255, 0.53), rgba(255, 255, 255, 0.97), rgba(255, 255, 255, 0.08), rgba(255, 255, 255, 0.73), rgba(255, 255, 255, 0.77), rgba(133, 214, 255, 0.35), rgba(148, 219, 255, 0.99), rgba(255, 255, 255, 0.7), rgba(250, 253, 255, 0.66), rgba(240, 250, 255, 0.07), rgba(255, 255, 255, 0.49), rgba(26, 179, 255, 0.39), rgba(230, 247, 255, 0.57), rgba(255, 255, 255, 0.27), rgba(82, 197, 255, 0.73), white);
  // }
  
  // .rays:after {
  //   background: conic-gradient(rgba(255, 255, 255, 0.11), rgba(10, 173, 255, 0.91), rgba(255, 255, 255, 0.41), rgba(255, 255, 255, 0.81), rgba(255, 255, 255, 0.52), rgba(245, 252, 255, 0.38), rgba(204, 238, 255, 0.04), rgba(56, 189, 255, 0.61), rgba(128, 213, 255, 0.74), rgba(255, 255, 255, 0.92), rgba(214, 241, 255, 0.29), rgba(41, 184, 255, 0.9), rgba(255, 255, 255, 0.49), rgba(255, 255, 255, 0.29), rgba(199, 236, 255, 0.48), rgba(138, 216, 255, 0.41), rgba(255, 255, 255, 0.37), rgba(255, 255, 255, 0.6), rgba(255, 255, 255, 0.09), rgba(5, 172, 255, 0.95), rgba(255, 255, 255, 0.49), rgba(255, 255, 255, 0.91), rgba(255, 255, 255, 0.11), rgba(10, 173, 255, 0.91), rgba(255, 255, 255, 0.41), rgba(255, 255, 255, 0.81), rgba(255, 255, 255, 0.52), rgba(245, 252, 255, 0.38), rgba(204, 238, 255, 0.04), rgba(56, 189, 255, 0.61), rgba(128, 213, 255, 0.74), rgba(255, 255, 255, 0.92), rgba(214, 241, 255, 0.29), rgba(41, 184, 255, 0.9), rgba(255, 255, 255, 0.49), rgba(255, 255, 255, 0.29), rgba(199, 236, 255, 0.48), rgba(138, 216, 255, 0.41), rgba(255, 255, 255, 0.37), rgba(255, 255, 255, 0.6), rgba(255, 255, 255, 0.09), rgba(5, 172, 255, 0.95), rgba(255, 255, 255, 0.49));
  //   -webkit-animation-duration: 3.6s;
  //           animation-duration: 3.6s;
  //   animation-direction: reverse;
  // }
  
  @-webkit-keyframes rot {
    to {
      transform: rotate(1.1turn);
    }
  }
  
  @keyframes rot {
    to {
      transform: rotate(1.3turn);
    }
  }
  body{
      position: relative;
  }
  .rays-wrapper{
      position: absolute;
      width: 100%;
      height: 100%;
      max-width: 100%;
      max-height: 100%;
      overflow: hidden;
  }


  //stars
  .stars{
    position: absolute;
    width: 100%;
    height: 100%;
    top: 0px;
    left: 0px;
    background: url('url:../img/stars.png?as=webp');
    background-repeat: repeat;
    filter: hue-rotate(90deg);
    &.left{
      background-position: left bottom;
      animation-delay: 0.7s;
    }
    &.rotaded{
      transform: rotate(180deg);
      transform-origin: center;
      animation-delay: 1.4s;
    }
    -webkit-animation: twinkle 2s linear 0s infinite;
          animation: twinkle 2s linear 0s infinite;
          @keyframes twinkle {
            0%, 100% {
              opacity: 0;
            }
            50% {
              opacity: 1;
            }
          }
  }
  .asteroids{
    position: absolute;
    top: 0px;
    left: 0px;
    right: 0px;
    bottom: 0px;
    z-index: -1;
    .asteroid{
      top: 9vh;
      left: 9vw;
      height: 2px;
      position: absolute;
      width: 0.5px;
      background: rgba(255, 255, 255, 0.4);
      border-radius: 50%;
      box-shadow: -15px 0 5px rgba(255, 0, 255, 0.8), 0 0 3px rgba(255, 0, 255, 0.8);
      z-index: 6;
      transform-origin: right;
      transform: rotate(45deg);
      -webkit-animation: shoot-to-right 20s linear 5s infinite;
      animation: shoot-to-right 20s linear 5s infinite;
      &:nth-child(1){
        top: 30vh;
        left: 20vw;
        animation-delay: 0s;
      }
      &:nth-child(2){
        top: 20vh;
        left: 50vw;
        animation-delay: 4s;
      }
      &:nth-child(3){
        top: 70vh;
        left: 20vw;
        animation-delay: 8s;
      }
      &:nth-child(4){
        top: 30vh;
        right: 20vw;
        left: unset;
        transform: rotate(135deg);
        -webkit-animation: shoot-to-left 20s linear 5s infinite;
        animation: shoot-to-left 20s linear 5s infinite;
      }
      &:nth-child(5){
        top: 0;
        right: 0;
        left: unset;
        transform: rotate(135deg);
        -webkit-animation: shoot-to-left 20s linear 10s infinite;
        animation: shoot-to-left 20s linear 10s infinite;
      }
      &:nth-child(6){
        top: 70vh;
        right: 20vw;
        left: unset;
        transform: rotate(135deg);
        -webkit-animation: shoot-to-left 20s linear 15s infinite;
        animation: shoot-to-left 20s linear 15s infinite;
      }
      &:nth-child(7){
        top: 20vh;
        left: 10vw;
        animation-delay: 17s;
      }
      &:nth-child(8){
        top: 0;
        left: 0;
        animation-delay: 14s;
      }
      &:nth-child(9){
        top: 70vh;
        left: 20vw;
        animation-delay: 18s;
      }
      
    }
  }
  @keyframes shoot-to-right {
    0% {
      opacity: 1;
      transform: rotate(45deg) translate3d(0, 0, 0);
      width: 25px;
    }
    2.5% {
      opacity: 1;
      transform: rotate(45deg) translate3d(200px, 0, 0);
      width: 25px;
    }
    5%, 100% {
      opacity: 0;
      transform: rotate(45deg) translate3d(400px, 0px, 0);
      width: 0px;
    }
  }
  @keyframes shoot-to-left {
    0% {
      opacity: 1;
      transform: rotate(135deg) translate3d(0, 0, 0);
      width: 25px;
    }
    2.5% {
      opacity: 1;
      transform: rotate(135deg) translate3d(200px, 0, 0);
      width: 25px;
    }
    5%, 100% {
      opacity: 0;
      transform: rotate(135deg) translate3d(400px, 0px, 0);
      width: 0px;
    }
  }
  .myrays-wrapper{
    position: absolute;
    width: 100%;
    height: 100vh;
    left: 0;
    top: 0;
    overflow: hidden;
    // background: linear-gradient(to bottom, rgba(35, 50, 190, 0.5), transparent);
    .rays{
      width: 120%;
      height: 120%;
      background-position: top center;
      background-size: contain;
      background-repeat: no-repeat;
      position: absolute;
      left: -10%;
      top: 0;
      
      animation: lighting 4s linear 0s infinite;
      transform-origin: center top;
      opacity: 0;
      @media (max-width: 576px) {
        background-size: cover;
        width: calc(100% + 20px);
        left: -10px;
        opacity: .6;
      }
      &:nth-child(1){
        background-image: url('url:../img/rays-1.png?as=webp');
      }
      &:nth-child(2){
        background-image: url('url:../img/rays-2.png?as=webp');
        animation-delay: 2s;
      }
      // &:nth-child(3){
      //   background-image: url('url:../img/rays-3.png?as=webp');
      //   animation-delay: 4s;
      // }
      // &:nth-child(4){
      //   background-image: url('url:../img/rays-4.png?as=webp');
      //   animation-delay: 6s;
      // }
      // &:nth-child(5){
      //   background-image: url('url:../img/rays-5.png?as=webp');
      //   animation-delay: 12s;
      // }
      // &:nth-child(6){
      //   background-image: url('url:../img/rays-6.png?as=webp');
      //   animation-delay: 18s;
      // }
    }
  }
  @keyframes lighting {
    0%, 100% {
      opacity: 0.4;
      transform: translateX(0px);
    }
    25%{
      transform: translateX(10px);
    }
    50% {
      opacity: 0.8;
      transform: translateX(0px);
    }
    75%{
      transform: translateX(-10px);
    }
  }
  .floating-animate{
    animation: floating 6s ease-in-out infinite;
  }
  @keyframes floating {
    0% {
      // box-shadow: 0 5px 15px 0px rgba(0,0,0,0.6);
      transform: translatey(0px);
    }
    50% {
      // box-shadow: 0 25px 15px 0px rgba(0,0,0,0.2);
      transform: translatey(-20px);
    }
    100% {
      // box-shadow: 0 5px 15px 0px rgba(0,0,0,0.6);
      transform: translatey(0px);
    }
  }
  @keyframes swimming {
    0%, 100% {
      
      transform: rotate(0deg) ;
    }
    25%{
      transform: rotate(10deg);
    }
    50% {
      
      transform: rotate(0deg);
    }
    75%{
      transform: rotate(-10deg);
    }
  }
  @keyframes foating_cat{
    0%{
        transform: translateX(-5%) translateY(-5%);
    }
    50% {
      // box-shadow: 0 25px 15px 0px rgba(0,0,0,0.2);
      transform: translatey(-20px);
    }
    100% {
      // box-shadow: 0 5px 15px 0px rgba(0,0,0,0.6);
      transform: translatey(0px);
    }
    
}

@keyframes top-spans {
  0%, 100%{
    transform: translateX(0) translateY(0);
  }
  10%{
    transform: translateX(-50%) translateY(-30%);
  }
  50%{
    transform: translateX(100%) translateY(-30%);
  }
  70%{
    transform: translateX(10%) translateY(-30%);
  }
  90%{
    transform: translateX(50%) translateY(50%);
  }
}
@keyframes bottom-spans-left {
  0%, 100%{
    transform: translateX(0) translateY(0);
  }
  10%{
    transform: translateX(50%) translateY(50%);
  }
  50%{
    transform: translateX(100%) translateY(50%);
  }
  70%{
    transform: translateX(100%) translateY(50%);
  }
  90%{
    transform: translateX(50%) translateY(-50%);
  }
}
@keyframes bottom-spans-right {
  0%, 100%{
    transform: translateX(0) translateY(0);
  }
  10%{
    transform: translateX(-50%) translateY(30%);
  }
  50%{
    transform: translateX(-100%) translateY(30%);
  }
  75%{
    transform: translateX(-10%) translateY(30%);
  }
  
}