@font-face {
  font-family: RotondaC;
  src: url("fonts/RotondaC-Bold.a8c185d0.eot");
  src: local(RotondaC), url("fonts/RotondaC-Bold.a8c185d0.eot#iefix") format("embedded-opentype"), url("fonts/RotondaC-Bold.fd75bb94.woff") format("woff"), url("fonts/RotondaC-Bold.40f0fe78.ttf") format("truetype");
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: CCKillJoy;
  src: url("fonts/KillJoy.a27e127f.ttf") format("truetype");
}

*, :before, :after {
  box-sizing: border-box;
}

html {
  -webkit-text-size-adjust: 100%;
  -webkit-tap-highlight-color: #0000;
  font-family: sans-serif;
  line-height: 1.15;
}

article, aside, figcaption, figure, footer, header, hgroup, main, nav, section {
  display: block;
}

body {
  color: #fff;
  text-align: left;
  background-color: #fff;
  margin: 0;
  font-family: RotondaC;
  font-size: 1rem;
  font-weight: 400;
  line-height: 1.5;
}

[tabindex="-1"]:focus:not(:focus-visible) {
  outline: 0 !important;
}

hr {
  box-sizing: content-box;
  height: 0;
  overflow: visible;
}

h1, h2, h3, h4, h5, h6 {
  margin-top: 0;
  margin-bottom: .5rem;
}

p {
  margin-top: 0;
  margin-bottom: 1rem;
}

abbr[title], abbr[data-original-title] {
  cursor: help;
  text-decoration-skip-ink: none;
  border-bottom: 0;
  -webkit-text-decoration: underline dotted;
  text-decoration: underline dotted;
}

address {
  font-style: normal;
  line-height: inherit;
  margin-bottom: 1rem;
}

ol, ul, dl {
  margin-top: 0;
  margin-bottom: 1rem;
}

ol ol, ul ul, ol ul, ul ol {
  margin-bottom: 0;
}

dt {
  font-weight: 700;
}

dd {
  margin-bottom: .5rem;
  margin-left: 0;
}

blockquote {
  margin: 0 0 1rem;
}

b, strong {
  font-weight: bolder;
}

small {
  font-size: 80%;
}

sub, sup {
  vertical-align: baseline;
  font-size: 75%;
  line-height: 0;
  position: relative;
}

sub {
  bottom: -.25em;
}

sup {
  top: -.5em;
}

a {
  color: #007bff;
  background-color: #0000;
  text-decoration: none;
}

a:hover {
  color: #0056b3;
  text-decoration: underline;
}

a:not([href]):not([class]), a:not([href]):not([class]):hover {
  color: inherit;
  text-decoration: none;
}

pre, code, kbd, samp {
  font-family: SFMono-Regular, Menlo, Monaco, Consolas, Liberation Mono, Courier New, monospace;
  font-size: 1em;
}

pre {
  -ms-overflow-style: scrollbar;
  margin-top: 0;
  margin-bottom: 1rem;
  overflow: auto;
}

figure {
  margin: 0 0 1rem;
}

img {
  vertical-align: middle;
  border-style: none;
}

svg {
  vertical-align: middle;
  overflow: hidden;
}

table {
  border-collapse: collapse;
}

caption {
  color: #6c757d;
  text-align: left;
  caption-side: bottom;
  padding-top: .75rem;
  padding-bottom: .75rem;
}

th {
  text-align: inherit;
  text-align: -webkit-match-parent;
}

label {
  margin-bottom: .5rem;
  display: inline-block;
}

button {
  border-radius: 0;
}

button:focus:not(:focus-visible) {
  outline: 0;
}

input, button, select, optgroup, textarea {
  font-family: inherit;
  font-size: inherit;
  line-height: inherit;
  margin: 0;
}

button, input {
  overflow: visible;
}

button, select {
  text-transform: none;
}

[role="button"] {
  cursor: pointer;
}

select {
  word-wrap: normal;
}

button, [type="button"], [type="reset"], [type="submit"] {
  -webkit-appearance: button;
}

button:not(:disabled), [type="button"]:not(:disabled), [type="reset"]:not(:disabled), [type="submit"]:not(:disabled) {
  cursor: pointer;
}

button::-moz-focus-inner, [type="button"]::-moz-focus-inner, [type="reset"]::-moz-focus-inner, [type="submit"]::-moz-focus-inner {
  border-style: none;
  padding: 0;
}

input[type="radio"], input[type="checkbox"] {
  box-sizing: border-box;
  padding: 0;
}

textarea {
  resize: vertical;
  overflow: auto;
}

fieldset {
  min-width: 0;
  border: 0;
  margin: 0;
  padding: 0;
}

legend {
  width: 100%;
  max-width: 100%;
  font-size: 1.5rem;
  line-height: inherit;
  color: inherit;
  white-space: normal;
  margin-bottom: .5rem;
  padding: 0;
  display: block;
}

progress {
  vertical-align: baseline;
}

[type="number"]::-webkit-inner-spin-button, [type="number"]::-webkit-outer-spin-button {
  height: auto;
}

[type="search"] {
  outline-offset: -2px;
  -webkit-appearance: none;
}

[type="search"]::-webkit-search-decoration {
  -webkit-appearance: none;
}

::-webkit-file-upload-button {
  font: inherit;
  -webkit-appearance: button;
}

output {
  display: inline-block;
}

summary {
  cursor: pointer;
  display: list-item;
}

template {
  display: none;
}

[hidden] {
  display: none !important;
}

.container, .container-fluid, .container-xl, .container-lg, .container-md, .container-sm {
  width: 100%;
  margin-left: auto;
  margin-right: auto;
  padding-left: 15px;
  padding-right: 15px;
}

@media (min-width: 576px) {
  .container-sm, .container {
    max-width: 540px;
  }
}

@media (min-width: 768px) {
  .container-md, .container-sm, .container {
    max-width: 720px;
  }
}

@media (min-width: 992px) {
  .container-lg, .container-md, .container-sm, .container {
    max-width: 960px;
  }
}

@media (min-width: 1200px) {
  .container-xl, .container-lg, .container-md, .container-sm, .container {
    max-width: 1140px;
  }
}

.row {
  flex-wrap: wrap;
  margin-left: -15px;
  margin-right: -15px;
  display: flex;
}

.no-gutters {
  margin-left: 0;
  margin-right: 0;
}

.no-gutters > .col, .no-gutters > [class*="col-"] {
  padding-left: 0;
  padding-right: 0;
}

.col-xl, .col-xl-auto, .col-xl-12, .col-xl-11, .col-xl-10, .col-xl-9, .col-xl-8, .col-xl-7, .col-xl-6, .col-xl-5, .col-xl-4, .col-xl-3, .col-xl-2, .col-xl-1, .col-lg, .col-lg-auto, .col-lg-12, .col-lg-11, .col-lg-10, .col-lg-9, .col-lg-8, .col-lg-7, .col-lg-6, .col-lg-5, .col-lg-4, .col-lg-3, .col-lg-2, .col-lg-1, .col-md, .col-md-auto, .col-md-12, .col-md-11, .col-md-10, .col-md-9, .col-md-8, .col-md-7, .col-md-6, .col-md-5, .col-md-4, .col-md-3, .col-md-2, .col-md-1, .col-sm, .col-sm-auto, .col-sm-12, .col-sm-11, .col-sm-10, .col-sm-9, .col-sm-8, .col-sm-7, .col-sm-6, .col-sm-5, .col-sm-4, .col-sm-3, .col-sm-2, .col-sm-1, .col, .col-auto, .col-12, .col-11, .col-10, .col-9, .col-8, .col-7, .col-6, .col-5, .col-4, .col-3, .col-2, .col-1 {
  width: 100%;
  padding-left: 15px;
  padding-right: 15px;
  position: relative;
}

.col {
  max-width: 100%;
  flex-grow: 1;
  flex-basis: 0;
}

.row-cols-1 > * {
  max-width: 100%;
  flex: 0 0 100%;
}

.row-cols-2 > * {
  max-width: 50%;
  flex: 0 0 50%;
}

.row-cols-3 > * {
  max-width: 33.3333%;
  flex: 0 0 33.3333%;
}

.row-cols-4 > * {
  max-width: 25%;
  flex: 0 0 25%;
}

.row-cols-5 > * {
  max-width: 20%;
  flex: 0 0 20%;
}

.row-cols-6 > * {
  max-width: 16.6667%;
  flex: 0 0 16.6667%;
}

.col-auto {
  width: auto;
  max-width: 100%;
  flex: none;
}

.col-1 {
  max-width: 8.33333%;
  flex: 0 0 8.33333%;
}

.col-2 {
  max-width: 16.6667%;
  flex: 0 0 16.6667%;
}

.col-3 {
  max-width: 25%;
  flex: 0 0 25%;
}

.col-4 {
  max-width: 33.3333%;
  flex: 0 0 33.3333%;
}

.col-5 {
  max-width: 41.6667%;
  flex: 0 0 41.6667%;
}

.col-6 {
  max-width: 50%;
  flex: 0 0 50%;
}

.col-7 {
  max-width: 58.3333%;
  flex: 0 0 58.3333%;
}

.col-8 {
  max-width: 66.6667%;
  flex: 0 0 66.6667%;
}

.col-9 {
  max-width: 75%;
  flex: 0 0 75%;
}

.col-10 {
  max-width: 83.3333%;
  flex: 0 0 83.3333%;
}

.col-11 {
  max-width: 91.6667%;
  flex: 0 0 91.6667%;
}

.col-12 {
  max-width: 100%;
  flex: 0 0 100%;
}

.order-first {
  order: -1;
}

.order-last {
  order: 13;
}

.order-0 {
  order: 0;
}

.order-1 {
  order: 1;
}

.order-2 {
  order: 2;
}

.order-3 {
  order: 3;
}

.order-4 {
  order: 4;
}

.order-5 {
  order: 5;
}

.order-6 {
  order: 6;
}

.order-7 {
  order: 7;
}

.order-8 {
  order: 8;
}

.order-9 {
  order: 9;
}

.order-10 {
  order: 10;
}

.order-11 {
  order: 11;
}

.order-12 {
  order: 12;
}

.offset-1 {
  margin-left: 8.33333%;
}

.offset-2 {
  margin-left: 16.6667%;
}

.offset-3 {
  margin-left: 25%;
}

.offset-4 {
  margin-left: 33.3333%;
}

.offset-5 {
  margin-left: 41.6667%;
}

.offset-6 {
  margin-left: 50%;
}

.offset-7 {
  margin-left: 58.3333%;
}

.offset-8 {
  margin-left: 66.6667%;
}

.offset-9 {
  margin-left: 75%;
}

.offset-10 {
  margin-left: 83.3333%;
}

.offset-11 {
  margin-left: 91.6667%;
}

@media (min-width: 576px) {
  .col-sm {
    max-width: 100%;
    flex-grow: 1;
    flex-basis: 0;
  }

  .row-cols-sm-1 > * {
    max-width: 100%;
    flex: 0 0 100%;
  }

  .row-cols-sm-2 > * {
    max-width: 50%;
    flex: 0 0 50%;
  }

  .row-cols-sm-3 > * {
    max-width: 33.3333%;
    flex: 0 0 33.3333%;
  }

  .row-cols-sm-4 > * {
    max-width: 25%;
    flex: 0 0 25%;
  }

  .row-cols-sm-5 > * {
    max-width: 20%;
    flex: 0 0 20%;
  }

  .row-cols-sm-6 > * {
    max-width: 16.6667%;
    flex: 0 0 16.6667%;
  }

  .col-sm-auto {
    width: auto;
    max-width: 100%;
    flex: none;
  }

  .col-sm-1 {
    max-width: 8.33333%;
    flex: 0 0 8.33333%;
  }

  .col-sm-2 {
    max-width: 16.6667%;
    flex: 0 0 16.6667%;
  }

  .col-sm-3 {
    max-width: 25%;
    flex: 0 0 25%;
  }

  .col-sm-4 {
    max-width: 33.3333%;
    flex: 0 0 33.3333%;
  }

  .col-sm-5 {
    max-width: 41.6667%;
    flex: 0 0 41.6667%;
  }

  .col-sm-6 {
    max-width: 50%;
    flex: 0 0 50%;
  }

  .col-sm-7 {
    max-width: 58.3333%;
    flex: 0 0 58.3333%;
  }

  .col-sm-8 {
    max-width: 66.6667%;
    flex: 0 0 66.6667%;
  }

  .col-sm-9 {
    max-width: 75%;
    flex: 0 0 75%;
  }

  .col-sm-10 {
    max-width: 83.3333%;
    flex: 0 0 83.3333%;
  }

  .col-sm-11 {
    max-width: 91.6667%;
    flex: 0 0 91.6667%;
  }

  .col-sm-12 {
    max-width: 100%;
    flex: 0 0 100%;
  }

  .order-sm-first {
    order: -1;
  }

  .order-sm-last {
    order: 13;
  }

  .order-sm-0 {
    order: 0;
  }

  .order-sm-1 {
    order: 1;
  }

  .order-sm-2 {
    order: 2;
  }

  .order-sm-3 {
    order: 3;
  }

  .order-sm-4 {
    order: 4;
  }

  .order-sm-5 {
    order: 5;
  }

  .order-sm-6 {
    order: 6;
  }

  .order-sm-7 {
    order: 7;
  }

  .order-sm-8 {
    order: 8;
  }

  .order-sm-9 {
    order: 9;
  }

  .order-sm-10 {
    order: 10;
  }

  .order-sm-11 {
    order: 11;
  }

  .order-sm-12 {
    order: 12;
  }

  .offset-sm-0 {
    margin-left: 0;
  }

  .offset-sm-1 {
    margin-left: 8.33333%;
  }

  .offset-sm-2 {
    margin-left: 16.6667%;
  }

  .offset-sm-3 {
    margin-left: 25%;
  }

  .offset-sm-4 {
    margin-left: 33.3333%;
  }

  .offset-sm-5 {
    margin-left: 41.6667%;
  }

  .offset-sm-6 {
    margin-left: 50%;
  }

  .offset-sm-7 {
    margin-left: 58.3333%;
  }

  .offset-sm-8 {
    margin-left: 66.6667%;
  }

  .offset-sm-9 {
    margin-left: 75%;
  }

  .offset-sm-10 {
    margin-left: 83.3333%;
  }

  .offset-sm-11 {
    margin-left: 91.6667%;
  }
}

@media (min-width: 768px) {
  .col-md {
    max-width: 100%;
    flex-grow: 1;
    flex-basis: 0;
  }

  .row-cols-md-1 > * {
    max-width: 100%;
    flex: 0 0 100%;
  }

  .row-cols-md-2 > * {
    max-width: 50%;
    flex: 0 0 50%;
  }

  .row-cols-md-3 > * {
    max-width: 33.3333%;
    flex: 0 0 33.3333%;
  }

  .row-cols-md-4 > * {
    max-width: 25%;
    flex: 0 0 25%;
  }

  .row-cols-md-5 > * {
    max-width: 20%;
    flex: 0 0 20%;
  }

  .row-cols-md-6 > * {
    max-width: 16.6667%;
    flex: 0 0 16.6667%;
  }

  .col-md-auto {
    width: auto;
    max-width: 100%;
    flex: none;
  }

  .col-md-1 {
    max-width: 8.33333%;
    flex: 0 0 8.33333%;
  }

  .col-md-2 {
    max-width: 16.6667%;
    flex: 0 0 16.6667%;
  }

  .col-md-3 {
    max-width: 25%;
    flex: 0 0 25%;
  }

  .col-md-4 {
    max-width: 33.3333%;
    flex: 0 0 33.3333%;
  }

  .col-md-5 {
    max-width: 41.6667%;
    flex: 0 0 41.6667%;
  }

  .col-md-6 {
    max-width: 50%;
    flex: 0 0 50%;
  }

  .col-md-7 {
    max-width: 58.3333%;
    flex: 0 0 58.3333%;
  }

  .col-md-8 {
    max-width: 66.6667%;
    flex: 0 0 66.6667%;
  }

  .col-md-9 {
    max-width: 75%;
    flex: 0 0 75%;
  }

  .col-md-10 {
    max-width: 83.3333%;
    flex: 0 0 83.3333%;
  }

  .col-md-11 {
    max-width: 91.6667%;
    flex: 0 0 91.6667%;
  }

  .col-md-12 {
    max-width: 100%;
    flex: 0 0 100%;
  }

  .order-md-first {
    order: -1;
  }

  .order-md-last {
    order: 13;
  }

  .order-md-0 {
    order: 0;
  }

  .order-md-1 {
    order: 1;
  }

  .order-md-2 {
    order: 2;
  }

  .order-md-3 {
    order: 3;
  }

  .order-md-4 {
    order: 4;
  }

  .order-md-5 {
    order: 5;
  }

  .order-md-6 {
    order: 6;
  }

  .order-md-7 {
    order: 7;
  }

  .order-md-8 {
    order: 8;
  }

  .order-md-9 {
    order: 9;
  }

  .order-md-10 {
    order: 10;
  }

  .order-md-11 {
    order: 11;
  }

  .order-md-12 {
    order: 12;
  }

  .offset-md-0 {
    margin-left: 0;
  }

  .offset-md-1 {
    margin-left: 8.33333%;
  }

  .offset-md-2 {
    margin-left: 16.6667%;
  }

  .offset-md-3 {
    margin-left: 25%;
  }

  .offset-md-4 {
    margin-left: 33.3333%;
  }

  .offset-md-5 {
    margin-left: 41.6667%;
  }

  .offset-md-6 {
    margin-left: 50%;
  }

  .offset-md-7 {
    margin-left: 58.3333%;
  }

  .offset-md-8 {
    margin-left: 66.6667%;
  }

  .offset-md-9 {
    margin-left: 75%;
  }

  .offset-md-10 {
    margin-left: 83.3333%;
  }

  .offset-md-11 {
    margin-left: 91.6667%;
  }
}

@media (min-width: 992px) {
  .col-lg {
    max-width: 100%;
    flex-grow: 1;
    flex-basis: 0;
  }

  .row-cols-lg-1 > * {
    max-width: 100%;
    flex: 0 0 100%;
  }

  .row-cols-lg-2 > * {
    max-width: 50%;
    flex: 0 0 50%;
  }

  .row-cols-lg-3 > * {
    max-width: 33.3333%;
    flex: 0 0 33.3333%;
  }

  .row-cols-lg-4 > * {
    max-width: 25%;
    flex: 0 0 25%;
  }

  .row-cols-lg-5 > * {
    max-width: 20%;
    flex: 0 0 20%;
  }

  .row-cols-lg-6 > * {
    max-width: 16.6667%;
    flex: 0 0 16.6667%;
  }

  .col-lg-auto {
    width: auto;
    max-width: 100%;
    flex: none;
  }

  .col-lg-1 {
    max-width: 8.33333%;
    flex: 0 0 8.33333%;
  }

  .col-lg-2 {
    max-width: 16.6667%;
    flex: 0 0 16.6667%;
  }

  .col-lg-3 {
    max-width: 25%;
    flex: 0 0 25%;
  }

  .col-lg-4 {
    max-width: 33.3333%;
    flex: 0 0 33.3333%;
  }

  .col-lg-5 {
    max-width: 41.6667%;
    flex: 0 0 41.6667%;
  }

  .col-lg-6 {
    max-width: 50%;
    flex: 0 0 50%;
  }

  .col-lg-7 {
    max-width: 58.3333%;
    flex: 0 0 58.3333%;
  }

  .col-lg-8 {
    max-width: 66.6667%;
    flex: 0 0 66.6667%;
  }

  .col-lg-9 {
    max-width: 75%;
    flex: 0 0 75%;
  }

  .col-lg-10 {
    max-width: 83.3333%;
    flex: 0 0 83.3333%;
  }

  .col-lg-11 {
    max-width: 91.6667%;
    flex: 0 0 91.6667%;
  }

  .col-lg-12 {
    max-width: 100%;
    flex: 0 0 100%;
  }

  .order-lg-first {
    order: -1;
  }

  .order-lg-last {
    order: 13;
  }

  .order-lg-0 {
    order: 0;
  }

  .order-lg-1 {
    order: 1;
  }

  .order-lg-2 {
    order: 2;
  }

  .order-lg-3 {
    order: 3;
  }

  .order-lg-4 {
    order: 4;
  }

  .order-lg-5 {
    order: 5;
  }

  .order-lg-6 {
    order: 6;
  }

  .order-lg-7 {
    order: 7;
  }

  .order-lg-8 {
    order: 8;
  }

  .order-lg-9 {
    order: 9;
  }

  .order-lg-10 {
    order: 10;
  }

  .order-lg-11 {
    order: 11;
  }

  .order-lg-12 {
    order: 12;
  }

  .offset-lg-0 {
    margin-left: 0;
  }

  .offset-lg-1 {
    margin-left: 8.33333%;
  }

  .offset-lg-2 {
    margin-left: 16.6667%;
  }

  .offset-lg-3 {
    margin-left: 25%;
  }

  .offset-lg-4 {
    margin-left: 33.3333%;
  }

  .offset-lg-5 {
    margin-left: 41.6667%;
  }

  .offset-lg-6 {
    margin-left: 50%;
  }

  .offset-lg-7 {
    margin-left: 58.3333%;
  }

  .offset-lg-8 {
    margin-left: 66.6667%;
  }

  .offset-lg-9 {
    margin-left: 75%;
  }

  .offset-lg-10 {
    margin-left: 83.3333%;
  }

  .offset-lg-11 {
    margin-left: 91.6667%;
  }
}

@media (min-width: 1200px) {
  .col-xl {
    max-width: 100%;
    flex-grow: 1;
    flex-basis: 0;
  }

  .row-cols-xl-1 > * {
    max-width: 100%;
    flex: 0 0 100%;
  }

  .row-cols-xl-2 > * {
    max-width: 50%;
    flex: 0 0 50%;
  }

  .row-cols-xl-3 > * {
    max-width: 33.3333%;
    flex: 0 0 33.3333%;
  }

  .row-cols-xl-4 > * {
    max-width: 25%;
    flex: 0 0 25%;
  }

  .row-cols-xl-5 > * {
    max-width: 20%;
    flex: 0 0 20%;
  }

  .row-cols-xl-6 > * {
    max-width: 16.6667%;
    flex: 0 0 16.6667%;
  }

  .col-xl-auto {
    width: auto;
    max-width: 100%;
    flex: none;
  }

  .col-xl-1 {
    max-width: 8.33333%;
    flex: 0 0 8.33333%;
  }

  .col-xl-2 {
    max-width: 16.6667%;
    flex: 0 0 16.6667%;
  }

  .col-xl-3 {
    max-width: 25%;
    flex: 0 0 25%;
  }

  .col-xl-4 {
    max-width: 33.3333%;
    flex: 0 0 33.3333%;
  }

  .col-xl-5 {
    max-width: 41.6667%;
    flex: 0 0 41.6667%;
  }

  .col-xl-6 {
    max-width: 50%;
    flex: 0 0 50%;
  }

  .col-xl-7 {
    max-width: 58.3333%;
    flex: 0 0 58.3333%;
  }

  .col-xl-8 {
    max-width: 66.6667%;
    flex: 0 0 66.6667%;
  }

  .col-xl-9 {
    max-width: 75%;
    flex: 0 0 75%;
  }

  .col-xl-10 {
    max-width: 83.3333%;
    flex: 0 0 83.3333%;
  }

  .col-xl-11 {
    max-width: 91.6667%;
    flex: 0 0 91.6667%;
  }

  .col-xl-12 {
    max-width: 100%;
    flex: 0 0 100%;
  }

  .order-xl-first {
    order: -1;
  }

  .order-xl-last {
    order: 13;
  }

  .order-xl-0 {
    order: 0;
  }

  .order-xl-1 {
    order: 1;
  }

  .order-xl-2 {
    order: 2;
  }

  .order-xl-3 {
    order: 3;
  }

  .order-xl-4 {
    order: 4;
  }

  .order-xl-5 {
    order: 5;
  }

  .order-xl-6 {
    order: 6;
  }

  .order-xl-7 {
    order: 7;
  }

  .order-xl-8 {
    order: 8;
  }

  .order-xl-9 {
    order: 9;
  }

  .order-xl-10 {
    order: 10;
  }

  .order-xl-11 {
    order: 11;
  }

  .order-xl-12 {
    order: 12;
  }

  .offset-xl-0 {
    margin-left: 0;
  }

  .offset-xl-1 {
    margin-left: 8.33333%;
  }

  .offset-xl-2 {
    margin-left: 16.6667%;
  }

  .offset-xl-3 {
    margin-left: 25%;
  }

  .offset-xl-4 {
    margin-left: 33.3333%;
  }

  .offset-xl-5 {
    margin-left: 41.6667%;
  }

  .offset-xl-6 {
    margin-left: 50%;
  }

  .offset-xl-7 {
    margin-left: 58.3333%;
  }

  .offset-xl-8 {
    margin-left: 66.6667%;
  }

  .offset-xl-9 {
    margin-left: 75%;
  }

  .offset-xl-10 {
    margin-left: 83.3333%;
  }

  .offset-xl-11 {
    margin-left: 91.6667%;
  }
}

.d-none {
  display: none !important;
}

.d-inline {
  display: inline !important;
}

.d-inline-block {
  display: inline-block !important;
}

.d-block {
  display: block !important;
}

.d-table {
  display: table !important;
}

.d-table-row {
  display: table-row !important;
}

.d-table-cell {
  display: table-cell !important;
}

.d-flex {
  display: flex !important;
}

.d-inline-flex {
  display: inline-flex !important;
}

@media (min-width: 576px) {
  .d-sm-none {
    display: none !important;
  }

  .d-sm-inline {
    display: inline !important;
  }

  .d-sm-inline-block {
    display: inline-block !important;
  }

  .d-sm-block {
    display: block !important;
  }

  .d-sm-table {
    display: table !important;
  }

  .d-sm-table-row {
    display: table-row !important;
  }

  .d-sm-table-cell {
    display: table-cell !important;
  }

  .d-sm-flex {
    display: flex !important;
  }

  .d-sm-inline-flex {
    display: inline-flex !important;
  }
}

@media (min-width: 768px) {
  .d-md-none {
    display: none !important;
  }

  .d-md-inline {
    display: inline !important;
  }

  .d-md-inline-block {
    display: inline-block !important;
  }

  .d-md-block {
    display: block !important;
  }

  .d-md-table {
    display: table !important;
  }

  .d-md-table-row {
    display: table-row !important;
  }

  .d-md-table-cell {
    display: table-cell !important;
  }

  .d-md-flex {
    display: flex !important;
  }

  .d-md-inline-flex {
    display: inline-flex !important;
  }
}

@media (min-width: 992px) {
  .d-lg-none {
    display: none !important;
  }

  .d-lg-inline {
    display: inline !important;
  }

  .d-lg-inline-block {
    display: inline-block !important;
  }

  .d-lg-block {
    display: block !important;
  }

  .d-lg-table {
    display: table !important;
  }

  .d-lg-table-row {
    display: table-row !important;
  }

  .d-lg-table-cell {
    display: table-cell !important;
  }

  .d-lg-flex {
    display: flex !important;
  }

  .d-lg-inline-flex {
    display: inline-flex !important;
  }
}

@media (min-width: 1200px) {
  .d-xl-none {
    display: none !important;
  }

  .d-xl-inline {
    display: inline !important;
  }

  .d-xl-inline-block {
    display: inline-block !important;
  }

  .d-xl-block {
    display: block !important;
  }

  .d-xl-table {
    display: table !important;
  }

  .d-xl-table-row {
    display: table-row !important;
  }

  .d-xl-table-cell {
    display: table-cell !important;
  }

  .d-xl-flex {
    display: flex !important;
  }

  .d-xl-inline-flex {
    display: inline-flex !important;
  }
}

@media print {
  .d-print-none {
    display: none !important;
  }

  .d-print-inline {
    display: inline !important;
  }

  .d-print-inline-block {
    display: inline-block !important;
  }

  .d-print-block {
    display: block !important;
  }

  .d-print-table {
    display: table !important;
  }

  .d-print-table-row {
    display: table-row !important;
  }

  .d-print-table-cell {
    display: table-cell !important;
  }

  .d-print-flex {
    display: flex !important;
  }

  .d-print-inline-flex {
    display: inline-flex !important;
  }
}

.flex-row {
  flex-direction: row !important;
}

.flex-column {
  flex-direction: column !important;
}

.flex-row-reverse {
  flex-direction: row-reverse !important;
}

.flex-column-reverse {
  flex-direction: column-reverse !important;
}

.flex-wrap {
  flex-wrap: wrap !important;
}

.flex-nowrap {
  flex-wrap: nowrap !important;
}

.flex-wrap-reverse {
  flex-wrap: wrap-reverse !important;
}

.flex-fill {
  flex: auto !important;
}

.flex-grow-0 {
  flex-grow: 0 !important;
}

.flex-grow-1 {
  flex-grow: 1 !important;
}

.flex-shrink-0 {
  flex-shrink: 0 !important;
}

.flex-shrink-1 {
  flex-shrink: 1 !important;
}

.justify-content-start {
  justify-content: flex-start !important;
}

.justify-content-end {
  justify-content: flex-end !important;
}

.justify-content-center {
  justify-content: center !important;
}

.justify-content-between {
  justify-content: space-between !important;
}

.justify-content-around {
  justify-content: space-around !important;
}

.align-items-start {
  align-items: flex-start !important;
}

.align-items-end {
  align-items: flex-end !important;
}

.align-items-center {
  align-items: center !important;
}

.align-items-baseline {
  align-items: baseline !important;
}

.align-items-stretch {
  align-items: stretch !important;
}

.align-content-start {
  align-content: flex-start !important;
}

.align-content-end {
  align-content: flex-end !important;
}

.align-content-center {
  align-content: center !important;
}

.align-content-between {
  align-content: space-between !important;
}

.align-content-around {
  align-content: space-around !important;
}

.align-content-stretch {
  align-content: stretch !important;
}

.align-self-auto {
  align-self: auto !important;
}

.align-self-start {
  align-self: flex-start !important;
}

.align-self-end {
  align-self: flex-end !important;
}

.align-self-center {
  align-self: center !important;
}

.align-self-baseline {
  align-self: baseline !important;
}

.align-self-stretch {
  align-self: stretch !important;
}

@media (min-width: 576px) {
  .flex-sm-row {
    flex-direction: row !important;
  }

  .flex-sm-column {
    flex-direction: column !important;
  }

  .flex-sm-row-reverse {
    flex-direction: row-reverse !important;
  }

  .flex-sm-column-reverse {
    flex-direction: column-reverse !important;
  }

  .flex-sm-wrap {
    flex-wrap: wrap !important;
  }

  .flex-sm-nowrap {
    flex-wrap: nowrap !important;
  }

  .flex-sm-wrap-reverse {
    flex-wrap: wrap-reverse !important;
  }

  .flex-sm-fill {
    flex: auto !important;
  }

  .flex-sm-grow-0 {
    flex-grow: 0 !important;
  }

  .flex-sm-grow-1 {
    flex-grow: 1 !important;
  }

  .flex-sm-shrink-0 {
    flex-shrink: 0 !important;
  }

  .flex-sm-shrink-1 {
    flex-shrink: 1 !important;
  }

  .justify-content-sm-start {
    justify-content: flex-start !important;
  }

  .justify-content-sm-end {
    justify-content: flex-end !important;
  }

  .justify-content-sm-center {
    justify-content: center !important;
  }

  .justify-content-sm-between {
    justify-content: space-between !important;
  }

  .justify-content-sm-around {
    justify-content: space-around !important;
  }

  .align-items-sm-start {
    align-items: flex-start !important;
  }

  .align-items-sm-end {
    align-items: flex-end !important;
  }

  .align-items-sm-center {
    align-items: center !important;
  }

  .align-items-sm-baseline {
    align-items: baseline !important;
  }

  .align-items-sm-stretch {
    align-items: stretch !important;
  }

  .align-content-sm-start {
    align-content: flex-start !important;
  }

  .align-content-sm-end {
    align-content: flex-end !important;
  }

  .align-content-sm-center {
    align-content: center !important;
  }

  .align-content-sm-between {
    align-content: space-between !important;
  }

  .align-content-sm-around {
    align-content: space-around !important;
  }

  .align-content-sm-stretch {
    align-content: stretch !important;
  }

  .align-self-sm-auto {
    align-self: auto !important;
  }

  .align-self-sm-start {
    align-self: flex-start !important;
  }

  .align-self-sm-end {
    align-self: flex-end !important;
  }

  .align-self-sm-center {
    align-self: center !important;
  }

  .align-self-sm-baseline {
    align-self: baseline !important;
  }

  .align-self-sm-stretch {
    align-self: stretch !important;
  }
}

@media (min-width: 768px) {
  .flex-md-row {
    flex-direction: row !important;
  }

  .flex-md-column {
    flex-direction: column !important;
  }

  .flex-md-row-reverse {
    flex-direction: row-reverse !important;
  }

  .flex-md-column-reverse {
    flex-direction: column-reverse !important;
  }

  .flex-md-wrap {
    flex-wrap: wrap !important;
  }

  .flex-md-nowrap {
    flex-wrap: nowrap !important;
  }

  .flex-md-wrap-reverse {
    flex-wrap: wrap-reverse !important;
  }

  .flex-md-fill {
    flex: auto !important;
  }

  .flex-md-grow-0 {
    flex-grow: 0 !important;
  }

  .flex-md-grow-1 {
    flex-grow: 1 !important;
  }

  .flex-md-shrink-0 {
    flex-shrink: 0 !important;
  }

  .flex-md-shrink-1 {
    flex-shrink: 1 !important;
  }

  .justify-content-md-start {
    justify-content: flex-start !important;
  }

  .justify-content-md-end {
    justify-content: flex-end !important;
  }

  .justify-content-md-center {
    justify-content: center !important;
  }

  .justify-content-md-between {
    justify-content: space-between !important;
  }

  .justify-content-md-around {
    justify-content: space-around !important;
  }

  .align-items-md-start {
    align-items: flex-start !important;
  }

  .align-items-md-end {
    align-items: flex-end !important;
  }

  .align-items-md-center {
    align-items: center !important;
  }

  .align-items-md-baseline {
    align-items: baseline !important;
  }

  .align-items-md-stretch {
    align-items: stretch !important;
  }

  .align-content-md-start {
    align-content: flex-start !important;
  }

  .align-content-md-end {
    align-content: flex-end !important;
  }

  .align-content-md-center {
    align-content: center !important;
  }

  .align-content-md-between {
    align-content: space-between !important;
  }

  .align-content-md-around {
    align-content: space-around !important;
  }

  .align-content-md-stretch {
    align-content: stretch !important;
  }

  .align-self-md-auto {
    align-self: auto !important;
  }

  .align-self-md-start {
    align-self: flex-start !important;
  }

  .align-self-md-end {
    align-self: flex-end !important;
  }

  .align-self-md-center {
    align-self: center !important;
  }

  .align-self-md-baseline {
    align-self: baseline !important;
  }

  .align-self-md-stretch {
    align-self: stretch !important;
  }
}

@media (min-width: 992px) {
  .flex-lg-row {
    flex-direction: row !important;
  }

  .flex-lg-column {
    flex-direction: column !important;
  }

  .flex-lg-row-reverse {
    flex-direction: row-reverse !important;
  }

  .flex-lg-column-reverse {
    flex-direction: column-reverse !important;
  }

  .flex-lg-wrap {
    flex-wrap: wrap !important;
  }

  .flex-lg-nowrap {
    flex-wrap: nowrap !important;
  }

  .flex-lg-wrap-reverse {
    flex-wrap: wrap-reverse !important;
  }

  .flex-lg-fill {
    flex: auto !important;
  }

  .flex-lg-grow-0 {
    flex-grow: 0 !important;
  }

  .flex-lg-grow-1 {
    flex-grow: 1 !important;
  }

  .flex-lg-shrink-0 {
    flex-shrink: 0 !important;
  }

  .flex-lg-shrink-1 {
    flex-shrink: 1 !important;
  }

  .justify-content-lg-start {
    justify-content: flex-start !important;
  }

  .justify-content-lg-end {
    justify-content: flex-end !important;
  }

  .justify-content-lg-center {
    justify-content: center !important;
  }

  .justify-content-lg-between {
    justify-content: space-between !important;
  }

  .justify-content-lg-around {
    justify-content: space-around !important;
  }

  .align-items-lg-start {
    align-items: flex-start !important;
  }

  .align-items-lg-end {
    align-items: flex-end !important;
  }

  .align-items-lg-center {
    align-items: center !important;
  }

  .align-items-lg-baseline {
    align-items: baseline !important;
  }

  .align-items-lg-stretch {
    align-items: stretch !important;
  }

  .align-content-lg-start {
    align-content: flex-start !important;
  }

  .align-content-lg-end {
    align-content: flex-end !important;
  }

  .align-content-lg-center {
    align-content: center !important;
  }

  .align-content-lg-between {
    align-content: space-between !important;
  }

  .align-content-lg-around {
    align-content: space-around !important;
  }

  .align-content-lg-stretch {
    align-content: stretch !important;
  }

  .align-self-lg-auto {
    align-self: auto !important;
  }

  .align-self-lg-start {
    align-self: flex-start !important;
  }

  .align-self-lg-end {
    align-self: flex-end !important;
  }

  .align-self-lg-center {
    align-self: center !important;
  }

  .align-self-lg-baseline {
    align-self: baseline !important;
  }

  .align-self-lg-stretch {
    align-self: stretch !important;
  }
}

@media (min-width: 1200px) {
  .flex-xl-row {
    flex-direction: row !important;
  }

  .flex-xl-column {
    flex-direction: column !important;
  }

  .flex-xl-row-reverse {
    flex-direction: row-reverse !important;
  }

  .flex-xl-column-reverse {
    flex-direction: column-reverse !important;
  }

  .flex-xl-wrap {
    flex-wrap: wrap !important;
  }

  .flex-xl-nowrap {
    flex-wrap: nowrap !important;
  }

  .flex-xl-wrap-reverse {
    flex-wrap: wrap-reverse !important;
  }

  .flex-xl-fill {
    flex: auto !important;
  }

  .flex-xl-grow-0 {
    flex-grow: 0 !important;
  }

  .flex-xl-grow-1 {
    flex-grow: 1 !important;
  }

  .flex-xl-shrink-0 {
    flex-shrink: 0 !important;
  }

  .flex-xl-shrink-1 {
    flex-shrink: 1 !important;
  }

  .justify-content-xl-start {
    justify-content: flex-start !important;
  }

  .justify-content-xl-end {
    justify-content: flex-end !important;
  }

  .justify-content-xl-center {
    justify-content: center !important;
  }

  .justify-content-xl-between {
    justify-content: space-between !important;
  }

  .justify-content-xl-around {
    justify-content: space-around !important;
  }

  .align-items-xl-start {
    align-items: flex-start !important;
  }

  .align-items-xl-end {
    align-items: flex-end !important;
  }

  .align-items-xl-center {
    align-items: center !important;
  }

  .align-items-xl-baseline {
    align-items: baseline !important;
  }

  .align-items-xl-stretch {
    align-items: stretch !important;
  }

  .align-content-xl-start {
    align-content: flex-start !important;
  }

  .align-content-xl-end {
    align-content: flex-end !important;
  }

  .align-content-xl-center {
    align-content: center !important;
  }

  .align-content-xl-between {
    align-content: space-between !important;
  }

  .align-content-xl-around {
    align-content: space-around !important;
  }

  .align-content-xl-stretch {
    align-content: stretch !important;
  }

  .align-self-xl-auto {
    align-self: auto !important;
  }

  .align-self-xl-start {
    align-self: flex-start !important;
  }

  .align-self-xl-end {
    align-self: flex-end !important;
  }

  .align-self-xl-center {
    align-self: center !important;
  }

  .align-self-xl-baseline {
    align-self: baseline !important;
  }

  .align-self-xl-stretch {
    align-self: stretch !important;
  }
}

.modal-open {
  overflow: hidden;
}

.modal-open .modal {
  overflow-x: hidden;
  overflow-y: auto;
}

.modal {
  z-index: 1050;
  width: 100%;
  height: 100%;
  outline: 0;
  display: none;
  position: fixed;
  top: 0;
  left: 0;
  overflow: hidden;
}

.modal-dialog {
  width: auto;
  pointer-events: none;
  margin: .5rem;
  position: relative;
}

.modal.fade .modal-dialog {
  transition: transform .3s ease-out;
  transform: translate(0, -50px);
}

@media (prefers-reduced-motion: reduce) {
  .modal.fade .modal-dialog {
    transition: none;
  }
}

.modal.show .modal-dialog {
  transform: none;
}

.modal.modal-static .modal-dialog {
  transform: scale(1.02);
}

.modal-dialog-scrollable {
  max-height: calc(100% - 1rem);
  display: flex;
}

.modal-dialog-scrollable .modal-content {
  max-height: calc(100vh - 1rem);
  overflow: hidden;
}

.modal-dialog-scrollable .modal-header, .modal-dialog-scrollable .modal-footer {
  flex-shrink: 0;
}

.modal-dialog-scrollable .modal-body {
  overflow-y: auto;
}

.modal-dialog-centered {
  min-height: calc(100% - 1rem);
  align-items: center;
  display: flex;
}

.modal-dialog-centered:before {
  height: calc(100vh - 1rem);
  height: min-content;
  content: "";
  display: block;
}

.modal-dialog-centered.modal-dialog-scrollable {
  height: 100%;
  flex-direction: column;
  justify-content: center;
}

.modal-dialog-centered.modal-dialog-scrollable .modal-content {
  max-height: none;
}

.modal-dialog-centered.modal-dialog-scrollable:before {
  content: none;
}

.modal-content {
  width: 100%;
  pointer-events: auto;
  background-color: #fff;
  background-clip: padding-box;
  border: 1px solid #0003;
  border-radius: .3rem;
  outline: 0;
  flex-direction: column;
  display: flex;
  position: relative;
}

.modal-backdrop {
  z-index: 1040;
  width: 100vw;
  height: 100vh;
  background-color: #000;
  position: fixed;
  top: 0;
  left: 0;
}

.modal-backdrop.fade {
  opacity: 0;
}

.modal-backdrop.show {
  opacity: .5;
}

.modal-header {
  border-bottom: 1px solid #dee2e6;
  border-top-left-radius: calc(.3rem - 1px);
  border-top-right-radius: calc(.3rem - 1px);
  justify-content: space-between;
  align-items: flex-start;
  padding: 1rem;
  display: flex;
}

.modal-header .close {
  margin: -1rem -1rem -1rem auto;
  padding: 1rem;
}

.modal-title {
  margin-bottom: 0;
  line-height: 1.5;
}

.modal-body {
  flex: auto;
  padding: 1rem;
  position: relative;
}

.modal-footer {
  border-top: 1px solid #dee2e6;
  border-bottom-right-radius: calc(.3rem - 1px);
  border-bottom-left-radius: calc(.3rem - 1px);
  flex-wrap: wrap;
  justify-content: flex-end;
  align-items: center;
  padding: .75rem;
  display: flex;
}

.modal-footer > * {
  margin: .25rem;
}

.modal-scrollbar-measure {
  width: 50px;
  height: 50px;
  position: absolute;
  top: -9999px;
  overflow: scroll;
}

@media (min-width: 576px) {
  .modal-dialog {
    max-width: 500px;
    margin: 1.75rem auto;
  }

  .modal-dialog-scrollable {
    max-height: calc(100% - 3.5rem);
  }

  .modal-dialog-scrollable .modal-content {
    max-height: calc(100vh - 3.5rem);
  }

  .modal-dialog-centered {
    min-height: calc(100% - 3.5rem);
  }

  .modal-dialog-centered:before {
    height: calc(100vh - 3.5rem);
    height: min-content;
  }

  .modal-sm {
    max-width: 300px;
  }
}

@media (min-width: 992px) {
  .modal-lg, .modal-xl {
    max-width: 800px;
  }
}

@media (min-width: 1200px) {
  .modal-xl {
    max-width: 1140px;
  }
}

.bubbles {
  flex-direction: column;
  display: flex;
  position: relative;
  overflow: hidden;
}

.bubbles.trashed {
  animation: 3s cubic-bezier(.87, 0, .13, 1) trashed;
}

@keyframes trashed {
  0% {
    top: -100px;
  }

  25% {
    top: -500px;
  }

  100% {
    top: -100px;
  }
}

.bubbles > li {
  text-align: center;
  width: 100px;
  height: 100px;
  opacity: 0;
  background-color: #ffffff26;
  border: 1px solid #0a199933;
  border-radius: 50%;
  padding: 10px;
  list-style: none;
  transition-timing-function: linear;
  animation: 25s infinite float;
  display: block;
  position: absolute;
  bottom: -200px;
  box-shadow: inset -3px 1px 10px #0a199980, inset 0 0 35px #32646433;
}

.bubbles > li:after {
  content: "";
  height: 8px;
  width: 11px;
  background: #ffffff4d;
  border-radius: 50%;
  display: block;
  position: absolute;
  top: 20%;
  right: 20%;
  transform: rotate(40deg);
  box-shadow: 0 0 5px #ffffff4d;
}

.bubbles > li:before {
  content: "";
  height: 15px;
  width: 20px;
  background: #ffffff1a;
  border-radius: 50%;
  display: block;
  position: absolute;
  bottom: 20%;
  left: 20%;
  transform: rotate(40deg);
  box-shadow: 0 0 5px #fff3;
}

.bubbles > li:nth-child(1) {
  left: 10%;
}

.bubbles > li:nth-child(2) {
  animation-duration: 17s;
  animation-delay: 2s;
  left: 20%;
  transform: scale(.5);
}

.bubbles > li:nth-child(3) {
  animation-delay: 4s;
  left: 25%;
}

.bubbles > li:nth-child(4) {
  background-color: #ffffff40;
  animation-duration: 22s;
  left: 40%;
  transform: scale(.3);
}

.bubbles > li:nth-child(5) {
  left: 70%;
  transform: scale(.4);
}

.bubbles > li:nth-child(6) {
  background-color: #ffffff1a;
  animation-delay: 3s;
  left: 80%;
  transform: scale(.6);
}

.bubbles > li:nth-child(7) {
  animation-delay: 7s;
  left: 32%;
  transform: scale(.8);
}

.bubbles > li:nth-child(8) {
  animation-duration: 40s;
  animation-delay: 15s;
  left: 55%;
  transform: scale(.2);
}

.bubbles > li:nth-child(9) {
  animation-duration: 40s;
  animation-delay: 2s;
  left: 25%;
  transform: scale(.1);
}

.bubbles > li:nth-child(10) {
  animation-delay: 11s;
  left: 85%;
  transform: scale(1);
}

.bubbles > li:nth-child(11) {
  animation-delay: 1s;
  left: 85%;
  transform: scale(.5);
}

.bubbles > li:nth-child(12) {
  animation-duration: 40s;
  animation-delay: 6s;
  left: 45%;
  transform: scale(.4);
}

.bubbles > li:nth-child(13) {
  animation-delay: 7s;
  left: 15%;
  transform: scale(.6);
}

.bubbles > li:nth-child(14) {
  animation-duration: 15s;
  animation-delay: 1s;
  left: 15%;
  transform: scale(.9);
}

.bubbles > li:nth-child(15) {
  animation-duration: 22s;
  animation-delay: 20s;
  left: 86%;
  transform: scale(.3);
}

.bubbles > li:nth-child(16) {
  animation-duration: 6s;
  animation-delay: 7s;
  left: 34%;
  transform: scale(1);
}

.bubbles > li:nth-child(17) {
  animation-duration: 11s;
  animation-delay: 32s;
  left: 2%;
  transform: scale(.7);
}

.bubbles > li:nth-child(18) {
  animation-duration: 14s;
  animation-delay: 14s;
  left: 73%;
  transform: scale(.4);
}

.bubbles > li:nth-child(19) {
  animation-duration: 5s;
  animation-delay: 2s;
  left: 59%;
  transform: scale(.8);
}

.bubbles > li:nth-child(20) {
  animation-duration: 28s;
  animation-delay: 86s;
  left: 11%;
  transform: scale(.3);
}

.bubbles > li:nth-child(21) {
  animation-duration: 10s;
  animation-delay: 48s;
  left: 44%;
  transform: scale(.9);
}

.bubbles > li:nth-child(22) {
  animation-duration: 21s;
  animation-delay: 32s;
  left: 93%;
  transform: scale(.7);
}

.bubbles > li:nth-child(23) {
  animation-duration: 7s;
  animation-delay: 60s;
  left: 31%;
  transform: scale(.5);
}

.bubbles > li:nth-child(24) {
  -webkit-animation-delay: 48s;
  animation-duration: 19s;
  animation-delay: 8s;
  left: 69%;
  transform: scale(.3);
}

@-webkit-keyframes float {
  0% {
    opacity: 0;
    margin-left: 0;
    bottom: -200px;
  }

  5% {
    opacity: 1;
    margin-left: 0;
  }

  10% {
    margin-left: 10px;
  }

  15% {
    margin-left: 0;
  }

  20% {
    margin-left: 15px;
  }

  25% {
    margin-left: 0;
  }

  30% {
    margin-left: 10px;
  }

  35% {
    margin-left: 0;
  }

  40% {
    margin-left: 10px;
  }

  45% {
    margin-left: 0;
  }

  50% {
    margin-left: 20px;
  }

  55% {
    margin-left: 0;
  }

  60% {
    margin-left: 10px;
  }

  65% {
    margin-left: 0;
  }

  70% {
    margin-left: 10px;
  }

  75% {
    margin-left: 0;
  }

  80% {
    margin-left: 15px;
  }

  85% {
    margin-left: 0;
  }

  90% {
    margin-left: 10px;
  }

  95% {
    margin-left: 0;
  }

  100% {
    margin-left: 0;
    bottom: 120%;
  }
}

@keyframes float {
  0% {
    opacity: 0;
    margin-left: 0;
    bottom: -120px;
  }

  5% {
    opacity: 0;
    margin-left: 0;
  }

  10% {
    opacity: 0;
    margin-left: 10px;
  }

  15% {
    margin-left: 0;
  }

  20% {
    margin-left: 15px;
  }

  25% {
    margin-left: 0;
  }

  30% {
    margin-left: 10px;
  }

  35% {
    opacity: 1;
    margin-left: 0;
  }

  40% {
    margin-left: 10px;
  }

  45% {
    margin-left: 0;
  }

  50% {
    margin-left: 20px;
  }

  55% {
    margin-left: 0;
  }

  60% {
    margin-left: 10px;
  }

  65% {
    margin-left: 0;
  }

  70% {
    margin-left: 10px;
  }

  75% {
    margin-left: 0;
  }

  80% {
    margin-left: 15px;
  }

  85% {
    margin-left: 0;
  }

  90% {
    margin-left: 10px;
  }

  95% {
    margin-left: 0;
  }

  100% {
    margin-left: 0;
    bottom: 110%;
  }
}

ul.rays {
  width: 0;
  height: 0;
  opacity: .2;
  filter: blur(10px);
  margin: auto;
  padding: 0;
  list-style: none;
  position: absolute;
  inset: -100% -10em 290px;
}

ul.rays.left {
  animation: 60s linear infinite rotate-l;
}

ul.rays.right {
  animation: 40s linear infinite rotate-r;
}

.rays > li, .rays > li:before, .rays > li:after {
  width: 0;
  height: 0;
  border: 1.2em solid #0000;
  border-left: 20em solid #ffffff1a;
  border-right: 20em solid #ffffff1a;
  font-size: 60px;
  position: absolute;
}

.rays > li {
  margin-top: -1.2em;
  top: 40%;
  left: -20em;
  transform: rotate(.1deg);
}

.rays > li:before, .rays > li:after {
  content: "";
  display: block;
  top: -1.2em;
  left: -20em;
}

.rays > li:before {
  transform: rotate(60deg);
}

.rays > li:after {
  transform: rotate(-60deg);
}

.rays > li:nth-child(2) {
  transform: rotate(13deg);
}

.rays > li:nth-child(2), .rays > li:nth-child(2):before, .rays > li:nth-child(2):after {
  border-color: #0000 #fff3;
}

.rays > li:nth-child(3) {
  transform: rotate(37deg);
}

.rays > li:nth-child(3), .rays > li:nth-child(3):before, LI:nth-child(3):after {
  border-color: #0000 #e6f7ff33;
}

.rays > li:nth-child(4) {
  transform: rotate(55deg);
}

.rays > li:nth-child(4), .rays > li:nth-child(4):before, .rays > li:nth-child(4):after {
  border-color: #0000 #ffffff1a;
}

@-webkit-keyframes rotate-l {
  100% {
    transform: rotate(361deg);
  }
}

@keyframes rotate-l {
  100% {
    transform: rotate(363deg);
  }
}

@-webkit-keyframes rotate-r {
  100% {
    transform: rotate(-365deg);
  }
}

@keyframes rotate-r {
  100% {
    transform: rotate(-367deg);
  }
}

@-webkit-keyframes rot {
  to {
    transform: rotate(1.1turn);
  }
}

@keyframes rot {
  to {
    transform: rotate(1.3turn);
  }
}

body {
  position: relative;
}

.rays-wrapper {
  width: 100%;
  height: 100%;
  max-width: 100%;
  max-height: 100%;
  position: absolute;
  overflow: hidden;
}

.stars {
  width: 100%;
  height: 100%;
  filter: hue-rotate(90deg);
  background: url("img/stars.298a3450.webp");
  animation: 2s linear infinite twinkle;
  position: absolute;
  top: 0;
  left: 0;
}

.stars.left {
  background-position: 0 100%;
  animation-delay: .7s;
}

.stars.rotaded {
  transform-origin: center;
  animation-delay: 1.4s;
  transform: rotate(180deg);
}

@keyframes twinkle {
  0%, 100% {
    opacity: 0;
  }

  50% {
    opacity: 1;
  }
}

.asteroids {
  z-index: -1;
  position: absolute;
  inset: 0;
}

.asteroids .asteroid {
  height: 2px;
  width: .5px;
  z-index: 6;
  transform-origin: 100%;
  background: #fff6;
  border-radius: 50%;
  animation: 20s linear 5s infinite shoot-to-right;
  position: absolute;
  top: 9vh;
  left: 9vw;
  transform: rotate(45deg);
  box-shadow: -15px 0 5px #f0fc, 0 0 3px #f0fc;
}

.asteroids .asteroid:nth-child(1) {
  animation-delay: 0s;
  top: 30vh;
  left: 20vw;
}

.asteroids .asteroid:nth-child(2) {
  animation-delay: 4s;
  top: 20vh;
  left: 50vw;
}

.asteroids .asteroid:nth-child(3) {
  animation-delay: 8s;
  top: 70vh;
  left: 20vw;
}

.asteroids .asteroid:nth-child(4) {
  top: 30vh;
  right: 20vw;
  left: unset;
  animation: 20s linear 5s infinite shoot-to-left;
  transform: rotate(135deg);
}

.asteroids .asteroid:nth-child(5) {
  top: 0;
  right: 0;
  left: unset;
  animation: 20s linear 10s infinite shoot-to-left;
  transform: rotate(135deg);
}

.asteroids .asteroid:nth-child(6) {
  top: 70vh;
  right: 20vw;
  left: unset;
  animation: 20s linear 15s infinite shoot-to-left;
  transform: rotate(135deg);
}

.asteroids .asteroid:nth-child(7) {
  animation-delay: 17s;
  top: 20vh;
  left: 10vw;
}

.asteroids .asteroid:nth-child(8) {
  animation-delay: 14s;
  top: 0;
  left: 0;
}

.asteroids .asteroid:nth-child(9) {
  animation-delay: 18s;
  top: 70vh;
  left: 20vw;
}

@keyframes shoot-to-right {
  0% {
    opacity: 1;
    width: 25px;
    transform: rotate(45deg)translate3d(0, 0, 0);
  }

  2.5% {
    opacity: 1;
    width: 25px;
    transform: rotate(45deg)translate3d(200px, 0, 0);
  }

  5%, 100% {
    opacity: 0;
    width: 0;
    transform: rotate(45deg)translate3d(400px, 0, 0);
  }
}

@keyframes shoot-to-left {
  0% {
    opacity: 1;
    width: 25px;
    transform: rotate(135deg)translate3d(0, 0, 0);
  }

  2.5% {
    opacity: 1;
    width: 25px;
    transform: rotate(135deg)translate3d(200px, 0, 0);
  }

  5%, 100% {
    opacity: 0;
    width: 0;
    transform: rotate(135deg)translate3d(400px, 0, 0);
  }
}

.myrays-wrapper {
  width: 100%;
  height: 100vh;
  position: absolute;
  top: 0;
  left: 0;
  overflow: hidden;
}

.myrays-wrapper .rays {
  width: 120%;
  height: 120%;
  transform-origin: top;
  opacity: 0;
  background-position: top;
  background-repeat: no-repeat;
  background-size: contain;
  animation: 4s linear infinite lighting;
  position: absolute;
  top: 0;
  left: -10%;
}

@media (max-width: 576px) {
  .myrays-wrapper .rays {
    width: calc(100% + 20px);
    opacity: .6;
    background-size: cover;
    left: -10px;
  }
}

.myrays-wrapper .rays:nth-child(1) {
  background-image: url("img/rays-1.40d4d874.webp");
}

.myrays-wrapper .rays:nth-child(2) {
  background-image: url("img/rays-2.d8c4a378.webp");
  animation-delay: 2s;
}

@keyframes lighting {
  0%, 100% {
    opacity: .4;
    transform: translateX(0);
  }

  25% {
    transform: translateX(10px);
  }

  50% {
    opacity: .8;
    transform: translateX(0);
  }

  75% {
    transform: translateX(-10px);
  }
}

.floating-animate {
  animation: 6s ease-in-out infinite floating;
}

@keyframes floating {
  0% {
    transform: translateY(0);
  }

  50% {
    transform: translateY(-20px);
  }

  100% {
    transform: translateY(0);
  }
}

@keyframes swimming {
  0%, 100% {
    transform: rotate(0);
  }

  25% {
    transform: rotate(10deg);
  }

  50% {
    transform: rotate(0);
  }

  75% {
    transform: rotate(-10deg);
  }
}

@keyframes foating_cat {
  0% {
    transform: translateX(-5%)translateY(-5%);
  }

  50% {
    transform: translateY(-20px);
  }

  100% {
    transform: translateY(0);
  }
}

@keyframes top-spans {
  0%, 100% {
    transform: translateX(0)translateY(0);
  }

  10% {
    transform: translateX(-50%)translateY(-30%);
  }

  50% {
    transform: translateX(100%)translateY(-30%);
  }

  70% {
    transform: translateX(10%)translateY(-30%);
  }

  90% {
    transform: translateX(50%)translateY(50%);
  }
}

@keyframes bottom-spans-left {
  0%, 100% {
    transform: translateX(0)translateY(0);
  }

  10% {
    transform: translateX(50%)translateY(50%);
  }

  50% {
    transform: translateX(100%)translateY(50%);
  }

  70% {
    transform: translateX(100%)translateY(50%);
  }

  90% {
    transform: translateX(50%)translateY(-50%);
  }
}

@keyframes bottom-spans-right {
  0%, 100% {
    transform: translateX(0)translateY(0);
  }

  10% {
    transform: translateX(-50%)translateY(30%);
  }

  50% {
    transform: translateX(-100%)translateY(30%);
  }

  75% {
    transform: translateX(-10%)translateY(30%);
  }
}

.response {
  visibility: hidden;
  opacity: 0;
  max-width: 80%;
  color: #fff;
  background: #292456;
  border-radius: 10px;
  margin: auto;
  padding: 30px;
  transition: all .5s;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translateX(0%)translateY(-50%);
}

.response.show {
  visibility: visible;
  opacity: 1;
  transform: translateX(-50%)translateY(-50%);
}

.checkbox-icon.error {
  box-shadow: 0 0 5px 3px red;
}

body {
  background: linear-gradient(#6dacc2 0%, #2eb5cf 29.98%, #006499 60.24%, #113053 100%);
  position: relative;
}

* {
  box-sizing: border-box;
}

ul {
  margin: 0;
  padding: 0;
  list-style: none;
}

button {
  background: none;
  border: none;
}

.text-left {
  text-align: left;
}

.text-right {
  text-align: right;
}

.text-center {
  text-align: center;
}

.header {
  text-align: center;
  margin-bottom: 25px;
  font-family: CCKillJoy;
  font-size: 48px;
  line-height: 1;
}

@media (max-width: 1600px) {
  .header {
    font-size: 35px;
  }
}

@media (max-width: 1200px) {
  .header {
    font-size: 28px;
  }
}

@media (max-width: 768px) {
  .header {
    font-size: 24px;
  }
}

.text {
  font-size: 24px;
  line-height: 28px;
}

@media (max-width: 1600px) {
  .text {
    font-size: 20px;
    line-height: 24px;
  }
}

@media (max-width: 768px) {
  .text {
    font-size: 18px;
    line-height: 21px;
  }
}

.overlay {
  height: 0%;
  width: 100%;
  z-index: 40;
  opacity: 0;
  background-color: #000000e6;
  transition: height, opacity .3s ease-in-out;
  position: absolute;
  top: 0;
  left: 0;
}

@media (max-width: 576px) {
  body.nav-opened .overlay {
    height: 100%;
    opacity: 1;
  }
}

@media (max-width: 992px) {
  body.button_overlay_opened {
    touch-action: none;
    -ms-touch-action: none;
    overflow-y: hidden;
  }

  body.button_overlay_opened .menu, body.button_overlay_opened .logo {
    z-index: 0;
  }
}

.menu {
  z-index: 50;
  flex-direction: row;
  display: flex;
  position: fixed;
  top: 50%;
  left: 0;
  transform: translateY(-50%)translateX(-100%);
}

@media (max-width: 576px) {
  .menu {
    width: 100vw;
    height: 100vh;
    bottom: 0;
    left: 0;
    top: unset;
    transform: translateY(100%);
  }
}

.menu button {
  width: 89px;
  height: 89px;
  background-color: #fff;
  border: none;
  border-radius: 50%;
  padding: 0;
  top: 50%;
  box-shadow: 0 4px 8px #0000001a;
}

@media (max-width: 1200px) {
  .menu button {
    width: 60px;
    height: 60px;
  }
}

@media (max-width: 576px) {
  .menu button {
    width: 60px;
    height: 60px;
    box-shadow: 0 0 8px 4px #0000001a;
  }
}

.menu button .close-icon {
  width: 32.5%;
  height: 32.5%;
}

.menu .open-icon_wrapper {
  width: 41.5%;
  min-height: 31.5%;
  height: 31.5%;
  margin: auto;
  display: block;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translateX(-50%)translateY(-50%);
}

.menu .open-icon_wrapper .open-icon {
  height: 100%;
  width: 100%;
  display: block;
}

.menu .open-icon_wrapper .open-icon span {
  width: 100%;
  height: 4px;
  background: #292456;
  border-radius: 2px;
  transition: all .3s;
  display: block;
}

.menu .open-icon_wrapper .open-icon span:first-child {
  position: absolute;
  top: 0;
}

.menu .open-icon_wrapper .open-icon span:last-child {
  position: absolute;
  bottom: 0;
}

.menu .open-icon_wrapper .open-icon span:nth-child(2) {
  position: absolute;
  top: calc(50% - 2px);
}

.menu .burger-wrapper {
  width: 97px;
  height: 204px;
  filter: drop-shadow(0 4px 8px #0000001a);
  z-index: 0;
  transition: z-index 0s .3s;
  position: absolute;
  top: 50%;
  left: 100%;
  transform: translateY(-50%);
}

@media (max-width: 1200px) {
  .menu .burger-wrapper {
    width: 62px;
    height: 139px;
  }
}

@media (max-width: 576px) {
  .menu .burger-wrapper {
    width: 66px;
    height: 140px;
    bottom: calc(100% - 20px);
    top: unset;
    transform-origin: 0 0;
    left: -25px;
    transform: translateY(100%)rotate(-90deg);
  }
}

.menu .burger-wrapper .burger-wrapper_bg {
  width: 100%;
  height: 100%;
}

.menu .burger-wrapper button {
  position: absolute;
  right: 0;
  transform: translateX(50%)translateY(-50%);
}

@media (max-width: 576px) {
  .menu .burger-wrapper button {
    transform: translateX(50%)translateY(-50%)rotate(-90deg);
  }
}

.menu nav {
  z-index: 1;
  transition: all .3s cubic-bezier(.68, -.6, .32, 1.6);
  position: relative;
  right: 200px;
}

@media (max-width: 576px) {
  .menu nav {
    right: unset;
    width: 100%;
    height: 100%;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    display: flex;
  }
}

.menu nav ul:not(.addon-nav) {
  flex-direction: column;
  flex: auto;
  align-items: flex-start;
  display: flex;
}

@media (max-width: 576px) {
  .menu nav ul:not(.addon-nav) {
    flex: unset;
    align-items: center;
  }
}

.menu nav ul:not(.addon-nav) li {
  background-color: #fff;
  border-radius: 14px;
  margin-top: 9px;
  margin-bottom: 9px;
  position: relative;
}

.menu nav ul:not(.addon-nav) li.active, .menu nav ul:not(.addon-nav) li:hover, .menu nav ul:not(.addon-nav) li.active .liquid-container, .menu nav ul:not(.addon-nav) li:hover .liquid-container, .menu nav ul:not(.addon-nav) li.active .liquid-container span, .menu nav ul:not(.addon-nav) li:hover .liquid-container span {
  background-color: #ffdb00;
}

.menu nav ul:not(.addon-nav) li a {
  text-align: center;
  color: #292456;
  padding: 2px 8px;
  font-size: 18px;
  text-decoration: none;
  transition: all .2s;
  display: block;
  position: relative;
}

@media (max-width: 340px) {
  .menu nav ul:not(.addon-nav) li {
    margin-top: 7px;
    margin-bottom: 7px;
  }

  .menu nav ul:not(.addon-nav) li a {
    font-size: 16px;
  }
}

.menu nav ul:not(.addon-nav) li .liquid-container {
  width: 100%;
  height: 100%;
  filter: url("#goo");
  z-index: -1;
  background-color: #fff;
  border-radius: 14px;
  position: absolute;
  top: 0;
  left: 0;
  box-shadow: 0 4px 8px #0000001a;
}

.menu nav ul:not(.addon-nav) li .liquid-container span {
  z-index: -2;
  background-color: #fff;
  border-radius: 50%;
  position: absolute;
}

.menu nav ul:not(.addon-nav) li .liquid-container span:nth-child(1) {
  width: 20px;
  height: 20px;
  top: 0%;
  left: 10%;
}

.menu nav ul:not(.addon-nav) li .liquid-container span:nth-child(2) {
  width: 10px;
  height: 10px;
  top: 0%;
  right: 10%;
}

.menu nav ul:not(.addon-nav) li .liquid-container span:nth-child(3) {
  width: 10px;
  height: 10px;
  bottom: 0%;
  left: 10%;
}

.menu nav ul:not(.addon-nav) li .liquid-container span:nth-child(4) {
  width: 20px;
  height: 20px;
  bottom: 0%;
  right: 10%;
}

.menu nav ul:not(.addon-nav) li:hover .liquid-container > span:nth-child(-n+2) {
  animation: 4s cubic-bezier(.12, 0, .39, 0) infinite top-spans;
}

.menu nav ul:not(.addon-nav) li:hover .liquid-container > span:nth-child(3) {
  animation: 3s cubic-bezier(.12, 0, .39, 0) infinite bottom-spans-left;
}

.menu nav ul:not(.addon-nav) li:hover .liquid-container > span:nth-child(4) {
  animation: 3s cubic-bezier(.12, 0, .39, 0) infinite bottom-spans-right;
}

.menu nav ul:not(.addon-nav) li:hover .liquid-container > span:nth-child(2) {
  animation-delay: .3s;
}

.menu nav ul:not(.addon-nav) li:hover .liquid-container > span:nth-child(4) {
  animation-delay: .5s;
}

.menu nav ul:not(.addon-nav) li:nth-child(1) {
  left: 46%;
}

.menu nav ul:not(.addon-nav) li:nth-child(2) {
  left: 74%;
}

.menu nav ul:not(.addon-nav) li:nth-child(3) {
  left: 94%;
}

.menu nav ul:not(.addon-nav) li:nth-child(4) {
  left: 106%;
}

.menu nav ul:not(.addon-nav) li:nth-child(5) {
  left: 110%;
}

.menu nav ul:not(.addon-nav) li:nth-child(6) {
  left: 106%;
}

.menu nav ul:not(.addon-nav) li:nth-child(7) {
  left: 94%;
}

.menu nav ul:not(.addon-nav) li:nth-child(8) {
  left: 74%;
}

.menu nav ul:not(.addon-nav) li:nth-child(9) {
  left: 46%;
}

@media (max-width: 576px) {
  .menu nav ul:not(.addon-nav) li {
    position: relative;
    left: unset !important;
  }
}

.menu nav .addon-nav {
  margin-top: 35px;
  display: none;
}

.menu nav .addon-nav li:first-child {
  margin-bottom: 10px;
}

.menu nav .addon-nav a img {
  max-width: 135px;
}

@media (max-width: 340px) {
  .menu nav .addon-nav a img {
    max-width: 100px;
  }
}

@media (max-width: 576px) {
  .menu nav .addon-nav {
    display: block;
  }
}

@media (max-width: 340px) {
  .menu nav .addon-nav {
    margin-top: 10px;
  }
}

.menu nav button {
  position: absolute;
  top: 50%;
  right: 0;
  transform: translateY(-50%);
}

@media (max-width: 576px) {
  .menu nav button {
    right: unset;
    left: 40px;
    top: unset;
    bottom: 6px;
  }
}

.menu.nav-opened nav {
  right: -141px;
}

@media (max-width: 1200px) {
  .menu.nav-opened nav {
    right: -92px;
  }
}

@media (max-width: 576px) {
  .menu.nav-opened nav {
    right: unset;
    transform: translateY(-100%);
  }
}

.menu.nav-opened .burger-wrapper {
  z-index: 5;
}

.menu.nav-opened .open-icon_wrapper .open-icon span:first-child {
  transform-origin: center;
  top: calc(50% - 2px);
  transform: rotate(45deg);
}

.menu.nav-opened .open-icon_wrapper .open-icon span:last-child {
  transform-origin: center;
  bottom: calc(50% - 2px);
  transform: rotate(45deg);
}

.menu.nav-opened .open-icon_wrapper .open-icon span:nth-child(2) {
  transform-origin: center;
  top: calc(50% - 2px);
  transform: rotate(-45deg);
}

.logo {
  z-index: 10;
  width: 24%;
  position: fixed;
  top: 20px;
  left: 50%;
  transform: translateX(-50%);
}

@media (max-width: 1600px) {
  .logo {
    width: 20%;
  }
}

@media (max-width: 576px) {
  .logo {
    width: auto;
    position: absolute;
    top: 5px;
  }
}

.logo img {
  max-width: 100%;
  max-height: 100%;
  width: auto;
}

.section {
  height: 100vh;
  padding-top: 206px;
  position: relative;
  overflow: hidden;
}

@media (orientation: portrait), (max-width: 1400px) {
  .section {
    height: auto;
  }

  .section:not(.section-1) {
    padding: 200px 0;
  }
}

@media (max-width: 992px) {
  .section:not(.section-1) {
    padding: 100px 0;
  }
}

@media (max-width: 576px) {
  .section {
    padding-top: 0;
  }
}

.section_content {
  height: 100%;
  z-index: 1;
  padding: 20px 0 0;
  position: relative;
}

@media (max-width: 768px) {
  .section_content {
    padding: 20px 25px 0;
  }
}

.img-fluid {
  max-width: 100%;
  max-height: 100%;
  width: auto;
}

.section_bg {
  z-index: -1;
  position: absolute;
  inset: 0;
}

@keyframes foating_cat {
  0% {
    transform: translateX(-5%)translateY(-5%);
  }
}

.section-1 {
  z-index: 1;
  position: relative;
}

@media (min-height: 675px) and (max-height: 812px) {
  .section-1 .section_content {
    padding-bottom: 50px;
  }
}

.section-1 .row {
  height: 100%;
  position: relative;
}

.section-1 .row .col-sm-6 {
  max-height: 100%;
}

.section-1 .row .img-fluid {
  max-height: 100%;
  max-width: 100%;
}

.section-1 .row .img-fluid.main-text {
  max-height: 32%;
}

.section-1 .row .img-fluid.main-aquarium {
  max-height: 68%;
  position: relative;
  left: 27%;
}

.section-1 .row .img-fluid.main-right {
  position: relative;
  top: -5%;
  right: 5%;
}

.section-1 .star {
  width: 71px;
  height: 71px;
  background-position: center;
  background-repeat: no-repeat;
  background-size: contain;
  position: absolute;
}

@media (max-width: 1200px) {
  .section-1 .star {
    width: 40px;
    height: 40px;
  }
}

.section-1 .star:nth-child(1) {
  background-image: url("img/blue-star-1.1b9f38a4.webp");
  top: 15%;
  left: -1%;
}

.section-1 .star:nth-child(2) {
  background-image: url("img/yellow-star-1.2b4078e2.webp");
  top: 83%;
  left: 3%;
}

.section-1 .star:nth-child(3) {
  background-image: url("img/blue-star-2.55f56c61.webp");
  top: 61%;
  left: 7%;
}

.section-1 .star:nth-child(4) {
  background-image: url("img/yellow-star-2.8b656745.webp");
  top: 28%;
  left: 13%;
}

.section-1 .star:nth-child(5) {
  background-image: url("img/yellow-star-3.39bc36ae.webp");
  top: 70%;
  left: 19%;
}

.section-1 .star:nth-child(6) {
  background-image: url("img/yellow-star-4.490e30d1.webp");
  top: 12%;
  left: 78%;
}

.section-1 .star:nth-child(7) {
  background-image: url("img/blue-star-3.cd9016b1.webp");
  top: 82%;
  left: 82%;
}

.section-1 .star:nth-child(8) {
  background-image: url("img/yellow-star-5.37cfb0ef.webp");
  top: 44%;
  left: 89%;
}

.section-1 .star:nth-child(9) {
  background-image: url("img/blue-star-4.a129ff48.webp");
  top: 5%;
  left: 97%;
}

.section-1 .star:nth-child(10) {
  background-image: url("img/yellow-star-6.3e8b1cc0.webp");
  top: 76%;
  left: 98%;
}

@media (max-width: 576px) {
  .section-1 {
    height: 100vh;
    height: calc(var(--vh, 1vh) * 100);
  }

  .section-1 .section_content {
    padding-top: 0;
  }

  .section-1 .col-sm-6.text-right {
    height: 100%;
    flex-direction: column;
    justify-content: space-between;
    align-items: center;
    display: flex;
  }

  .section-1 .col-sm-6.text-right .main-aquarium {
    max-height: 29%;
    left: unset;
    transform: translateX(10%);
  }

  .section-1 .col-sm-6.text-right .main-text {
    max-height: 17%;
    transform: translateX(-25%);
  }

  .section-1 .col-sm-6.text-left {
    height: 100%;
    justify-content: center;
    align-items: center;
    display: flex;
    position: absolute;
    top: 0;
  }

  .section-1 .col-sm-6.text-left .img-fluid.main-right {
    max-height: 55%;
    z-index: -1;
    top: unset;
    bottom: 26%;
    right: unset;
    position: absolute;
  }
}

.section-2 {
  padding-top: 0;
}

.section-2 .section_bg_img {
  position: absolute;
}

.section-2 .section_bg_img:before {
  background-repeat: no-repeat;
  background-size: contain;
}

.section-2 .section_bg_img:nth-child(1) {
  width: 13%;
  top: 66%;
  left: 7%;
  transform: rotate(340deg);
}

.section-2 .section_bg_img:nth-child(1):before {
  content: "";
  width: 100%;
  background-image: url("img/ufo.c9748655.webp");
  padding-bottom: 97%;
  display: block;
}

@media (max-width: 992px) {
  .section-2 .section_bg_img:nth-child(1) {
    width: 123px;
    top: unset;
    bottom: 0;
    left: -44px;
  }
}

.section-2 .section_bg_img:nth-child(2) {
  width: 12%;
  top: 9%;
  left: 16%;
  transform: rotate(21.5deg);
}

.section-2 .section_bg_img:nth-child(2):before {
  content: "";
  width: 100%;
  background-image: url("img/cat-pink.97e49c53.webp");
  padding-bottom: 125%;
  display: block;
}

@media (max-width: 992px) {
  .section-2 .section_bg_img:nth-child(2) {
    width: 100px;
    top: 18px;
    left: 1.95px;
  }
}

@media (max-width: 576px) {
  .section-2 .section_bg_img:nth-child(2) {
    width: 80px;
  }
}

.section-2 .section_bg_img:nth-child(3) {
  width: 10%;
  top: 75%;
  left: 45%;
  transform: rotate(19.25deg);
}

.section-2 .section_bg_img:nth-child(3):before {
  content: "";
  width: 100%;
  background-image: url("img/butterfly.a87c6657.webp");
  padding-bottom: 111%;
  display: block;
}

@media (max-width: 992px) {
  .section-2 .section_bg_img:nth-child(3) {
    display: none;
  }
}

.section-2 .section_bg_img:nth-child(4) {
  width: 17%;
  top: 7%;
  left: 68%;
  transform: rotate(300deg);
}

.section-2 .section_bg_img:nth-child(4):before {
  content: "";
  width: 100%;
  background-image: url("img/rocket.121dea2b.webp");
  padding-bottom: 102%;
  display: block;
}

@media (max-width: 992px) {
  .section-2 .section_bg_img:nth-child(4) {
    left: unset;
    width: 148px;
    top: 0;
    right: 8.95px;
  }
}

.section-2 .section_bg_img:nth-child(5) {
  width: 16%;
  top: 62%;
  left: 78%;
  transform: rotate(20.35deg);
}

.section-2 .section_bg_img:nth-child(5):before {
  content: "";
  width: 100%;
  background-image: url("img/cat-black.5d9e2afd.webp");
  padding-bottom: 93%;
  display: block;
}

@media (max-width: 992px) {
  .section-2 .section_bg_img:nth-child(5) {
    width: 137px;
    top: unset;
    bottom: 0;
    left: unset;
    right: -44px;
  }
}

@media (max-width: 576px) {
  .section-2 .section_bg_img:nth-child(5) {
    width: 100px;
    right: -30px;
  }
}

@media (max-width: 992px) {
  .section-3.section {
    padding-bottom: 150px;
  }
}

.section-3 .section_content {
  height: unset;
}

.section-3 .section_bg_img {
  position: absolute;
}

.section-3 .section_bg_img:before {
  background-repeat: no-repeat;
  background-size: contain;
}

.section-3 .section_bg_img:nth-child(1) {
  width: 4%;
  top: 22%;
  left: 3%;
}

.section-3 .section_bg_img:nth-child(1):before {
  content: "";
  width: 100%;
  background-image: url("img/blot-blue.52b25986.webp");
  padding-bottom: 92%;
  display: block;
}

@media (max-width: 992px) {
  .section-3 .section_bg_img:nth-child(1) {
    width: 43px;
    top: 113px;
    left: 29px;
  }
}

@media (max-width: 576px) {
  .section-3 .section_bg_img:nth-child(1) {
    top: 50px;
  }
}

.section-3 .section_bg_img:nth-child(2) {
  width: 17%;
  top: 50%;
  left: 7%;
  transform: rotate(-7.87deg);
}

.section-3 .section_bg_img:nth-child(2):before {
  content: "";
  width: 100%;
  background-image: url("img/tube-blue.c8037ef7.webp");
  padding-bottom: 147%;
  display: block;
}

@media (max-width: 992px) {
  .section-3 .section_bg_img:nth-child(2) {
    width: 197px;
    left: -6px;
    top: unset;
    bottom: -40px;
  }
}

.section-3 .section_bg_img:nth-child(3) {
  width: 19%;
  top: 2%;
  left: 13%;
}

.section-3 .section_bg_img:nth-child(3):before {
  content: "";
  width: 100%;
  background-image: url("img/tube-pink.5d47f2a8.webp");
  padding-bottom: 128%;
  display: block;
}

@media (max-width: 992px) {
  .section-3 .section_bg_img:nth-child(3) {
    display: none;
  }
}

.section-3 .section_bg_img:nth-child(4) {
  width: 5%;
  top: 65%;
  left: 71%;
}

.section-3 .section_bg_img:nth-child(4):before {
  content: "";
  width: 100%;
  background-image: url("img/blot-green.ee3f0ef6.webp");
  padding-bottom: 101%;
  display: block;
}

@media (max-width: 992px) {
  .section-3 .section_bg_img:nth-child(4) {
    display: none;
  }
}

.section-3 .section_bg_img:nth-child(5) {
  width: 20%;
  top: 5%;
  left: 63%;
}

.section-3 .section_bg_img:nth-child(5):before {
  content: "";
  width: 100%;
  background-image: url("img/tube-black.39c5a09c.webp");
  padding-bottom: 105%;
  display: block;
}

@media (max-width: 992px) {
  .section-3 .section_bg_img:nth-child(5) {
    width: 150px;
    top: 30px;
    left: unset;
    right: -26px;
  }
}

.section-3 .section_bg_img:nth-child(6) {
  width: 13%;
  top: 38%;
  left: 80%;
}

.section-3 .section_bg_img:nth-child(6):before {
  content: "";
  width: 100%;
  background-image: url("img/tube-green.d3220bdd.webp");
  padding-bottom: 226%;
  display: block;
}

@media (max-width: 992px) {
  .section-3 .section_bg_img:nth-child(6) {
    display: none;
  }
}

.section-3 .text {
  margin-bottom: 50px;
}

@media (max-width: 1600px) {
  .section-3 .video_wrapper {
    width: 90%;
    margin: 0 auto;
  }
}

.section-3 .video_inner {
  width: 100%;
  position: relative;
}

.section-3 .video_inner:after {
  content: "";
  width: 100%;
  padding-bottom: 56%;
  display: block;
}

.section-3 .video_inner .player {
  width: 100%;
  height: 100%;
  position: absolute;
  top: 0;
  left: 0;
}

.section-4 .header {
  margin-bottom: 50px;
}

.section-4 .section_content {
  height: unset;
}

.section-4 .video:hover {
  transform: scale(2);
}

.section-5 .header {
  margin-bottom: 50px;
}

.section-5 .section_content {
  height: unset;
}

.section-5 .cat, .section-5 .rocket, .section-5 .ufo {
  z-index: -1;
  position: absolute;
}

.section-5 .rocket {
  top: 0;
  left: 0;
  transform: translateX(-30%)translateY(-55%)rotate(50.84deg);
}

.section-5 .ufo {
  top: 0;
  right: 0;
  transform: translateX(50%)translateY(-70%)rotate(21.22deg);
}

.section-5 .cat {
  z-index: 1;
  top: 90%;
  right: 0;
  transform: translateX(50%)translateY(-20%)rotate(22.67deg);
}

@media (max-width: 1600px) {
  .section-5 .cat {
    max-width: 100px;
  }

  .section-5 .ufo, .section-5 .rocket {
    max-width: 150px;
  }
}

@media (max-width: 1200px) {
  .section-5 .video-outer {
    margin-bottom: 40px;
  }

  .section-5 .cat {
    display: none;
  }

  .section-5 .ufo {
    right: 30px;
    top: unset;
    bottom: -140px;
  }
}

@media (max-width: 576px) {
  .section-5 .rocket {
    top: -120px;
    left: 80%;
  }

  .section-5 .ufo {
    right: unset;
    bottom: -230px;
    left: 0;
    transform: translateX(0%)translateY(-70%)rotate(21.22deg);
  }
}

.section-5 .video-outer {
  transition: all .3s;
}

@media (min-width: 1200px) {
  .section-5 .col-xl-2 .video {
    border-radius: 30px;
    padding: 10px;
  }

  .section-5 .col-xl-2.video-outer {
    min-width: 281px;
  }

  .section-5 .col-xl-4.video-outer {
    min-width: 594px;
  }
}

@media (max-width: 1200px) {
  .section-5 .video_wrapper {
    width: 90%;
    margin: 0 auto;
  }
}

.section-5 .video_inner {
  width: 100%;
  position: relative;
}

.section-5 .video_inner:after {
  content: "";
  width: 100%;
  padding-bottom: 56%;
  display: block;
}

.section-5 .video_inner .player {
  width: 100%;
  height: 100%;
  position: absolute;
  top: 0;
  left: 0;
}

.section-6 {
  z-index: 2;
}

@media (max-width: 576px) {
  .section-6.section {
    padding-bottom: 0 !important;
  }
}

.section-6 .section_bg_img {
  position: absolute;
}

.section-6 .section_bg_img:before {
  background-repeat: no-repeat;
  background-size: contain;
}

.section-6 .section_bg_img:nth-child(1) {
  width: 8%;
  top: 20%;
  left: 81%;
}

.section-6 .section_bg_img:nth-child(1):before {
  content: "";
  width: 100%;
  background-image: url("img/asteroid-1.2628070c.webp");
  padding-bottom: 59%;
  display: block;
}

@media (max-width: 1600px) {
  .section-6 .section_bg_img:nth-child(1) {
    width: 100px;
    top: 50px;
    left: unset;
    right: 20px;
  }
}

@media (min-width: 992px) and (max-width: 1400px) {
  .section-6 .products .col-xl-2 {
    min-width: 240px;
  }
}

.section-6 .product {
  flex-direction: column;
  align-items: center;
  display: flex;
  position: relative;
}

.section-6 .product .owl-item {
  cursor: -webkit-zoom-in;
  cursor: zoom-in;
  padding-top: 20px;
}

.section-6 .product .owl-item img {
  cursor: -webkit-zoom-in;
  cursor: zoom-in;
  transition: all .3s;
}

.section-6 .product .owl-item:hover img {
  transform: translateY(-20px);
}

@media (max-width: 992px) {
  .section-6 .product {
    max-width: 300px;
    margin: auto;
  }
}

.section-6 .product_img {
  width: 100%;
  justify-content: center;
  align-items: center;
  transition: all .3s;
  display: flex;
  position: relative;
}

.section-6 .product_img img {
  max-width: 100%;
  margin: auto;
}

.section-6 .product_img .light {
  width: 100%;
  z-index: -1;
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
}

.section-6 .product_img .light:before {
  content: "";
  width: 100%;
  filter: blur(45px);
  background: #9edbef;
  border-radius: 50%;
  padding-bottom: 100%;
  display: block;
}

.section-6 .product_title {
  text-transform: uppercase;
  text-align: center;
  margin-top: 50px;
  margin-bottom: 13px;
  font-size: 24px;
  line-height: 28px;
}

@media (max-width: 576px) {
  .section-6 .product_title {
    font-size: 18px;
    line-height: 21px;
  }
}

.section-6 .product_info {
  width: 100%;
  margin-bottom: 21px;
  line-height: 21px;
  position: relative;
}

.section-6 .product_info_text {
  cursor: pointer;
  border-bottom: 2px dotted #fff;
  margin: auto;
  font-size: 18px;
  line-height: 21px;
}

.section-6 .product_info_text:after {
  content: "";
  width: 50px;
  height: 25px;
  background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='16' height='16' fill='white' class='bi bi-arrow-left' viewBox='0 0 16 16'%3E%3Cpath fill-rule='evenodd' d='M15 8a.5.5 0 0 0-.5-.5H2.707l3.147-3.146a.5.5 0 1 0-.708-.708l-4 4a.5.5 0 0 0 0 .708l4 4a.5.5 0 0 0 .708-.708L2.707 8.5H14.5A.5.5 0 0 0 15 8z'/%3E%3C/svg%3E");
  background-position: center;
  background-repeat: no-repeat;
  background-size: contain;
  animation: 2.5s cubic-bezier(.37, 0, .63, 1) infinite float-arrow;
  position: absolute;
}

@keyframes float-arrow {
  0%, 100% {
    transform: translateX(0%);
  }

  25% {
    transform: translateX(10%);
  }

  80% {
    transform: translateX(0%);
  }
}

.section-6 .product_info_btn {
  display: none;
  position: relative;
}

.section-6 .product_info ul {
  margin-bottom: 0;
  list-style: disc;
}

.section-6 .product_info ul li {
  white-space: nowrap;
}

.section-6 .product_info_text:hover ~ .product_info_popup, .section-6 .product_info_text:focus ~ .product_info_popup, .section-6 .product_info .product_info_popup:hover {
  display: block;
}

.section-6 .product_info_popup {
  color: #292456;
  width: max-content;
  z-index: 2;
  background-color: #fff;
  border-radius: 15px;
  padding: 15px 30px;
  font-size: 18px;
  line-height: 25px;
  display: none;
  position: absolute;
  bottom: 40px;
  left: 50%;
  right: 0;
  transform: translateX(-50%);
}

.section-6 .product_info_popup:after {
  content: "";
  width: 0;
  height: 0;
  z-index: 11;
  border-top: 20px solid #fff;
  border-left: 20px solid #0000;
  border-right: 20px solid #0000;
  position: absolute;
  bottom: -20px;
  left: 50%;
  transform: translateX(-50%);
}

.section-6 .product_info .close_btn {
  color: #292456;
  display: none;
  position: absolute;
  bottom: 8px;
  right: 0;
  transform: rotate(45deg);
}

.section-6 .product_info button {
  color: #fff;
  font-size: 28px;
}

.section-6 .product .buy_button_wrapper {
  position: relative;
}

.section-6 .product .buy_button_wrapper .btn.buy_button {
  z-index: 2;
  position: relative;
}

.section-6 .product .buy_button_wrapper .links {
  z-index: -1;
  flex-direction: column;
  align-items: flex-start;
  display: flex;
  position: absolute;
  top: 50%;
  left: calc(100% + 10px);
  transform: translateY(-50%);
}

@media (max-width: 1200px) {
  .section-6 .product .buy_button_wrapper .links {
    left: unset;
    top: unset;
    width: 100%;
    flex-direction: column;
    align-items: center;
    top: -80%;
  }
}

.section-6 .product .buy_button_wrapper .links a {
  visibility: hidden;
  opacity: 0;
  height: 36px;
  background-color: #93f2ff;
  border-radius: 25px;
  justify-content: center;
  align-items: center;
  margin-bottom: 10px;
  padding: 5px 15px;
  transition: opacity .3s cubic-bezier(.85, 0, .15, 1);
  display: flex;
  position: relative;
}

.section-6 .product .buy_button_wrapper .links a > img {
  display: block;
}

.section-6 .product .buy_button_wrapper .links a:first-child {
  transform: translateX(-30px);
}

@media (max-width: 1200px) {
  .section-6 .product .buy_button_wrapper .links a:first-child {
    transform: unset;
  }
}

.section-6 .product .buy_button_wrapper .links a:last-child {
  margin-bottom: 0;
  transform: translateX(-30px);
}

@media (max-width: 1200px) {
  .section-6 .product .buy_button_wrapper .links a:last-child {
    transform: unset;
  }
}

.section-6 .product .buy_button_wrapper .links a:nth-child(1) {
  transition-delay: .1s;
}

.section-6 .product .buy_button_wrapper .links a:nth-child(2) {
  transition-delay: .2s;
}

.section-6 .product .buy_button_wrapper .links a:nth-child(3) {
  transition-delay: .3s;
}

.section-6 .product .buy_button_wrapper .links a:nth-child(4) {
  transition-delay: .4s;
}

.section-6 .product .buy_button_wrapper .buy_button_overlay {
  z-index: 3;
  width: 100vw;
  height: 100vh;
  background-color: #000c;
  display: none;
  position: fixed;
  top: 0;
  right: 0;
}

.section-6 .product .buy_button_wrapper.opened .btn.buy_button, .section-6 .product .buy_button_wrapper.opened .links {
  z-index: 4;
}

.section-6 .product .buy_button_wrapper.opened .links a {
  visibility: visible;
  opacity: 1;
  transition: all .3s cubic-bezier(.85, 0, .15, 1);
}

.section-6 .product .buy_button_wrapper.opened .links a:nth-child(1) {
  transition-delay: .1s;
}

.section-6 .product .buy_button_wrapper.opened .links a:nth-child(2) {
  transition-delay: .2s;
}

.section-6 .product .buy_button_wrapper.opened .links a:nth-child(3) {
  transition-delay: .3s;
}

.section-6 .product .buy_button_wrapper.opened .links a:nth-child(4) {
  transition-delay: .4s;
}

@media (max-width: 1200px) {
  .section-6 .product .buy_button_wrapper.opened .buy_button_overlay {
    display: block;
  }
}

.section-6 .products .col-md-6:nth-child(2) .product_info_btn:after {
  animation-delay: .3s;
}

.section-6 .products .col-md-6:nth-child(3) .product_info_btn:after {
  animation-delay: .6s;
}

@media (max-width: 576px) {
  .section-7.section {
    padding-bottom: 0;
  }
}

.section-7 .section_bg_img {
  position: absolute;
}

.section-7 .section_bg_img:before {
  background-repeat: no-repeat;
  background-size: contain;
}

.section-7 .section_bg_img:nth-child(1) {
  width: 10%;
  top: 23%;
  left: 9%;
}

.section-7 .section_bg_img:nth-child(1):before {
  content: "";
  width: 100%;
  background-image: url("img/asteroid-2.0ddc791f.webp");
  padding-bottom: 61%;
  display: block;
}

@media (max-width: 1600px) {
  .section-7 .section_bg_img:nth-child(1) {
    width: 100px;
    top: 20px;
    left: 20px;
  }
}

.section-7 .text {
  margin-bottom: 50px;
}

.section-7 .blocks {
  max-width: 1180px;
  flex-direction: row;
  justify-content: center;
  margin: auto;
  display: flex;
}

@media (max-width: 1400px) {
  .section-7 .blocks {
    max-width: 768px;
    flex-wrap: wrap;
  }
}

@media (max-width: 768px) {
  .section-7 .blocks {
    flex-direction: column;
    align-items: center;
  }
}

.section-7 .block {
  flex: 100%;
  padding: 15px;
}

.section-7 .block:first-child {
  margin-left: 0;
}

@media (max-width: 1400px) {
  .section-7 .block {
    min-width: 280px;
    max-width: 280px;
  }
}

@media (max-width: 768px) {
  .section-7 .block {
    flex-direction: column;
    align-items: center;
    padding-bottom: 30px;
  }
}

.section-7 .block .block_img {
  height: 100px;
  transition: all .3s;
}

@media (max-width: 576px) {
  .section-7 .block_title {
    font-size: 18px;
    line-height: 21px;
  }

  .section-7 .block_text {
    font-size: 14px;
    line-height: 16px;
  }
}

.section-7 .block:hover .block_img {
  transform: translateY(-20px);
}

.section-8 .section_bg_img {
  position: absolute;
}

.section-8 .section_bg_img:before {
  background-repeat: no-repeat;
  background-size: contain;
}

.section-8 .section_bg_img:nth-child(1) {
  width: 12%;
  top: 76%;
  left: -1%;
}

.section-8 .section_bg_img:nth-child(1):before {
  content: "";
  width: 100%;
  background-image: url("img/planet-blue.9da710c3.webp");
  padding-bottom: 62%;
  display: block;
}

@media (max-width: 1600px) {
  .section-8 .section_bg_img:nth-child(1) {
    width: 150px;
    top: unset;
    bottom: 0;
    left: -10px;
  }
}

.section-8 .section_bg_img:nth-child(2) {
  width: 8%;
  top: 28%;
  left: 82%;
}

.section-8 .section_bg_img:nth-child(2):before {
  content: "";
  width: 100%;
  background-image: url("img/asteroid-3.0202988d.webp");
  padding-bottom: 56%;
  display: block;
}

@media (max-width: 1600px) {
  .section-8 .section_bg_img:nth-child(2) {
    width: 100px;
    top: 20px;
    left: unset;
    right: 50px;
  }
}

.section-8 .header {
  margin-bottom: 80px;
}

@media (max-width: 768px) {
  .section-8 .header {
    margin-bottom: 25px;
  }
}

.section-8 .carousel-container {
  min-width: 1124px;
  max-width: 1124px;
}

@media (max-width: 1200px) {
  .section-8 .carousel-container {
    min-width: 843px;
    max-width: 843px;
  }
}

@media (max-width: 1040px) {
  .section-8 .carousel-container {
    min-width: 562px;
    max-width: 562px;
  }
}

@media (max-width: 700px) {
  .section-8 .carousel-container {
    min-width: 281px;
    max-width: 281px;
  }
}

@media (max-width: 350px) {
  .section-8 .carousel-container {
    min-width: unset;
    max-width: 100%;
  }
}

.section-8 .owl-carousel.aqua-theme .owl-next, .section-8 .owl-carousel.aqua-theme .owl-prev {
  top: 127px !important;
}

.section-8 .owl-carousel.aqua-theme .owl-next {
  right: -60px !important;
}

.section-8 .owl-carousel.aqua-theme .owl-prev {
  left: -60px !important;
}

@media (max-width: 475px) {
  .section-8 .owl-carousel.aqua-theme .owl-next {
    right: -20px !important;
  }

  .section-8 .owl-carousel.aqua-theme .owl-prev {
    left: -20px !important;
  }
}

.section-8 .block_img {
  width: 100%;
  padding: 20px 20px 0;
}

.section-8 .block_img_inner {
  height: unset;
  border-radius: 50%;
  transition: all .3s;
  display: inline-block;
  position: relative;
  overflow: hidden;
  transform: scale(1);
  box-shadow: 0 4px 8px #0000001a;
}

.section-8 .block_img_inner:after {
  content: "";
  width: 54px;
  height: 54px;
  visibility: hidden;
  opacity: 0;
  z-index: 2;
  background-image: url("data:image/svg+xml,%3Csvg width='54' height='54' viewBox='0 0 54 54' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M35.2091 22.4673H27.4589V13.6663C27.4589 13.3773 27.2225 13.1408 26.9335 13.1408H22.9927C22.7037 13.1408 22.4673 13.3773 22.4673 13.6663V22.4673H14.7171C14.4281 22.4673 14.1917 22.7037 14.1917 22.9927V26.9335C14.1917 27.2225 14.4281 27.4589 14.7171 27.4589H22.4673V36.26C22.4673 36.549 22.7037 36.7854 22.9927 36.7854H26.9335C27.2225 36.7854 27.4589 36.549 27.4589 36.26V27.4589H35.2091C35.4981 27.4589 35.7345 27.2225 35.7345 26.9335V22.9927C35.7345 22.7037 35.4981 22.4673 35.2091 22.4673ZM53.8621 50.3154L44.2729 40.7262C52.2923 30.9465 51.7341 16.4576 42.5652 7.29534C32.8447 -2.43178 17.0553 -2.43178 7.29534 7.29534C-2.43178 17.0553 -2.43178 32.8447 7.29534 42.5652C16.4576 51.7341 30.9465 52.2923 40.7262 44.2729L50.3154 53.8621C50.5256 54.046 50.8605 54.046 51.0379 53.8621L53.8621 51.0379C54.046 50.8605 54.046 50.5256 53.8621 50.3154ZM39.0842 39.0842C31.2815 46.8803 18.6448 46.8803 10.842 39.0842C3.04589 31.2815 3.04589 18.6448 10.842 10.842C18.6448 3.04589 31.2815 3.04589 39.0842 10.842C46.8803 18.6448 46.8803 31.2815 39.0842 39.0842Z' fill='%23292456'/%3E%3C/svg%3E");
  transition: all .3s;
  display: block;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translateX(-50%)translateY(-50%);
}

.section-8 .block_img_inner:hover {
  cursor: pointer;
  transform: scale(1.05);
  box-shadow: 0 0 20px #00d1ffb3;
}

.section-8 .block_img_inner:hover:after {
  visibility: visible;
  opacity: 1;
}

.section-8 .block_title {
  margin-bottom: 30px;
}

@media (max-width: 576px) {
  .section-9.section {
    padding-top: 0 !important;
  }
}

.section-9 .section_bg_img {
  position: absolute;
}

.section-9 .section_bg_img:before {
  background-repeat: no-repeat;
  background-size: contain;
}

.section-9 .section_bg_img:nth-child(1) {
  width: 5%;
  top: 38%;
  left: 9%;
}

.section-9 .section_bg_img:nth-child(1):before {
  content: "";
  width: 100%;
  background-image: url("img/star-3.1e0f4170.webp");
  padding-bottom: 103%;
  display: block;
}

@media (max-width: 1600px) {
  .section-9 .section_bg_img:nth-child(1) {
    width: 50px;
    top: 30px;
    left: 20px;
  }
}

@media (max-width: 576px) {
  .section-9 .section_bg_img:nth-child(1) {
    display: none;
  }
}

.section-9 .section_bg_img:nth-child(2) {
  width: 16%;
  top: 66%;
  left: 85%;
}

.section-9 .section_bg_img:nth-child(2):before {
  content: "";
  width: 100%;
  background-image: url("img/planet-small.d8ab968f.webp");
  padding-bottom: 47%;
  display: block;
}

@media (max-width: 1600px) {
  .section-9 .section_bg_img:nth-child(2) {
    width: 150px;
    top: unset;
    left: unset;
    bottom: 0;
    right: -1px;
  }
}

.section-9 .carousel-container {
  max-width: 900px;
  min-width: 900px;
}

@media (max-width: 1040px) {
  .section-9 .carousel-container {
    max-width: 600px;
    min-width: 600px;
  }
}

@media (max-width: 768px) {
  .section-9 .carousel-container {
    max-width: 300px;
    min-width: 300px;
  }
}

@media (max-width: 350px) {
  .section-9 .carousel-container {
    max-width: 100%;
    min-width: unset;
  }
}

.section-9 .block_img {
  width: 100%;
  position: relative;
}

.section-9 .block_img:after {
  content: "";
  width: 80%;
  filter: blur(10px);
  z-index: -1;
  border-radius: 50%;
  padding-bottom: 80%;
  display: block;
  position: absolute;
  bottom: 0;
  left: 50%;
  transform: translateX(-50%);
}

.section-9 .block_img.captain:after {
  background-color: #5b99ff;
}

.section-9 .block_img.pilot:after {
  background-color: #f9b000;
}

.section-9 .block_img.cook:after {
  background-color: #ff3f3f;
}

.section-9 .block_img.doctor:after {
  background-color: #02e17b;
}

.section-9 .block_img.engineer:after {
  background-color: #fe0;
}

.section-9 .block_img.scientist:after {
  background-color: #b93dfa;
}

.section-9 .block_img img {
  animation: 3s ease-in-out infinite floating;
  transform: translateY(0);
}

.section-9 .row:nth-child(3) {
  margin-top: 50px;
}

.section-9 .owl-carousel.aqua-theme .owl-nav .owl-next {
  right: -60px;
}

.section-9 .owl-carousel.aqua-theme .owl-nav .owl-prev {
  left: -60px;
}

@media (max-width: 475px) {
  .section-9 .owl-carousel.aqua-theme .owl-nav .owl-next {
    right: -20px;
  }

  .section-9 .owl-carousel.aqua-theme .owl-nav .owl-prev {
    left: -20px;
  }
}

@media (max-width: 350px) {
  .section-9 .owl-carousel.aqua-theme .owl-nav .owl-next {
    right: -10px;
  }

  .section-9 .owl-carousel.aqua-theme .owl-nav .owl-prev {
    left: -10px;
  }
}

.section-10 .section_bg_img {
  position: absolute;
}

.section-10 .section_bg_img:before {
  background-repeat: no-repeat;
  background-size: contain;
}

.section-10 .section_bg_img:nth-child(1) {
  width: 4%;
  top: 74%;
  left: 8%;
}

.section-10 .section_bg_img:nth-child(1):before {
  content: "";
  width: 100%;
  background-image: url("img/star-1.d667b491.webp");
  padding-bottom: 101%;
  display: block;
}

.section-10 .section_bg_img:nth-child(2) {
  width: 17%;
  top: 10%;
  left: 10%;
}

.section-10 .section_bg_img:nth-child(2):before {
  content: "";
  width: 100%;
  background-image: url("img/rocket-2.fca06217.webp");
  padding-bottom: 87%;
  display: block;
}

@media (max-width: 992px) {
  .section-10 .section_bg_img:nth-child(2) {
    width: 80px;
    top: 30px;
  }
}

.section-10 .section_bg_img:nth-child(3) {
  width: 15%;
  bottom: 0%;
  left: 41%;
}

.section-10 .section_bg_img:nth-child(3):before {
  content: "";
  width: 100%;
  background-image: url("img/octopus.979a9b55.webp");
  padding-bottom: 81%;
  display: block;
}

@media (max-width: 576px) {
  .section-10 .section_bg_img:nth-child(3) {
    width: 100px;
  }
}

.section-10 .section_bg_img:nth-child(4) {
  width: 4%;
  top: 14%;
  left: 74%;
}

.section-10 .section_bg_img:nth-child(4):before {
  content: "";
  width: 100%;
  background-image: url("img/star-2.f90271d1.webp");
  padding-bottom: 99%;
  display: block;
}

@media (max-width: 576px) {
  .section-10 .section_bg_img:nth-child(4) {
    top: 80px;
  }
}

.section-10 .section_bg_img:nth-child(5) {
  width: 24%;
  top: 36%;
  left: 79%;
}

.section-10 .section_bg_img:nth-child(5):before {
  content: "";
  width: 100%;
  background-image: url("img/planet-big.a36c2443.webp");
  padding-bottom: 50%;
  display: block;
}

@media (max-width: 768px) {
  .section-10 .logo-link {
    margin-bottom: 30px;
    display: block;
  }

  .section-10 .logo-block:last-child a {
    margin-top: -20px;
  }
}

.section-10 .contacts {
  text-align: center;
  margin-top: 50px;
  margin-bottom: 70px;
}

.section-10 .contacts .tel {
  color: #fff;
  font-size: 36px;
  line-height: 43px;
  text-decoration: none;
}

.section-10 .contacts .tel-text {
  margin-bottom: 30px;
  font-size: 24px;
  line-height: 28px;
}

@media (max-width: 768px) {
  .section-10 .contacts .tel {
    font-size: 24px;
  }

  .section-10 .contacts .tel-text {
    font-size: 18px;
  }
}

.btn {
  color: #292456;
  text-transform: uppercase;
  border-radius: 25px;
  font-size: 24px;
  position: relative;
}

.btn:hover {
  color: #292456;
  text-decoration: none;
}

.btn.btn-blue {
  color: #fff;
  background: #292456;
  border-radius: 25px;
  padding: 2px 15px;
  box-shadow: 0 4px 8px #0000001a;
}

svg.liquid-button {
  filter: drop-shadow(0 4px 10px #00000040);
}

.block_img {
  width: 100px;
  margin: 0 auto 20px;
}

.block_img svg, .block_img img {
  max-width: 100%;
  height: auto;
  max-height: 100%;
}

.block_title {
  text-align: center;
  letter-spacing: .03em;
  text-transform: uppercase;
  margin-bottom: 10px;
  font-size: 24px;
  line-height: 29px;
}

.block_text {
  text-align: center;
  letter-spacing: .03em;
  font-size: 18px;
  line-height: 22px;
}

.video_wrapper .video_title {
  margin-top: 25px;
  font-size: 24px;
  line-height: 28px;
}

.video_wrapper .video {
  max-height: 100%;
  filter: drop-shadow(0 4px 8px #0000001a);
  background-color: #fff;
  border-radius: 40px;
  padding: 18px;
  overflow: hidden;
}

@media (max-width: 576px) {
  .video_wrapper .video {
    border-radius: 30px;
    padding: 10px;
  }
}

.video_wrapper .video_inner {
  filter: drop-shadow(0 4px 8px #0000001a);
  border: 6px solid #292456;
  border-radius: 20px;
  justify-content: center;
  align-items: center;
  display: flex;
  position: relative;
  overflow: hidden;
}

.video_wrapper .video .play-btn {
  z-index: 1;
  width: 20%;
  z-index: 1;
  background-color: #ffffff80;
  border-radius: 50%;
  transition: all .5s;
  display: inline-block;
  position: absolute;
}

.video_wrapper .video .play-btn max-width :after {
  content: "";
  padding-bottom: 100%;
}

.video_wrapper .video .play-btn svg {
  width: 100%;
  height: 100%;
}

.video_wrapper .video .play-btn .circle {
  stroke: #292456;
  stroke-dasharray: 650;
  stroke-dashoffset: 650px;
  opacity: .3;
  -webkit-transition: all .5s ease-in-out;
}

.video_wrapper .video .play-btn .triangle {
  stroke-dasharray: 240;
  stroke-dashoffset: 480px;
  stroke: #292456;
  transition: all .7s ease-in-out;
  transform: translateY(0);
}

.video_wrapper .video .play-btn:hover {
  cursor: pointer;
}

.video_wrapper .video .play-btn:hover .triangle {
  stroke-dashoffset: 0;
  opacity: 1;
  stroke: #292456;
  animation: .7s ease-in-out nudge;
}

@keyframes nudge {
  0% {
    transform: translateX(0);
  }

  30% {
    transform: translateX(-5px);
  }

  50% {
    transform: translateX(5px);
  }

  70% {
    transform: translateX(-2px);
  }

  100% {
    transform: translateX(0);
  }
}

.video_wrapper .video .play-btn:hover .circle {
  stroke-dashoffset: 0;
  opacity: 1;
}

.modal.aqua .modal-content {
  background: #d8f1ff;
  border: 11px solid #292456;
  border-radius: 23px;
  padding: 50px;
  box-shadow: 0 4px 15px #00000040;
}

.modal.aqua .modal-content_img {
  filter: drop-shadow(0 4px 15px #00000040);
}

.modal.aqua .modal-content_img img {
  max-width: 100%;
}

.modal.aqua .modal-content .close {
  padding: 0;
  font-size: 35px;
  font-weight: 600;
  line-height: 20px;
  position: absolute;
  top: 20px;
  right: 20px;
  overflow: hidden;
}

.modal.aqua .modal-content .close span {
  font-size: 35px;
  line-height: 20px;
  display: inline-block;
}

#contact-form .modal-content {
  background-color: #fff;
}

#contact-form input, #contact-form textarea {
  max-width: 100%;
  width: 100%;
  border: none;
  border-bottom: 2px solid #292456;
  font-size: 18px;
}

#contact-form input:focus, #contact-form textarea:focus {
  border: none;
  border-bottom: 2px solid #292456;
  outline: none;
}

#contact-form input {
  margin-bottom: 20px;
}

#contact-form input:last-child {
  margin-bottom: 0;
}

#contact-form textarea {
  height: 100%;
}

#contact-form .form-footer {
  margin-top: 20px;
}

#contact-form .checkbox {
  color: #292456;
  margin-bottom: 0;
  font-size: 12px;
}

#contact-form .checkbox input[type="checkbox"] {
  display: none;
}

#contact-form .checkbox input[type="checkbox"]:checked + .checkbox-icon:after {
  display: block;
}

@media (max-width: 768px) {
  #contact-form .checkbox {
    margin-bottom: 16px;
  }
}

#contact-form .checkbox .checkbox-icon {
  min-width: 26px;
  min-height: 26px;
  box-sizing: border-box;
  border: 3px solid #292456;
  border-radius: 50%;
  margin-right: 10px;
  position: relative;
}

#contact-form .checkbox .checkbox-icon:after {
  content: "";
  width: 14px;
  height: 14px;
  background-color: #292456;
  border-radius: 50%;
  display: none;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translateX(-50%)translateY(-50%);
}

.owl-carousel.aqua-theme .owl-nav .owl-next, .owl-carousel.aqua-theme .owl-nav .owl-prev {
  width: 26px;
  height: 30px;
  filter: drop-shadow(0 4px 10px #0000001a);
  background-image: url("data:image/svg+xml,%3Csvg width='26' height='30' viewBox='0 0 26 30' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M24 11.5359C26.6667 13.0755 26.6667 16.9245 24 18.4641L6.00001 28.8564C3.33334 30.396 4.38926e-06 28.4715 3.98547e-06 25.3923L1.2599e-06 4.6077C8.56109e-07 1.5285 3.33333 -0.396005 6 1.1436L24 11.5359Z' fill='white'/%3E%3C/svg%3E");
  background-position: center;
  background-repeat: no-repeat;
  background-size: contain;
  position: absolute;
  top: calc(50% - 10px);
}

.owl-carousel.aqua-theme .owl-nav .owl-next:after, .owl-carousel.aqua-theme .owl-nav .owl-prev:after {
  content: "";
  width: 18px;
  height: 20px;
  filter: drop-shadow(0 4px 10px #0000001a);
  visibility: hidden;
  opacity: 0;
  background-image: url("data:image/svg+xml,%3Csvg width='18' height='20' viewBox='0 0 18 20' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M17 8.26795C18.3333 9.03775 18.3333 10.9622 17 11.7321L3.5 19.5263C2.16667 20.2961 0.500001 19.3338 0.500001 17.7942L0.499999 2.20577C0.499999 0.666172 2.16666 -0.296078 3.5 0.473722L17 8.26795Z' fill='%23FFDB00'/%3E%3C/svg%3E");
  background-position: center;
  background-repeat: no-repeat;
  background-size: contain;
  transition: all .3s;
  display: block;
  position: absolute;
  top: 50%;
  left: 44%;
  transform: translateX(-50%)translateY(-50%);
}

.owl-carousel.aqua-theme .owl-nav .owl-next:hover:after, .owl-carousel.aqua-theme .owl-nav .owl-prev:hover:after {
  visibility: visible;
  opacity: 1;
}

.owl-carousel.aqua-theme .owl-nav .owl-next {
  right: -26px;
}

.owl-carousel.aqua-theme .owl-nav .owl-prev {
  left: -26px;
  transform: rotate(180deg);
}

.owl-carousel.aqua-theme .owl-nav .owl-prev:after {
  left: 48%;
}

.test-container {
  min-height: 400px;
  position: relative;
}

.test {
  width: 100%;
  display: none;
  position: absolute;
  top: 0;
  left: 0;
}

@media (max-width: 768px) {
  .test .navigation {
    max-width: 300px;
  }
}

.test .navigation .info {
  margin: 0 140px;
  font-size: 24px;
}

@media (max-width: 768px) {
  .test .navigation .info {
    margin: 0 40px;
  }
}

.test .navigation .right, .test .navigation .left {
  width: 26px;
  height: 30px;
  filter: drop-shadow(0 4px 10px #0000001a);
  background-image: url("data:image/svg+xml,%3Csvg width='26' height='30' viewBox='0 0 26 30' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M24 11.5359C26.6667 13.0755 26.6667 16.9245 24 18.4641L6.00001 28.8564C3.33334 30.396 4.38926e-06 28.4715 3.98547e-06 25.3923L1.2599e-06 4.6077C8.56109e-07 1.5285 3.33333 -0.396005 6 1.1436L24 11.5359Z' fill='white'/%3E%3C/svg%3E");
  background-position: center;
  background-repeat: no-repeat;
  background-size: contain;
  padding: 0;
}

.test .navigation .right:after, .test .navigation .left:after {
  content: "";
  width: 18px;
  height: 20px;
  filter: drop-shadow(0 4px 10px #0000001a);
  visibility: hidden;
  opacity: 0;
  background-image: url("data:image/svg+xml,%3Csvg width='18' height='20' viewBox='0 0 18 20' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M17 8.26795C18.3333 9.03775 18.3333 10.9622 17 11.7321L3.5 19.5263C2.16667 20.2961 0.500001 19.3338 0.500001 17.7942L0.499999 2.20577C0.499999 0.666172 2.16666 -0.296078 3.5 0.473722L17 8.26795Z' fill='%23FFDB00'/%3E%3C/svg%3E");
  background-position: center;
  background-repeat: no-repeat;
  background-size: contain;
  transition: all .3s;
  display: block;
  position: absolute;
  top: 50%;
  left: 44%;
  transform: translateX(-50%)translateY(-50%);
}

.test .navigation .right:hover, .test .navigation .left:hover {
  cursor: pointer;
}

.test .navigation .right:hover:after, .test .navigation .left:hover:after {
  visibility: visible;
  opacity: 1;
}

.test .navigation .right:disabled, .test .navigation .left:disabled {
  cursor: not-allowed;
  opacity: .5;
}

.test .navigation .left {
  transform-origin: center;
  transform: rotate(180deg);
}

.test .navigation .left:after {
  left: 45%;
}

.test-result {
  width: 100%;
  height: 100%;
  display: none;
  top: 0;
  left: 0;
}

.test-result__container {
  max-width: 1040px;
  margin-left: auto;
  margin-right: auto;
  padding: 0 50px;
}

@media (max-width: 768px) {
  .test-result__container {
    padding: 0;
  }
}

.test-result .res-name {
  text-transform: uppercase;
  max-width: 170px;
  text-align: center;
  font-size: 24px;
}

@media (max-width: 768) {
  .test-result .res-name {
    margin-bottom: 30px;
  }
}

.test-result .res-slogan, .test-result .res-text {
  font-size: 18px;
  line-height: normal;
}

.test-result .res-slogan {
  text-transform: uppercase;
  margin-bottom: 30px;
}

@media (min-width: 769px) {
  .test-result .owl-carousel.aqua-theme .owl-stage {
    justify-content: center;
    align-items: center;
    display: flex;
  }
}

.test-result .owl-carousel.aqua-theme .owl-next {
  right: -30px !important;
}

.test-result .owl-carousel.aqua-theme .owl-prev {
  left: -30px !important;
}

@media (max-width: 768px) {
  .test-result .owl-carousel.aqua-theme .owl-next, .test-result .owl-carousel.aqua-theme .owl-prev {
    top: 167px;
  }

  .test-result .owl-carousel.aqua-theme .owl-next {
    right: -10px !important;
  }

  .test-result .owl-carousel.aqua-theme .owl-prev {
    left: -10px !important;
  }
}

.test-result .owl-carousel.aqua-theme {
  visibility: hidden;
  opacity: 0;
  transition: all 2s;
}

.test-result.show {
  display: block;
}

.test-result.show .owl-carousel.aqua-theme {
  visibility: visible;
  opacity: 1;
}

.progress-bar-wrapper {
  width: 100%;
  height: 35px;
  background-color: #fff;
  border-radius: 22px;
  margin-bottom: 40px;
  position: relative;
  overflow: hidden;
}

@media (max-width: 1200px) {
  .progress-bar-wrapper {
    margin-top: 40px;
  }
}

@media (max-width: 768px) {
  .progress-bar-wrapper {
    height: 26px;
    max-width: 300px;
  }
}

.progress-bar-wrapper .progress-bar {
  width: 0%;
  height: 100%;
  z-index: 1;
  background-color: #50c2f0;
  border-radius: 22px;
  transition: all .3s;
  position: absolute;
  top: 0;
  left: 0;
}

.steps {
  position: relative;
}

.steps .step {
  visibility: hidden;
  opacity: 0;
  z-index: -1;
  transition: all .5s cubic-bezier(.87, 0, .13, 1);
  position: absolute;
  transform: translateX(100%);
}

.steps .step.active {
  visibility: visible;
  opacity: 1;
  z-index: 0;
  position: relative;
  transform: translateX(0%);
}

.steps .step.prev {
  transform: translateX(-100%);
}

.steps .step-number {
  margin-bottom: 25px;
  font-size: 24px;
  line-height: 1;
}

@media (max-width: 768px) {
  .steps .step-number {
    font-size: 14px;
  }
}

.steps .question {
  text-align: center;
  min-height: 75px;
  margin-bottom: 40px;
  font-size: 36px;
  line-height: 1;
}

@media (max-width: 768px) {
  .steps .question {
    min-height: 50px;
    font-size: 24px;
  }
}

.answers-wrapper {
  margin-bottom: 40px;
}

.answers-wrapper .answers {
  position: relative;
}

.answers-wrapper .answers:before {
  content: "согласен";
  color: #42ff00;
  font-size: 24px;
  position: absolute;
  top: 50%;
  left: -50px;
  transform: translateX(-100%)translateY(-50%);
}

@media (max-width: 1200px) {
  .answers-wrapper .answers:before {
    transform: unset;
    top: calc(100% + 20px);
    left: 0;
  }
}

@media (max-width: 768px) {
  .answers-wrapper .answers:before {
    font-size: 14px;
  }
}

.answers-wrapper .answers:after {
  content: "не согласен";
  color: red;
  font-size: 24px;
  position: absolute;
  top: 50%;
  right: -50px;
  transform: translateX(100%)translateY(-50%);
}

@media (max-width: 1200px) {
  .answers-wrapper .answers:after {
    transform: unset;
    top: calc(100% + 20px);
    right: 0;
  }
}

@media (max-width: 768px) {
  .answers-wrapper .answers:after {
    font-size: 14px;
  }
}

.answers-wrapper .answer {
  border-radius: 50%;
  margin-right: 40px;
  position: relative;
  overflow: hidden;
}

@media (max-width: 768px) {
  .answers-wrapper .answer {
    margin-right: 10px;
  }
}

.answers-wrapper .answer:after {
  content: "";
  width: 70%;
  height: 70%;
  visibility: hidden;
  opacity: 0;
  background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='16' height='16' fill='currentColor' class='bi bi-check' viewBox='0 0 16 16'%3E%3Cpath fill='white' d='M10.97 4.97a.75.75 0 0 1 1.07 1.05l-3.99 4.99a.75.75 0 0 1-1.08.02L4.324 8.384a.75.75 0 1 1 1.06-1.06l2.094 2.093 3.473-4.425a.267.267 0 0 1 .02-.022z'/%3E%3C/svg%3E");
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  display: block;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translateX(-50%)translateY(-50%);
}

.answers-wrapper .answer.checked:after {
  visibility: visible;
  opacity: 1;
}

.answers-wrapper .answer:last-child {
  margin-right: 0;
}

.answers-wrapper .answer :hover {
  cursor: pointer;
}

.answers-wrapper .answer svg {
  width: 100%;
  height: 100%;
}

.answers-wrapper .answer.max {
  width: 100px;
  height: 100px;
  min-width: 100px;
  min-height: 100px;
}

@media (max-width: 768px) {
  .answers-wrapper .answer.max {
    width: 55px;
    height: 55px;
    min-width: 55px;
    min-height: 55px;
  }
}

@media (max-width: 375px) {
  .answers-wrapper .answer.max {
    width: 40px;
    height: 40px;
    min-width: 40px;
    min-height: 40px;
  }
}

.answers-wrapper .answer.max.positive:hover, .answers-wrapper .answer.max.positive.checked {
  background: linear-gradient(to right, #42ff00 .74%, gold 333.55%);
}

.answers-wrapper .answer.max.negative:hover, .answers-wrapper .answer.max.negative.checked {
  background: linear-gradient(to left, red .01%, gold 311.6%);
}

.answers-wrapper .answer.mid {
  width: 70px;
  height: 70px;
  min-width: 70px;
  min-height: 70px;
}

@media (max-width: 768px) {
  .answers-wrapper .answer.mid {
    width: 40px;
    height: 40px;
    min-width: 40px;
    min-height: 40px;
  }
}

@media (max-width: 375px) {
  .answers-wrapper .answer.mid {
    width: 35px;
    height: 35px;
    min-width: 35px;
    min-height: 35px;
  }
}

.answers-wrapper .answer.mid.positive:hover, .answers-wrapper .answer.mid.positive.checked {
  background: linear-gradient(to right, #42ff00 -167.91%, gold 432.09%);
}

.answers-wrapper .answer.mid.negative:hover, .answers-wrapper .answer.mid.negative.checked {
  background: linear-gradient(to left, red -194.74%, gold 244.56%);
}

.answers-wrapper .answer.min {
  width: 60px;
  height: 60px;
  min-width: 60px;
  min-height: 60px;
}

@media (max-width: 768px) {
  .answers-wrapper .answer.min {
    width: 35px;
    height: 35px;
    min-width: 35px;
    min-height: 35px;
  }
}

@media (max-width: 375px) {
  .answers-wrapper .answer.min {
    width: 28px;
    height: 28px;
    min-width: 28px;
    min-height: 28px;
  }
}

.answers-wrapper .answer.min.positive:hover, .answers-wrapper .answer.min.positive.checked {
  background: linear-gradient(to right, #42ff00 -341.38%, gold 224.14%);
}

.answers-wrapper .answer.min.negative:hover, .answers-wrapper .answer.min.negative.checked {
  background: linear-gradient(to left, red -407.53%, gold 99.98%);
}

.answers-wrapper .answer.neutral {
  width: 50px;
  height: 50px;
  min-width: 50px;
  min-height: 50px;
}

@media (max-width: 768px) {
  .answers-wrapper .answer.neutral {
    width: 30px;
    height: 30px;
    min-width: 30px;
    min-height: 30px;
  }
}

@media (max-width: 375px) {
  .answers-wrapper .answer.neutral {
    width: 25px;
    height: 25px;
    min-width: 25px;
    min-height: 25px;
  }
}

.answers-wrapper .answer.neutral:hover, .answers-wrapper .answer.neutral.checked {
  background: linear-gradient(to right, #42ff00 -680.77%, gold 100%);
}

.cta-btn {
  color: #fff;
  z-index: 50;
  filter: drop-shadow(0 -4px 8px #0000001a);
  text-transform: uppercase;
  background-color: #ff0072;
  border-top-left-radius: 10px;
  padding: 8px 18px;
  font-size: 18px;
  transition: all .3s;
  position: fixed;
  bottom: 0;
  right: 0;
}

.cta-btn:hover {
  color: #292456;
  background-color: #ffdb00;
  text-decoration: none;
}

@media (min-width: 450px) {
  .cta-btn {
    display: none;
  }
}

.owl-carousel {
  width: 100%;
  -webkit-tap-highlight-color: transparent;
  z-index: 1;
  display: none;
  position: relative;
}

.owl-carousel .owl-stage {
  -ms-touch-action: pan-Y;
  touch-action: manipulation;
  -moz-backface-visibility: hidden;
  position: relative;
}

.owl-carousel .owl-stage:after {
  content: ".";
  clear: both;
  visibility: hidden;
  height: 0;
  line-height: 0;
  display: block;
}

.owl-carousel .owl-stage-outer {
  position: relative;
  overflow: hidden;
  -webkit-transform: translate3d(0, 0, 0);
}

.owl-carousel .owl-wrapper, .owl-carousel .owl-item {
  -webkit-backface-visibility: hidden;
  -moz-backface-visibility: hidden;
  -ms-backface-visibility: hidden;
  -webkit-transform: translate3d(0, 0, 0);
  -moz-transform: translate3d(0, 0, 0);
  -ms-transform: translate3d(0, 0, 0);
}

.owl-carousel .owl-item {
  min-height: 1px;
  float: left;
  -webkit-backface-visibility: hidden;
  -webkit-tap-highlight-color: transparent;
  -webkit-touch-callout: none;
  position: relative;
}

.owl-carousel .owl-item img {
  width: 100%;
  display: block;
}

.owl-carousel .owl-nav.disabled, .owl-carousel .owl-dots.disabled {
  display: none;
}

.owl-carousel .owl-nav .owl-prev, .owl-carousel .owl-nav .owl-next, .owl-carousel .owl-dot {
  cursor: pointer;
  -webkit-user-select: none;
  user-select: none;
  -khtml-user-select: none;
}

.owl-carousel .owl-nav button.owl-prev, .owl-carousel .owl-nav button.owl-next, .owl-carousel button.owl-dot {
  color: inherit;
  font: inherit;
  background: none;
  border: none;
  padding: 0 !important;
}

.owl-carousel.owl-loaded {
  display: block;
}

.owl-carousel.owl-loading {
  opacity: 0;
  display: block;
}

.owl-carousel.owl-hidden {
  opacity: 0;
}

.owl-carousel.owl-refresh .owl-item {
  visibility: hidden;
}

.owl-carousel.owl-drag .owl-item {
  -ms-touch-action: pan-y;
  touch-action: pan-y;
  -webkit-user-select: none;
  user-select: none;
}

.owl-carousel.owl-grab {
  cursor: move;
  cursor: grab;
}

.owl-carousel.owl-rtl {
  direction: rtl;
}

.owl-carousel.owl-rtl .owl-item {
  float: right;
}

.no-js .owl-carousel {
  display: block;
}

.owl-carousel .animated {
  animation-duration: 1s;
  animation-fill-mode: both;
}

.owl-carousel .owl-animated-in {
  z-index: 0;
}

.owl-carousel .owl-animated-out {
  z-index: 1;
}

.owl-carousel .fadeOut {
  animation-name: fadeOut;
}

@keyframes fadeOut {
  0% {
    opacity: 1;
  }

  100% {
    opacity: 0;
  }
}

.owl-height {
  transition: height .5s ease-in-out;
}

.owl-carousel .owl-item .owl-lazy {
  opacity: 0;
  transition: opacity .4s;
}

.owl-carousel .owl-item .owl-lazy[src^=""], .owl-carousel .owl-item .owl-lazy:not([src]) {
  max-height: 0;
}

.owl-carousel .owl-item img.owl-lazy {
  transform-style: preserve-3d;
}

.owl-carousel .owl-video-wrapper {
  height: 100%;
  background: #000;
  position: relative;
}

.owl-carousel .owl-video-play-icon {
  height: 80px;
  width: 80px;
  cursor: pointer;
  z-index: 1;
  -webkit-backface-visibility: hidden;
  background: url("up_/node_modules/owl.carousel/dist/assets/owl.video.play.e7a23fb2.png") no-repeat;
  margin-top: -40px;
  margin-left: -40px;
  transition: transform .1s;
  position: absolute;
  top: 50%;
  left: 50%;
}

.owl-carousel .owl-video-play-icon:hover {
  transform: scale(1.3);
}

.owl-carousel .owl-video-playing .owl-video-tn, .owl-carousel .owl-video-playing .owl-video-play-icon {
  display: none;
}

.owl-carousel .owl-video-tn {
  opacity: 0;
  height: 100%;
  background-position: center;
  background-repeat: no-repeat;
  background-size: contain;
  transition: opacity .4s;
}

.owl-carousel .owl-video-frame {
  z-index: 1;
  height: 100%;
  width: 100%;
  position: relative;
}

.not-selectable {
  -webkit-user-select: none;
  user-select: none;
}

.carousel {
  box-sizing: border-box;
  position: relative;
}

.carousel *, .carousel :before, .carousel :after {
  box-sizing: inherit;
}

.carousel__viewport {
  width: 100%;
  height: 100%;
  position: relative;
  overflow: hidden;
}

.carousel__track {
  display: flex;
}

.carousel__slide {
  width: var(--carousel-slide-width, 60%);
  max-width: 100%;
  overscroll-behavior: contain;
  -webkit-overflow-scrolling: touch;
  touch-action: pan-y;
  flex: none;
  padding: 1rem;
  position: relative;
  overflow-x: hidden;
  overflow-y: auto;
}

.carousel.has-dots {
  margin-bottom: calc(.5rem + 22px);
}

.carousel__dots {
  -webkit-user-select: none;
  user-select: none;
  justify-content: center;
  margin: 0 auto;
  padding: 0;
  list-style: none;
  display: flex;
  position: absolute;
  top: calc(100% + .5rem);
  left: 0;
  right: 0;
}

.carousel__dots .carousel__dot {
  width: 22px;
  height: 22px;
  cursor: pointer;
  margin: 0;
  padding: 0;
  display: block;
  position: relative;
}

.carousel__dots .carousel__dot:after {
  content: "";
  width: 8px;
  height: 8px;
  opacity: .25;
  background-color: currentColor;
  border-radius: 50%;
  transition: opacity .15s ease-in-out;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}

.carousel__dots .carousel__dot.is-selected:after {
  opacity: 1;
}

.carousel__button {
  width: var(--carousel-button-width, 48px);
  height: var(--carousel-button-height, 48px);
  pointer-events: all;
  cursor: pointer;
  color: var(--carousel-button-color, currentColor);
  background: var(--carousel-button-bg, transparent);
  border-radius: var(--carousel-button-border-radius, 50%);
  box-shadow: var(--carousel-button-shadow, none);
  border: 0;
  justify-content: center;
  align-items: center;
  padding: 0;
  display: flex;
}

.carousel__button.is-prev, .carousel__button.is-next {
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
}

.carousel__button.is-prev {
  left: 10px;
}

.carousel__button.is-next {
  right: 10px;
}

.carousel__button[disabled] {
  cursor: default;
  opacity: .3;
}

.carousel__button svg {
  width: var(--carousel-button-svg-width, 24px);
  height: var(--carousel-button-svg-height, 24px);
  fill: none;
  stroke: currentColor;
  stroke-width: var(--carousel-button-svg-stroke-width, 1.5);
  stroke-linejoin: bevel;
  stroke-linecap: round;
  filter: var(--carousel-button-svg-filter, none);
  pointer-events: none;
}

body.compensate-for-scrollbar {
  touch-action: none;
  overflow: hidden !important;
}

.fancybox__container {
  padding: env(safe-area-inset-top, 0px) env(safe-area-inset-right, 0px) env(safe-area-inset-bottom, 0px) env(safe-area-inset-left, 0px);
  box-sizing: border-box;
  color: var(--fancybox-color, #fff);
  -webkit-tap-highlight-color: transparent;
  z-index: 1050;
  transform-origin: 0 0;
  --carousel-button-width: 48px;
  --carousel-button-height: 48px;
  --carousel-button-svg-width: 24px;
  --carousel-button-svg-height: 24px;
  --carousel-button-svg-stroke-width: 2.5;
  --carousel-button-svg-filter: drop-shadow(1px 1px 1px #0006);
  outline: none;
  flex-direction: column;
  margin: 0;
  display: flex;
  position: fixed;
  inset: 0;
  overflow: hidden;
}

.fancybox__container *, .fancybox__container :before, .fancybox__container :after {
  box-sizing: inherit;
}

.fancybox__container :focus {
  outline: thin dotted;
}

body.is-using-mouse .fancybox__container :focus {
  outline: none;
}

@media (min-width: 1024px) {
  .fancybox__container {
    --carousel-button-svg-width: 27px;
    --carousel-button-svg-height: 27px;
  }
}

.fancybox__backdrop {
  z-index: -1;
  background: var(--fancybox-bg, #18181beb);
  position: absolute;
  inset: 0;
}

.fancybox__carousel {
  min-height: 0;
  height: 100%;
  z-index: 10;
  flex: auto;
  position: relative;
}

.fancybox__carousel.has-dots {
  margin-bottom: calc(.5rem + 22px);
}

.fancybox__viewport {
  width: 100%;
  height: 100%;
  cursor: default;
  position: relative;
  overflow: visible;
}

.fancybox__track {
  height: 100%;
  display: flex;
}

.fancybox__slide {
  width: 100%;
  max-width: 100%;
  overscroll-behavior: contain;
  -webkit-overflow-scrolling: touch;
  --carousel-button-width: 36px;
  --carousel-button-height: 36px;
  --carousel-button-svg-width: 22px;
  --carousel-button-svg-height: 22px;
  outline: 0;
  flex-direction: column;
  flex: none;
  margin: 0;
  padding: 64px;
  display: flex;
  position: relative;
  overflow: auto;
}

.fancybox__slide:before, .fancybox__slide:after {
  content: "";
  flex: 0 0 0;
  margin: auto;
}

@media (min-width: 1024px) {
  .fancybox__slide {
    padding: 64px 100px;
  }
}

.fancybox__content {
  margin: 0 env(safe-area-inset-right, 0px) 0 env(safe-area-inset-left, 0px);
  color: var(--fancybox-content-color, #374151);
  background: var(--fancybox-content-bg, #fff);
  z-index: 20;
  flex-direction: column;
  align-self: center;
  padding: 36px;
  display: flex;
  position: relative;
}

.fancybox__caption {
  max-width: 100%;
  color: var(--fancybox-color, currentColor);
  visibility: visible;
  cursor: auto;
  flex-shrink: 0;
  align-self: center;
  margin: 0;
  padding: 1rem 0 0;
  line-height: 1.375;
}

.is-loading .fancybox__caption {
  visibility: hidden;
}

.fancybox__container > .carousel__dots {
  color: var(--fancybox-color, #fff);
  top: 100%;
}

.fancybox__nav .carousel__button {
  z-index: 40;
}

.fancybox__nav .carousel__button.is-next {
  right: 8px;
}

@media (min-width: 1024px) {
  .fancybox__nav .carousel__button.is-next {
    right: 40px;
  }
}

.fancybox__nav .carousel__button.is-prev {
  left: 8px;
}

@media (min-width: 1024px) {
  .fancybox__nav .carousel__button.is-prev {
    left: 40px;
  }
}

.carousel__button.is-close {
  top: 8px;
  right: 8px;
  top: calc(env(safe-area-inset-top, 0px)  + 8px);
  right: calc(env(safe-area-inset-right, 0px)  + 8px);
  z-index: 40;
  position: absolute;
}

@media (min-width: 1024px) {
  .carousel__button.is-close {
    right: 40px;
  }
}

.fancybox__content > .carousel__button.is-close {
  color: var(--fancybox-color, #fff);
  position: absolute;
  top: -36px;
  right: 0;
}

.fancybox__spinner {
  width: 50px;
  height: 50px;
  cursor: pointer;
  z-index: 1053;
  color: var(--fancybox-color, currentColor);
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}

.fancybox__spinner svg {
  transform-origin: center;
  width: 100%;
  height: 100%;
  margin: auto;
  animation: 2s linear infinite fancybox-rotate;
  position: absolute;
  inset: 0;
}

.fancybox__spinner svg circle {
  fill: none;
  stroke-width: 2.5px;
  stroke-miterlimit: 10;
  stroke-dasharray: 1 200;
  stroke-dashoffset: 0;
  stroke-linecap: round;
  stroke: currentColor;
  animation: 1.5s ease-in-out infinite fancybox-dash;
}

@keyframes fancybox-rotate {
  100% {
    transform: rotate(360deg);
  }
}

@keyframes fancybox-dash {
  0% {
    stroke-dasharray: 1 200;
    stroke-dashoffset: 0;
  }

  50% {
    stroke-dasharray: 89 200;
    stroke-dashoffset: -35px;
  }

  100% {
    stroke-dasharray: 89 200;
    stroke-dashoffset: -124px;
  }
}

.fancybox__container.is-animated[aria-hidden="false"] .fancybox__backdrop, .fancybox__container.is-animated[aria-hidden="false"] .fancybox__caption, .fancybox__container.is-animated[aria-hidden="false"] .fancybox__nav, .fancybox__container.is-animated[aria-hidden="false"] .carousel__dots, .fancybox__container.is-animated[aria-hidden="false"] .carousel__button.is-close {
  transition: opacity var(--fancybox-ts, .25s) ease;
  opacity: var(--fancybox-opacity, 1);
}

.fancybox__container.is-animated[aria-hidden="true"] .fancybox__backdrop, .fancybox__container.is-animated[aria-hidden="true"] .fancybox__caption, .fancybox__container.is-animated[aria-hidden="true"] .fancybox__nav, .fancybox__container.is-animated[aria-hidden="true"] .carousel__dots, .fancybox__container.is-animated[aria-hidden="true"] .carousel__button.is-close {
  opacity: 0;
  transition: opacity .2s;
}

.fancybox-fadeIn {
  animation: .2s both fancybox-fadeIn;
}

.fancybox-fadeOut {
  animation: .2s both fancybox-fadeOut;
}

.fancybox-zoomInUp {
  animation: .2s both fancybox-zoomInUp;
}

.fancybox-zoomOutDown {
  animation: .2s both fancybox-zoomOutDown;
}

.fancybox-throwOutUp {
  animation: .2s both fancybox-throwOutUp;
}

.fancybox-throwOutDown {
  animation: .2s both fancybox-throwOutDown;
}

@keyframes fancybox-fadeIn {
  from {
    opacity: 0;
  }

  to {
    opacity: 1;
  }
}

@keyframes fancybox-fadeOut {
  to {
    opacity: 0;
  }
}

@keyframes fancybox-zoomInUp {
  from {
    opacity: 0;
    transform: scale(.97)translate3d(0, 16px, 0);
  }

  to {
    opacity: 1;
    transform: scale(1)translate3d(0, 0, 0);
  }
}

@keyframes fancybox-zoomOutDown {
  to {
    opacity: 0;
    transform: scale(.97)translate3d(0, 16px, 0);
  }
}

@keyframes fancybox-throwOutUp {
  20% {
    opacity: .5;
  }

  to {
    opacity: 0;
    transform: translate3d(0, -30%, 0);
  }
}

@keyframes fancybox-throwOutDown {
  20% {
    opacity: .5;
  }

  to {
    opacity: 0;
    transform: translate3d(0, 30%, 0);
  }
}

.fancybox__carousel .carousel__slide {
  scrollbar-width: thin;
  scrollbar-color: #ccc #ffffff1a;
}

.fancybox__carousel .carousel__slide::-webkit-scrollbar {
  width: 8px;
  height: 8px;
}

.fancybox__carousel .carousel__slide::-webkit-scrollbar-track {
  background-color: #ffffff1a;
}

.fancybox__carousel .carousel__slide::-webkit-scrollbar-thumb {
  background-color: #ccc;
  border-radius: 2px;
  box-shadow: inset 0 0 4px #0003;
}

.fancybox__carousel .is-draggable {
  cursor: move;
  cursor: grab;
}

.fancybox__carousel .is-dragging {
  cursor: move;
  cursor: grabbing;
}

.fancybox__carousel .carousel__slide:not(.has-image) .fancybox__content {
  cursor: auto;
}

.fancybox__carousel .carousel__slide.can-zoom_in .fancybox__content {
  cursor: zoom-in;
}

.fancybox__carousel .carousel__slide.can-zoom_out .fancybox__content {
  cursor: zoom-out;
}

.fancybox__image {
  -webkit-user-select: none;
  user-select: none;
  background: none;
}

.has-image .fancybox__content {
  background: none;
  padding: 0;
}

.is-closing .has-image .fancybox__content {
  overflow: visible;
}

.has-image[data-image-fit="contain"] {
  touch-action: none;
  overflow: visible;
}

.has-image[data-image-fit="contain"] .fancybox__content {
  min-height: 1px;
}

.has-image[data-image-fit="contain"] .fancybox__image {
  max-width: 100%;
  min-height: 1px;
  object-fit: contain;
  background: none;
}

.has-image[data-image-fit="contain-w"] {
  overflow-x: hidden;
  overflow-y: auto;
}

.has-image[data-image-fit="contain-w"] .fancybox__content {
  min-height: auto;
}

.has-image[data-image-fit="contain-w"] .fancybox__image {
  max-width: 100%;
  height: auto;
}

.has-image[data-image-fit="cover"] {
  touch-action: none;
  overflow: visible;
}

.has-image[data-image-fit="cover"] .fancybox__content {
  min-height: 1px;
  width: 100%;
  height: 100%;
}

.has-image[data-image-fit="cover"] .fancybox__image {
  width: 100%;
  height: 100%;
  min-height: 1px;
  object-fit: cover;
}

.fancybox__carousel .fancybox__slide.has-iframe .fancybox__content, .fancybox__carousel .fancybox__slide.has-map .fancybox__content, .fancybox__carousel .fancybox__slide.has-pdf .fancybox__content, .fancybox__carousel .fancybox__slide.has-video .fancybox__content, .fancybox__carousel .fancybox__slide.has-html5video .fancybox__content {
  min-height: 1px;
  flex-shrink: 1;
  overflow: visible;
}

.fancybox__carousel .fancybox__slide.has-iframe .fancybox__content, .fancybox__carousel .fancybox__slide.has-map .fancybox__content, .fancybox__carousel .fancybox__slide.has-pdf .fancybox__content {
  width: 100%;
  height: 80%;
}

.fancybox__carousel .fancybox__slide.has-video .fancybox__content, .fancybox__carousel .fancybox__slide.has-html5video .fancybox__content {
  width: 960px;
  height: 540px;
  max-width: 100%;
  max-height: 100%;
}

.fancybox__carousel .fancybox__slide.has-map .fancybox__content, .fancybox__carousel .fancybox__slide.has-pdf .fancybox__content, .fancybox__carousel .fancybox__slide.has-video .fancybox__content, .fancybox__carousel .fancybox__slide.has-html5video .fancybox__content {
  color: #fff;
  background: #18181be6;
  padding: 0;
}

.fancybox__carousel .fancybox__slide.has-map .fancybox__content {
  background: #e5e3df;
}

.fancybox__html5video, .fancybox__iframe {
  height: 100%;
  width: 100%;
  background: none;
  border: 0;
  display: block;
}

.fancybox-placeholder {
  width: 1px;
  height: 1px;
  clip: rect(0, 0, 0, 0);
  white-space: nowrap;
  border-width: 0;
  margin: -1px;
  padding: 0;
  position: absolute;
  overflow: hidden;
}

.fancybox__thumbs {
  flex: none;
  padding: 0 3px;
  position: relative;
}

.fancybox__container.is-animated[aria-hidden="true"] .fancybox__thumbs {
  opacity: 0;
  transition: unset;
}

.fancybox__container.is-animated[aria-hidden="false"] .fancybox__thumbs {
  transition: opacity var(--fancybox-ts, .25s) ease-in;
  opacity: var(--fancybox-opacity, 1);
}

.fancybox__thumbs .carousel__slide {
  width: var(--fancybox-thumbs-width, 96px);
  box-sizing: content-box;
  cursor: pointer;
  flex: none;
  justify-content: center;
  align-items: center;
  margin: 0;
  padding: 8px 3px;
  display: flex;
  overflow: visible;
}

.fancybox__thumbs .carousel__slide.is-nav-selected:after {
  content: "";
  border-bottom: 3px solid;
  position: absolute;
  inset: 0 3px 3px;
}

.fancybox__thumbs .carousel__slide > * {
  pointer-events: none;
  -webkit-user-select: none;
  user-select: none;
}

.fancybox__thumb {
  width: 100%;
  padding-top: calc(100% / (var(--fancybox-thumbs-ratio, 1.5)));
  background-color: #ffffff1a;
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  position: relative;
}

/*# sourceMappingURL=index.d8f4a1f1.css.map */
