
@font-face {
	font-family: 'RotondaC';
	src: url('../fonts/RotondaC-Bold.eot');
	src: local('RotondaC'),
		url('../fonts/RotondaC-Bold.eot?#iefix') format('embedded-opentype'),
		url('../fonts/RotondaC-Bold.woff') format('woff'),
		url('../fonts/RotondaC-Bold.ttf') format('truetype');
	font-weight: normal;
	font-style: normal;
}
@font-face {font-family: "CCKillJoy";
	src:  url("../fonts/KillJoy.ttf") format("truetype"); 
}
$font-family-sans-serif: 'RotondaC';
$body-color: #ffffff;

@import "../../node_modules/bootstrap/scss/functions";
@import "../../node_modules/bootstrap/scss/variables";
@import "../../node_modules/bootstrap/scss/mixins";
@import "../../node_modules/bootstrap/scss/reboot";
@import "../../node_modules/bootstrap/scss/grid";
@import "../../node_modules/bootstrap/scss/utilities/display";
@import "../../node_modules/bootstrap/scss/utilities/flex";
@import "../../node_modules/bootstrap/scss/modal";